import React, { useEffect, useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';

import { Title, Link, Paragraph } from '../../components/Elements/Text';
import Button from '../../components/Elements/Button';

import { useDictionary } from '../../context/Language';
import { useStore } from '../../context/Store';
import { useOrder } from '../../context/Order';
import { useApi } from '../../context/Api';
import { useTheme, useColorOverrides } from '../../theme';

import { createNamedStyled } from '../../stitches.config';

const styled = createNamedStyled('Review');

const Element = styled('div', {});

const Wrapper = styled.named('Wrapper')('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 1000,

  overflowY: 'scroll',
  overscrollBehavior: 'contain',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
    width: 0,
    height: 0,
  },

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '$xs',
  '@desktop': { padding: '$s' },

  variants: { isOpen: { false: { pointerEvents: 'none' } } },
});

const Shade = styled.named('Shade')('div', {
  position: 'fixed',
  inset: 0,
  background: '$shade',

  transition: 'opacity calc($s / 1.5)',
  variants: { isOpen: { false: { opacity: 0 } } },
});

const Container = styled.named('Container')(motion.div, {
  position: 'relative',
  width: '100%',
  maxWidth: '600px',
  borderRadius: '$l',
  boxShadow: '$xl',
  background: '$backgroundCart',

  display: 'flex',
  flexDirection: 'column',
  gap: '$m',

  padding: '$s',
  '@desktop': { padding: 'calc($s * 1.5)' },

  margin: 'auto',
});

const Close = styled.named('Close')('div', {
  position: 'absolute',
  top: '$s',
  padding: '$s',
  cursor: 'pointer',
  pointerEvents: 'all',
  zIndex: 1000,

  insetInlineEnd: '$s',

  '&:before, &:after': {
    content: '',
    position: 'absolute',
    top: 12,
    width: 24,
    height: 2,
    borderRadius: 2,
    background: '$icon',
    transition: 'scale $l $ease, background $s',

    insetInlineEnd: 0,
  },

  '&:before': { transform: 'rotate(45deg)' },
  '&:after': { transform: 'rotate(-45deg)' },

  '&:hover': {
    '&:before, &:after': {
      scale: 1.15,
      background: '$accent',
    },
  },
});

const Header = styled.named('Header')('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled.named('Content')('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Block = styled.named('Block')(motion.div, {
  display: 'flex',
  flexDirection: 'column',

  gap: '$m',
});

const Rating = styled.named('Rating')('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  padding: '$s $l',
  alignItems: 'center',
  justifyContent: 'center',
});

const RatingIcon = styled.named('Rating')('i', {
  position: 'relative',
  fontSize: 'calc($l / 1.25)',
  color: '$brandColor',
  opacity: 0.4,
  padding: 'calc($xs / 4)',

  cursor: 'pointer',
  transition: 'opacity $m $ease, transform $m $ease',

  '@media (hover: hover)': {
    '&:hover': {
      opacity: 0.8,
      transform: 'scale(1.2)',
    },
  },

  variants: {
    active: {
      true: {
        opacity: '1 !important',
        transform: 'scale(1.1)',
      },
    },
    isSingle: {
      true: {
        '@media (hover: hover)': {
          '&:hover': {
            '& ~ *': {
              transform: 'scale(1.1)',
              opacity: 0.8,
            },
          },
        },
      },
    },
  },
});

const RatingFill = styled.named('RatingFill')('i', {
  position: 'absolute',
  inset: 'calc($xs / 4)',
  opacity: 0,
  transition: 'opacity $m $ease',
  variants: { active: { true: {
    opacity: 1,
  } } },

  ':nth-child(5) > &': { transitionDelay: '0ms' },
  ':nth-child(4) > &': { transitionDelay: '30ms' },
  ':nth-child(3) > &': { transitionDelay: '60ms' },
  ':nth-child(2) > &': { transitionDelay: '90ms' },
  ':nth-child(1) > &': { transitionDelay: '120ms' },
});

const Comment = styled.named('Comment')('textarea', {
  width: '100%',
  minHeight: '100px',
  border: 'none',
  background: 'transparent',
  color: '$text',
  fontFamily: '$text',
  fontSize: '$text',
  resize: 'none',
  transition: 'border-color $s $ease',
  borderRadius: 0,
  borderBottom: '1px solid $border',
  outline: 'none',
  '&:placeholder': {
    color: '$shade',
    fontFamily: '$text',
  },
});

const ProductWrapper = styled.named('ProductWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s',
});

const ProductRow = styled.named('ProductRow')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$s',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Product = styled.named('Product')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$xs',
  alignItems: 'center',
});

const ProductImage = styled.named('ProductImage')('img', {
  width: 'auto',
  maxWidth: 80,
  height: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  borderRadius: '$m',

  '@mobile': { display: 'none' },
});

const ProductTitle = styled.named('ProductTitle')('div', {
  display: 'flex',
  flexDirection: 'column',
  marginInlineStart: 'calc($xs / 2)',
});

const ProductName = styled.named('ProductName')(Title, {
  fontSize: 'calc($text * 1.5) !important',
  fontWeight: '$bold',
});

const ProductVariant = styled.named('ProductVariant')(Paragraph, {
  fontSize: 'calc($text * 0.75) !important',
  opacity: 0.6,
});

const Actions = styled.named('Actions')('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const ICONS_MAP = {
  heart: {
    single: true,
    value: 'heart',
  },
  star: {
    single: true,
    value: 'star',
  },
  emotion: {
    single: false,
    value: [
      'emotion-sad',
      'emotion-unhappy',
      'emotion-normal',
      'emotion-happy',
      'emotion-laugh',
    ],
  },
};

const Review = ({ ...props }) => {
  const { data: {
    reviewSupported,
    reviewAvailableFor, // number of days
    reviewCommentRequiredUnder,
  } } = useStore();

  const { data: store } = useStore();

  const api = useApi();

  const {
    data: {
      _id: orderId,
      completedAt,
      reviewed,
      items,
      status,
    },
    getOrder,
  } = useOrder();

  const { transitions: { spring } } = useTheme();
  const colorOverrides = useColorOverrides('cart');

  const {
    reviewTitle,
    reviewSubtitleSingular,
    reviewSubtitlePlural,
    reviewSubtitleDetailed,
    reviewActionOpen,
    reviewActionDetailed,
    reviewActionBack,
    reviewActionSubmit,
    reviewCommentOptional,
    reviewCommentRequired,
  } = useDictionary();

  // const [isReviewAvailable, setIsReviewAvailable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isSimpleRating, setIsSimpleRating] = useState(true);
  const [simpleRating, setSimpleRating] = useState(null);

  const [isForcedDetailedRating, setIsForcedDetailedRating] = useState(false);
  // const [detailedRating, setDetailedRating] = useState(null);

  const icon = ICONS_MAP[store?.style?.icons?.rating || 'star'];

  const [formData, setFormData] = useState({
    order: null,
    testimonial: null,
    products: [],
  });

  // Set initial form data
  useEffect(() => {
    if (items) {
      setFormData({
        order: orderId,
        testimonial: null,
        products: items.map((item) => ({
          product: item.product,
          rating: null,
          message: null,
        })),
      });
    }
  }, [orderId, items]);
  // Check if review is available
  // useEffect(() => {
  //   if (
  //     reviewSupported
  //     && !reviewed
  //     && moment().diff(completedAt, 'days') < reviewAvailableFor) {
  //       setIsReviewAvailable(true);
  //   }
  // }, [reviewSupported, reviewed, completedAt, reviewAvailableFor]);

  const isReviewAvailable = useMemo(() => (
    reviewSupported
    && !reviewed
    && moment().diff(completedAt, 'days') < reviewAvailableFor
    && status === 'DELIVERED'
  ), [
    reviewSupported,
    reviewed,
    completedAt,
    reviewAvailableFor,
    status,
  ]);

  // Switch to detailed rating if simple rating is below threshold
  useEffect(() => {
    if (!!simpleRating
      && (simpleRating <= reviewCommentRequiredUnder)
      && items.length > 1
    ) {
      setIsSimpleRating(false);
      setIsForcedDetailedRating(true);
    // } else {
    //   setFormData({
    //     ...formData,
    //     products: formData.products.map((product) => ({
    //       ...product,
    //       rating: simpleRating,
    //       message: null,
    //     })),
    //   });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleRating, reviewCommentRequiredUnder, items.length]);

  // Reset simple rating if detailed rating is initialted
  useEffect(() => {
    if (!isSimpleRating) {
      setSimpleRating(null);
      setFormData({
        ...formData,
        testimonial: null,
        products: formData.products.map((product) => ({
          ...product,
          rating: null,
          message: null,
        })),
      });
    } else {
      setIsForcedDetailedRating(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSimpleRating]);

  // Open review form after a delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  if (!isReviewAvailable) {
    return null
  }

  return (
    <Element>
      <Button onClick={() => setIsOpen(true)}>{reviewActionOpen || ''}</Button>
      <Wrapper
        {...props}
        isOpen={isOpen}
        className={colorOverrides}
      >
        <Shade isOpen={isOpen} onClick={() => setIsOpen(false)} />
        <AnimatePresence>
          {isOpen ? (
            <Container
              initial={{ opacity: 0, y: 250 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 250 }}
              transition={spring}
            >
              <Close onClick={() => setIsOpen(false)} />
              <Header>
                <Title>{reviewTitle}</Title>
                <Paragraph
                  css={{ '@mobile': { fontSize: 'calc($text * 0.85)' } }}
                >
                  {isForcedDetailedRating
                    ? reviewSubtitleDetailed
                    : items.length === 1
                      ? reviewSubtitleSingular
                      : reviewSubtitlePlural}
                </Paragraph>
              </Header>
              <Content>
                <AnimatePresence initial={false}>
                {isSimpleRating ? (
                  <Block
                    layout
                    key="rating-simple"
                    layoutId="rating-simple"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={spring}
                  >
                    <Rating
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={spring}
                    >
                      {[1, 2, 3, 4, 5].map((i) => (
                        <RatingIcon
                          key={i}
                          onClick={() => {
                            setFormData({
                              ...formData,
                              testimonial:
                                !(6 - i <= reviewCommentRequiredUnder)
                                  ? formData.testimonial
                                  : null,
                              products: formData.products.map((product) => ({
                                ...product,
                                rating: 6 - i,
                              })),
                            });
                            setSimpleRating(simpleRating === 6 - i ? 0 : 6 - i);
                          }}
                          active={simpleRating >= 6 - i}
                          className={`ri-${
                            icon.single ? icon.value : icon.value[5 - i]}-line
                          `}
                        >
                          <RatingFill
                            className={`ri-${icon.value}-fill`}
                            active={simpleRating >= 6 - i}
                          />
                        </RatingIcon>
                      ))}
                    </Rating>
                    <Comment
                      placeholder={
                        !(simpleRating <= reviewCommentRequiredUnder)
                        || simpleRating === null
                          ? reviewCommentOptional
                          : reviewCommentRequired
                      }
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          testimonial:
                            !(simpleRating <= reviewCommentRequiredUnder)
                              ? e.target.value
                              : null,
                          products: formData.products.map((product) => ({
                            ...product,
                            message: e.target.value,
                          })),
                        });
                      }}
                    />
                  </Block>
                ) : (
                  <Block
                    layout
                    key="rating-detailed"
                    layoutId="rating-detailed"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={spring}
                  >
                    {items.map((item, i) => (
                      <ProductWrapper key={item.product}>
                      <ProductRow>
                        <Product>
                          <ProductImage src={item.image.src} />
                          <ProductTitle>
                            <ProductName>
                              {item.productName}
                            </ProductName>
                            <ProductVariant>
                              {item.variationName}
                            </ProductVariant>
                          </ProductTitle>
                        </Product>
                        <Rating
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={spring}
                          css={{ zoom: 0.5, padding: 0 }}
                        >
                          {[1, 2, 3, 4, 5].map((j) => (
                            <RatingIcon
                              key={j}
                              isSingle={icon.single}
                              onClick={() => {
                                const newProducts = formData.products;
                                newProducts[i].rating = newProducts[i]
                                  ?.rating === 6 - j ? 0 : 6 - j;
                                setFormData({
                                  ...formData, products: newProducts,
                                });
                              }}
                              active={formData.products[i]?.rating >= 6 - j}
                              className={`ri-${
                                icon.single
                                  ? icon.value
                                  : icon.value[5 - i]}-line
                              `}
                            >
                              <RatingFill
                                className={`ri-${icon.value}-fill`}
                                active={formData.products[i]?.rating >= 6 - j}
                              />
                            </RatingIcon>

                          ))}
                        </Rating>
                      </ProductRow>
                        <Comment
                          css={{ minHeight: 0 }}
                          placeholder={
                            !(formData.products[i]?.rating
                            <= reviewCommentRequiredUnder)
                            || (formData.products[i]?.rating === null)
                              ? reviewCommentOptional
                              : reviewCommentRequired
                          }
                          onChange={(e) => {
                            const newProducts = formData.products;
                            newProducts[i].message = e.target.value;
                            setFormData({
                              ...formData, products: newProducts,
                            });
                          }}
                        />
                      </ProductWrapper>
                    ))}
                  </Block>
                )}
                </AnimatePresence>
              </Content>
              <Actions
                css={{
                  justifyContent: items.length > 1
                    ? 'space-between'
                    : 'center',
                }}
              >
                {items.length > 1 ? (
                  <Link onClick={() => {
                    setIsSimpleRating(!isSimpleRating);
                    setFormData({
                      ...formData,
                      testimonial: null,
                      products: formData.products.map((product) => ({
                        ...product,
                        rating: null,
                        message: null,
                      })),
                    });
                  }}
                  >
                    {isSimpleRating
                      ? reviewActionDetailed
                      : reviewActionBack}
                  </Link>
                ) : null}
                <Button
                  disabled={
                    !(formData.products.every((product) => product.rating)
                    && formData.products.every((product) => (
                      product.rating > reviewCommentRequiredUnder
                      || product.message
                    )))
                  }
                  onClick={async () => {
                    try {
                      await api.post('/reviews', formData);
                    } catch (error) {
                      // eslint-disable-next-line no-console
                      console.error(error);
                    }
                    await getOrder();
                    setIsOpen(false);
                  }}
                >
                  {reviewActionSubmit}
                </Button>
              </Actions>
            </Container>
          ) : null}
        </AnimatePresence>
      </Wrapper>
    </Element>
  );
};

export default Review;

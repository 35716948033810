/* eslint-disable max-len */
import * as React from 'react';

import { useTheme } from '../../theme';
import Wrapper from '../Wrapper';

function Facebook({ type, ...props }) {
  const { custom: { iconsStyle } } = useTheme();

  return (
    <Wrapper
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`type-${iconsStyle}`}
      {...props}
    >
      {iconsStyle === 'duotone' || iconsStyle === 'duotoneOffset' ? (
        <path
          d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3V2z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="duotone"
          opacity={0.6}
        />
      ) : null}
      {iconsStyle === 'solid' ? (
        <path
          d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3V2z"
          fill="#000"
          className="solid"
        />
      ) : null}
      <path
        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3V2z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={iconsStyle === 'solid' ? 1 : 2}
        className="base"
      />
    </Wrapper>
  );
}

export default Facebook;

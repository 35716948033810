import { createNamedStyled } from '../../stitches.config';

const styled = createNamedStyled('Grid');

const Grid = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  width: '100%',
  gridTemplateColumns: 'repeat(2, 1fr)',

  '@tablet+': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  '@medium+': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  div: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
});

export default Grid;

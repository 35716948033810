import React, { useMemo, useState, useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';
import usePortal from 'react-useportal';

import { createNamedStyled } from '../../stitches.config';

import Base from './Base';

const styled = createNamedStyled('Toggle');

const Node = styled(Base, {
  width: '100%',
});

const PortalContent = styled.named('PortalContent')('div', {
  position: 'fixed',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  zIndex: 999999999999999,
  inset: 0,
  overflowY: 'scroll',
  overscrollBehavior: 'contain',
});

const ClickOutside = styled.named('ClickOutside')('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  inset: 0,

  variants: {
    isOpen: {
      true: {
        pointerEvents: 'auto',
      },
      false: {
        pointerEvents: 'none',
      },
    },
  },
});

function fadeOutVolume(videoElement, duration = 200) {
  const initialVolume = videoElement.volume;
  const startTime = Date.now();

  function animate() {
    const elapsed = Date.now() - startTime;
    const progress = elapsed / duration;

    if (progress < 1) {
      videoElement.volume = initialVolume * (1 - progress);
      requestAnimationFrame(animate);
    } else {
      videoElement.volume = 0;
    }
  }

  requestAnimationFrame(animate);
}

function getDaysDifference(date1, date2) {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

const Toggle = ({ node, renderNode, css, ...props }) => {
  const finalCss = useMemo(
    () => ({
      ...css,
      cursor: 'pointer',
    }),
    [css],
  );

  const { Portal } = usePortal()
  const portalContentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAllowed, setIsOpenAllowed] = useState(false);

  useHotkeys('esc', () => setIsOpen(false), [isOpen])

  useEffect(() => {
    const today = new Date();
    const lastDisplayDate = new Date(
      localStorage.getItem('lastDisplayDate') || today
    );
    const displayCount = parseInt(
      localStorage.getItem('displayCount') || 0, 10
    );
    const period = node.toggle_period || 0;
    const repeatCount = node.toggle_repeatCount || 0;

    const daysSinceLastDisplay = getDaysDifference(lastDisplayDate, today);

    let isAllowed = false;

    if (displayCount < repeatCount) {
      isAllowed = true;
    } else if (daysSinceLastDisplay >= period) {
      localStorage.setItem('lastDisplayDate', today.toISOString());
      localStorage.setItem('displayCount', '0');
      isAllowed = true;
    }

    setIsOpenAllowed(isAllowed);

    if (isAllowed && displayCount < repeatCount) {
      localStorage.setItem('displayCount', (displayCount + 1).toString());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout;

    if (
      isOpenAllowed
        && node.toggle_placement === 'MODAL'
        && node.toggle_mode === 'ENTER'
    ) {
      timeout = setTimeout(() => {
        setIsOpen(true);
      }, node.toggle_duration * 1000);
    }

    return () => {
      clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAllowed]);

  useEffect(() => {
    let handleMouseLeave = () => {};

    if (
      isOpenAllowed
        && node.toggle_placement === 'MODAL'
        && node.toggle_mode === 'LEAVE'
      ) {
      handleMouseLeave = (e) => {
        if (e.clientY <= 0) {
          setIsOpen(true);
        }
      };

      document.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAllowed]);

  useEffect(() => {
    let timeout;
    const handleMouseMove = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsOpen(true);
      }, node.toggle_duration * 1000);
    };

    if (
      isOpenAllowed
        && node.toggle_placement === 'MODAL'
        && node.toggle_mode === 'INACTIVE'
    ) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('mousemove', handleMouseMove);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAllowed]);

  useEffect(() => {
    if (!isOpen && portalContentRef.current) {
      portalContentRef?.current?.querySelectorAll('video').forEach(video => {
        fadeOutVolume(video, 300);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && portalContentRef.current) {
      const closeClickHandler = () => {
        setIsOpen(false);
      };

      const closeElements = portalContentRef
        ?.current?.querySelectorAll('.close');
      closeElements.forEach(close => {
        close.addEventListener('click', closeClickHandler);
      });

      return () => {
        closeElements.forEach(close => {
          close.removeEventListener('click', closeClickHandler);
        });
      };
    }

    return () => {};
  }, [isOpen]);

  return (
    <>
      {(node?.toggle_placement === 'BEFORE' && isOpen)
        ? node?.toggle_items?.map(child => renderNode(child)) : null}
      <Node
        node={node}
        css={finalCss}
        onClick={() => setIsOpen(
          node?.toggle_placement === 'MODAL' ? true : !isOpen
        )}
        dataIsOpen={isOpen}
        {...props}
      >
        {node?.toggle_trigger?.map((child) => renderNode(child))}
      </Node>
      {(node?.toggle_placement === 'AFTER' && isOpen)
        ? node?.toggle_items?.map(child => renderNode(child)) : null}
      {node?.toggle_placement === 'MODAL' ? (
        <Portal>
          <PortalContent ref={portalContentRef}>
            <ClickOutside isOpen={isOpen} onClick={() => setIsOpen(false)} />
            <AnimatePresence>
              {isOpen && node?.toggle_items?.map(child => renderNode(child))}
            </AnimatePresence>
          </PortalContent>
        </Portal>
      ) : null}
    </>
  );
}

export default Toggle;

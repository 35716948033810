import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { styled } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import { useStore } from '../../../context/Store';
import CartContext from '../../../context/Cart';

import Button from '../Button';
import { Cart } from '../../../icons';

const Wrapper = styled(motion.div, {
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  bottom: '$s',
  insetInlineEnd: '$s',
});

export default function FloatingCart({ visible }) {
  const { transitions: { spring } } = useTheme();
  const cart = useContext(CartContext);
  const { data: store } = useStore();

  const isChatSupported = store.integrations.whatsApp.supported;

  return (
    <AnimatePresence>
      {visible && cart.quantityTotal > 0 && (
        <Wrapper
          key="floating-cart-button"
          onClick={() => cart.toggleShowCart()}
          initial={{ y: isChatSupported ? 0 : 200, opacity: 0 }}
          animate={{ y: isChatSupported ? -80 : 0, opacity: 1 }}
          exit={{ y: isChatSupported ? 0 : 200, opacity: 0 }}
          transition={{ ...spring }}
        >
          <Button round notification={cart.quantityTotal}>
            <Cart css={{
                '.stroke': { stroke: '$buttonForeground' },
                '.fill': { fill: '$buttonForeground' },
              }}
            />
          </Button>
        </Wrapper>
      )}
    </AnimatePresence>
  );
}

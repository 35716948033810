import TiktokPixel from 'tiktok-pixel';

import Tracker from './Tracker';

// import Types from '../../../modules/types';

// const { ANALYTICS_EVENTS } = Types;

export default class TiktokSnapchat extends Tracker {
  static NAME = 'Tiktok';

  setup({ store } = {}) {
    if (
         !this.active
      && store?.isCustomDomain
      && store?.data?.integrations?.tiktokPixel?.supported
      && store?.data?.integrations?.tiktokPixel?.trackingID
    ) {
      this.debug(
        'initializing',
        `(mode: ${store.mode})`,
        store?.data?.integrations?.tiktokPixel?.trackingID,
      );
      TiktokPixel.init(
        store?.data?.integrations?.tiktokPixel?.trackingID,
        {},
        {
          autoConfig: true,
          debug: true,
        },
      );
      TiktokPixel.pageView();

      this.active = true;
    } else {
      this.active = false;
    }
  }

  // eslint-disable-next-line no-unused-vars
  trigger(event, data) {
    if (!this.active) {
      return false;
    }
    switch (event) {
      case 'PAGE_VIEW':
        TiktokPixel.pageView();
        return true;
      default:
    }
    return false;
  }
}

import React, { PureComponent, useContext } from 'react';
import mix, { AsyncState, Injectable } from './Mixins';

import ApiContext from '../context/Api';

export default class Query extends mix(PureComponent, Injectable, AsyncState) {
  static defaultProps = {
    url: '/',
    method: 'get',
    config: {},
    autoload: true,
    autoreload: true,
    throttle: 0,
    initialData: null,
    extractData: response => response,
  }

  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      request: null,
      loading: true,
      response: null,
      data: this.props.extractData(null, [], props),
      error: null,
      reload: this.reload,
      cancel: this.cancel,
    };
    this.aborter = new AbortController();
  }

  componentDidMount() {
    if (this.props.autoload) {
      this.load(this.props);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.cancel();
  }

  componentDidUpdate(props) {
    if (props.autoload || this.props.autoreload) {
      if (
        props.url !== this.props.url
        || props.method !== this.props.method
        || props.config !== this.props.config
      ) {
        this.reload(this.props);
      }
    }
  }

  load = async (props = {}) => {
    this.cancel();
    const aborter = new AbortController();
    const state = await this.setState({ loading: true });
    const promise = props.request(
      props.method,
      props.url,
      undefined,
      this.abort,
      aborter,
    )
    .then(async (response) => {
      if (!this.unmounted) {
        await this.setState({
          data: props.extractData(response, state.data, props),
          error: null,
          loading: false,
        });
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      if (!this.unmounted) {
        this.setState({
          data: props.extractData(null, [], props),
          error: error.message ? error : null,
          loading: false,
        });
      }
    });
    this.abort = aborter;
    return promise;
  }

  reload = (props = {}) => {
    props = Object.assign({}, this.props, props);
    this.cancel();
    clearTimeout(this.throttleTimeout);
    if (props.throttle > 0) {
      return new Promise((resolve) => {
        this.throttleTimeout = setTimeout(
          () => this.load(props).then(resolve),
          props.throttle
        );
      });
    }
    return this.load(props);
  }

  cancel = () => {
    this.aborter.abort();
  }

  render() {
    return this.renderChildrenWithProps(this.state);
  }
}

export function ApiBoundQuery(props) {
  const api = useContext(ApiContext);
  return <Query request={api.request} {...props} />;
}

import React, { forwardRef } from 'react';
import { Link as ReactRouterLink, useHistory } from 'react-router-dom';
import { animate } from 'framer-motion';

import { styled } from '../../stitches.config';
import { useTheme } from '../../theme';

import { useStore } from '../../context/Store';
import { useLanguage } from '../../context/Language';

const Wrapper = styled.named('Link')('div', { cursor: 'pointer' });

const InnerLink = ({ to, children, ...props }) => (
  <Wrapper as={ReactRouterLink} to={to} {...props}>
    {children}
  </Wrapper>
);

const OutterLink = ({ to, children, ...props }) => (
  <Wrapper as="a" href={to} target="_blank" {...props}>
    {children}
  </Wrapper>
);

const ScrollLink = ({ to, children, ...props }) => {
  const history = useHistory();
  const { navigation } = useTheme();

  const handleOnClick = () => {
    history.push(to);

    setTimeout(() => {
      const target = document.querySelector(`#${to.split('#')[1]}`);

      if (target) {
        animate(
          window.scrollY,
          window.scrollY
          + (target.getBoundingClientRect().top * getComputedStyle(target).zoom)
          // TODO: update to dynamic navigationn height
          - (navigation.position === 'fixed' ? 145 : 0),
        {
          // TODO: update with theme values
          type: 'spring',
          stiffness: 100,
          damping: 15,
          mass: 0.5,
          onUpdate: v => { window.scroll({ top: v }); },
        });
      }
    }, 100);
  };

  return (
    <Wrapper onClick={handleOnClick} {...props}>
      {children}
    </Wrapper>
  );
};

const TYPES = {
  inner: InnerLink,
  outter: OutterLink,
  scroll: ScrollLink,
};

const getType = (to) => {
  if (
    to.startsWith('http') || to.startsWith('mailto') || to.startsWith('tel')
  ) return 'outter';

  if (to.startsWith('#')) return 'scroll';

  return 'inner';
};

const Link = forwardRef(({ to = '', children, node, ...props }, ref) => {
  const { homePath } = useTheme();
  const { data: { domains, slug } } = useStore();
  const { _id: language } = useLanguage();

  to = (
    to.startsWith('http')
    || to.startsWith('mailto')
    || to.startsWith('tel')
    || to.startsWith('/')
    || to.startsWith('#')
  )
    ? to
    : `/${to}`;

  if (to === '/') to = homePath || '/';

  const slugSafeLink = domains.length > 0
    ? `/${language}${to}`
    : `/${slug}/${language}${to}`;

  return (
    TYPES[getType(to)]({
      to: getType(to) === 'inner' ? slugSafeLink : to,
      children,
      ref,
      ...props,
    })
  );
});

export default Link;

import COUNTRIES from '../assets/countries.json';
import CURRENCIES from '../assets/currencies.json';
import LANGUAGES from '../assets/languages.json';

const Types = {};

Types.COUNTRIES = COUNTRIES;

Types.COUNTRIES_MAP = COUNTRIES.reduce(
  (agr, country) => { agr[country.iso3a2] = country; return agr; },
  {},
);

Types.COUNTRIES_BANNED = [
  'af', // Afghanistan
  'ir', // Iran
  'iq', // Iraq
  'il', // Israel
  'ly', // Lybia
  'ng', // Nigeria
  'pk', // Pakistan
  'qa', // Qatar
  'so', // Somalia
  'sd', // Sudan
  'ss', // South Sudan
  'sy', // Syria
  'ye', // Yemen
];

Types.CURRENCIES = CURRENCIES;
Types.CURRENCIES_MAP = CURRENCIES.reduce(
  (agr, currency) => { agr[currency.code] = currency; return agr; },
  {},
);

Types.LANGUAGES = LANGUAGES;
Types.LANGUAGES_MAP = LANGUAGES.reduce(
  (agr, language, i) => {
    language.index = i;
    agr[language.code] = language;
    return agr;
  },
  {},
);

export default Types;

import React, { useMemo } from 'react';
import { styled } from '../../stitches.config';

import Base from '../components/Base';
import ButtonElement from '../../components/Elements/Button';

const Node = styled(Base, {});

const Button = ({ node, css, ...props }) => {
  const finalCss = useMemo(
    () => ({
      cursor: 'pointer',
      ...css,
    }),
    [css],
  );

  if (!node.button_label) {
    return null;
  }

  return (
    <Node
      {...props}
      node={node}
      as={ButtonElement}
      to={node.button_link}
      css={finalCss}
    >
      {node.button_label}
    </Node>
  );
};

export default Button;

import React, { useState } from 'react';
import usePortal from 'react-useportal';

import { createNamedStyled } from '../../../stitches.config';
import { useColorOverrides } from '../../../theme';

import { useDictionary } from '../../../context/Language';

import Cart from '../Cart/Trigger';
import SocialIcons from '../SocialIcons';

import AutoLayout from '../../Patterns/AutoLayout';

import Items from './Items';
import Switches from './Switches';

const styled = createNamedStyled('NavigationMobile');

const Icon = styled.named('Icon')('div', {
  position: 'relative',
  width: 40,
  height: 40,
  padding: '10px 8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'transform $m $s $ease',
  zIndex: 2,
});

const Line = styled.named('Line')('div', {
  width: 24,
  height: 2,
  borderRadius: 2,
  background: '$icon',
  position: 'absolute',
  left: 'calc(50% - 12px)',
  top: 'calc(50% - 1px)',

  transition: [
    'transform $s $xs',
    'scale $s',
    'opacity $s',
    'background $s',
    'rotate $s',
  ].join(', '),

  transitionTimingFunction: '$transitions$ease',

  '&:first-child': { transform: 'translateY(-8px)' },
  '&:last-child': { transform: 'translateY(8px)' },

  [`${Icon}:hover &`]: {
    scale: 1.15,
    background: '$accent',
  },

  variants: {
    isOpen: {
      true: {
        transition: [
          'transform $s',
          'scale $s',
          'opacity $s',
          'background $s',
          'rotate $s $xs',
        ].join(', '),

        '&:first-child': { transform: 'translateY(0px)', rotate: '45deg' },
        '&:last-child': { transform: 'translateY(0px)', rotate: '-45deg' },

        '&:nth-child(2)': { opacity: 0 },
      },
    },
  },
});

const Shade = styled.named('Shade')('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: '$shade',
  zIndex: 2,
  opacity: 0,
  pointerEvents: 'none',

  transition: 'opacity $l',

  variants: {
    isOpen: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
});

const Container = styled.named('Container')('div', {
  position: 'fixed',
  inset: 0,
  paddingTop: '$s',
  overflowX: 'hidden',
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  zIndex: 2,
  pointerEvents: 'none',
});

const Wrapper = styled.named('Wrapper')('div', {
  width: '80%',
  maxWidth: 400,
  flex: 1,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$s',
  boxShadow: '0 0 50px $colors$shadow',

  insetInlineEnd: 0,
  borderStartStartRadius: '$radii$l',

  gap: '$m',

  background: '$backgroundNavigation',

  marginTop: 0,

  '&:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '$backgroundNavigation',
    insetInlineEnd: '-100%',
  },

  variants: {
    isOpen: {
      true: {
        pointerEvents: 'auto',
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'transform $xl $ease, opacity $xs',
      },
      false: {
        pointerEvents: 'none',
        opacity: 0,
        transform: '$custom$inlineTranslateEnd',
        transition: 'transform $l $ease, opacity $l',
      },
    },
  },
});

const ItemsWrapper = styled.named('ItemsWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 'calc($s * 1.33)',
  padding: '$s',
});

export const Divider = styled.named('Divider')('div', {
  width: 1,
  height: 12,
  background: '$text',
  opacity: 0.5,
});

export const Vertical = styled.named('Vertical')('div', {
  width: 12,
  height: 1,
  background: '$text',
  opacity: 0.5,
});

const Group = styled.named('Group')('div', {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$s',
});

const MobileNavigation = ({ items, actions, hideCart }) => {
  const { cartTitle } = useDictionary();

  const colorOverrides = useColorOverrides('navigation');

  const [isOpen, setIsOpen] = useState(false);

  const iconRef = React.useRef(null);
  const [iconOffset, setIconOffset] = useState(0);

  const { Portal } = usePortal({
    bindTo: document && document.getElementById('mobile-navigation-portal'),
  });

  const renderIcon = ({ ref, additionalStyles, fake }) => (
    <Icon
      ref={ref || null}
      onClick={() => {
        setIsOpen(!isOpen);

        const { y } = iconRef.current.getBoundingClientRect();
        setIconOffset(y);
      }}
      css={{
        ...additionalStyles,
        ...isOpen && {
          opacity: fake ? 1 : 0,
          transform: `translateY(calc($space$s * 2 - ${iconOffset}px))`,
          pointerEvents: 'auto',
        },
      }}
    >
      <Line isOpen={isOpen} />
      <Line isOpen={isOpen} />
      <Line isOpen={isOpen} />
    </Icon>
  );

  return (
    <>
      <Group>
        <AutoLayout
          gap="$s"
          align="center"
          justify="flex-end"
          hide
        >
          <Switches />
          <Divider css={{ marginInlineStart: '10px' }} />
        </AutoLayout>
        {
          hideCart
            ? null
            : (
                <>
                  <Cart />
                  <Divider css={{ marginInlineStart: '10px' }} />
                </>
              )
        }
        {renderIcon({ ref: iconRef })}
      </Group>

      <Portal>
        <Shade
          className={colorOverrides}
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
        />
        <Container isOpen={isOpen}>
          <Wrapper className={colorOverrides} isOpen={isOpen}>
            <ItemsWrapper data-mobile-nav="true">
              <Items
                mobile
                items={items}
                onClick={() => setIsOpen(false)}
              />
              {!!actions.length && <Vertical />}
              <Items
                mobile
                items={actions}
                actions
                onClick={() => setIsOpen(false)}
              />
              {
                hideCart
                  ? null
                  : (
                      <>
                        <Vertical />
                        <Cart label={cartTitle} />
                      </>
                    )
              }
            </ItemsWrapper>
            <div />
            <Switches position="top" mobile />
            <SocialIcons />
          </Wrapper>

          {renderIcon({
            additionalStyles: {
              pointerEvents: 'none',
              position: 'fixed',
              top: iconOffset,
              insetInlineEnd: '$s',
              opacity: 0,
            },
            fake: true,
          })}
        </Container>
      </Portal>
    </>
  );
};

export default MobileNavigation;

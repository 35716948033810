import { createNamedStyled } from '../stitches.config';

const styled = createNamedStyled('Icon');

const hoverStyles = {
  transform: 'scale(1.15)',

  '&.type-solid': {
    '.base': { stroke: '$accent' },
    '.solid': { fill: '$accent' },
  },

  '&.type-line': {
    '.base': { stroke: '$accent' },
    '.line': { stroke: '$accent' },
  },

  '&.type-duotone': {
    '.duotone': { opacity: 1 },
  },

  '&.type-duotoneOffset': {
    '.duotone': { opacity: 1, transform: 'translate(7px, -6px)' },
  },
};

const Wrapper = styled.named('Wrapper')('svg', {
  overflow: 'visible',
  cursor: 'pointer',
  transition: 'transform $m $ease',

  '.base': { stroke: '$icon', transition: 'stroke $s' },
  '.line': { stroke: '$icon', transition: 'stroke $s' },
  '.solid': { fill: '$icon', transition: 'fill $s' },
  '.duotone': {
    fill: '$accent !important',
    stroke: '$accent !important',
    transition: 'opacity $s' },

  '&:hover': hoverStyles,
  '.hover-target:hover &': hoverStyles,

  '&.type-duotoneOffset .duotone': {
    transform: 'translate(5px, -4px)',
    transition: 'opacity $s, transform $m $ease',
  },
});

export default Wrapper;

import Tracker from './Tracker';

// import Types from '../../../modules/types';

// const { ANALYTICS_EVENTS } = Types;

export default class TrackerGoogleAnalytics extends Tracker {
  static NAME = 'GoogleAnalytics';

  setup({ store } = {}) {
    const existingScript = document.getElementById('google-analytics-script');
    if (
         !this.active
      && store?.isCustomDomain
      && store?.data?.integrations?.googleAnalytics?.supported
      && store?.data?.integrations?.googleAnalytics?.trackingID
    ) {
      if (!existingScript) {
        const script = document.createElement('script');
        script.id = 'google-analytics-script';
        // script.src = `https://www.googletagmanager.com/gtag/js?id=${
        //   store?.data?.integrations?.googleAnalytics?.trackingID
        // }`;
        script.src = 'https://www.googletagmanager.com/gtag/js';
        document.head.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        window.gtag = this.gtag = function gtag() {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
      }
      this.trackingID = store?.data?.integrations?.googleAnalytics?.trackingID;
      this.debug(
        'initializing',
        `(mode: ${store.mode})`,
        this.trackingID,
      );
      this.gtag('set', {
        send_to: this.trackingID,
      });
      this.gtag(
        'config',
        this.trackingID,
        {
          send_to: this.trackingID,
        },
      );
      this.active = true;
    } else {
      // if (existingScript) {
      //   document.head.removeChild(existingScript);
      //   delete window.dataLayer;
      //   delete window.gtag;
      // }
      this.trackingID = undefined;
      this.active = false;
    }
  }

  sendEvent(event, data = {}) {
    if (this.gtag) {
      const payload = {
        ...data,
        ...(this.trackingID ? { send_to: this.trackingID } : {}),
      };
      this.debug('event', event, payload);
      this.gtag('event', event, payload);
    }
  }

  trigger(event, data) {
    if (!this.active) {
      return false;
    }
    switch (event) {
      case 'CART_OPEN': {
        const { items, currency } = data;
        let value = 0;
        const payload = {
          currency,
          items: items.map((item) => {
            value += item.quantity * item.price;
            return {
              item_id: item.meta.storeSku,
              item_name: item.meta.name,
              item_category: item.meta.categoryName,
              // item_variant: item.meta.variation,
              price: item.price,
              quantity: item.quantity,
            };
          }),
        };
        payload.value = value;
        this.sendEvent('view_cart', payload);
        return true;
      }
      case 'CART_ADD': {
        const {
          price,
          meta,
          quantity = 1,
          currency,
        } = data;
        const payload = {
          currency,
          value: quantity * price,
          items: [{
            item_id: meta.storeSku,
            item_name: `${meta.name} (${meta.variation})`,
            item_category: meta.categoryName,
            // item_variant: meta.variation,
            price,
            quantity,
          }],
        };
        this.sendEvent('add_to_cart', payload);
        return true;
      }
      case 'CART_REMOVE': {
        const {
          price,
          meta,
          quantity = 1,
          currency,
        } = data;
        const payload = {
          currency,
          value: quantity * price,
          items: [{
            item_id: meta.storeSku,
            item_name: `${meta.name} (${meta.variation})`,
            item_category: meta.categoryName,
            // item_variant: meta.variation,
            price,
            quantity,
          }],
        };
        this.sendEvent('remove_from_cart', payload);
        return true;
      }
      case 'ORDER_SUBMIT': {
        const { order, currency } = data;
        const payload = {
          value: order.cost.charged,
          currency,
          items: order.items.map(item => ({
            item_id: item.storeSku,
            item_name: item.name,
            // TODO Missing properties, populate order or take from store categories
            // item_category: item.meta.categoryName,
            // item_variant: item.variation,
            price: item.price,
            quantity: item.quantity,
          })),
        };
        this.sendEvent('begin_checkout', payload);
        return true;
      }
      case 'ORDER_SUBMIT_SUCCESS': {
        const { order, currency } = data;
        const payload = {
          value: order.cost.charged,
          currency,
          transaction_id: order._id,
          items: order.items.map(item => ({
            item_id: item.storeSku,
            item_name: item.name,
            // TODO Missing properties, populate order or take from store categories
            // item_category: item.meta.categoryName,
            // item_variant: item.variation,
            price: item.price,
            quantity: item.quantity,
          })),
        };
        this.sendEvent('purchase', payload);
        return true;
      }
      case 'PRODUCT_SEARCH': {
        const { searchString } = data;
        if (searchString && searchString.trim().length) {
          const payload = {
            search_term: searchString,
          };
          this.sendEvent('search', payload);
          this.debug(event, payload);
          return true;
        }
        break;
      }
      case 'PRODUCT_OPEN': {
        const { product, variation, currency } = data;
        const payload = {
          value: variation.price,
          currency,
          items: [{
            item_id: variation.storeSku,
            item_name: `${product.name} (${variation.name})`,
            item_category: product.category?.name,
            // item_variant: variation.name,
            price: variation.price,
            quantity: 1,
          }],
        };
        this.sendEvent('view_item', payload);
        return true;
      }
      default:
    }
    return false;
  }
}

/* eslint-disable max-len */
import * as React from 'react';

import { useTheme } from '../../theme';
import Wrapper from '../Wrapper';

function Instagram({ type, ...props }) {
  const { custom: { iconsStyle } } = useTheme();

  return (
    <Wrapper
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`type-${iconsStyle}`}
      {...props}
    >
      {iconsStyle === 'duotone' || iconsStyle === 'duotoneOffset' ? (
        <path
          d="M3 2v18a2 2 0 002 2h14a2 2 0 002-2V2H3z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="duotone fill"
          opacity={0.6}
        />
      ) : null}
      {iconsStyle === 'solid' ? (
        <path
          d="M3 6V2h18v18a2 2 0 01-2 2H5a2 2 0 01-2-2V6zm6 .92a1 1 0 00-2 0 5 5 0 0010 0 1 1 0 10-2 0 3 3 0 01-6 0z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#000"
          className="fill solid"
        />
      ) : (
        <path
          d="M16 6.92a4 4 0 11-8 0"
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="line stroke"
        />
      )}
      <path
        d="M3 2v18a2 2 0 002 2h14a2 2 0 002-2V2H3z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={iconsStyle === 'solid' ? 1 : 2}
        className="base stroke"
      />
    </Wrapper>
  );
}

export default Instagram;

import TagManager from '@sooro-io/react-gtm-module';

import Tracker from './Tracker';

// import Types from '../../../modules/types';

// const { ANALYTICS_EVENTS } = Types;

export default class TrackerGoogleTagManager extends Tracker {
  static NAME = 'GoogleTagManager';

  setup({ store } = {}) {
    if (store?.data?.integrations?.googleTagManager?.supported) {
      this.debug('supported, initializing');
      TagManager.initialize({
        gtmId: store?.data?.integrations?.googleTagManager?.containerId,
      });
      this.active = true;
    } else {
      this.active = false;
    }
  }

  sendEvent(event, data = {}) {
    this.debug('event', event, data);
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...data,
      },
    });
  }

  trigger(event, data) {
    if (!this.active) {
      return false;
    }
    switch (event) {
      case 'CART_OPEN': {
        const { items, currency } = data;
        let value = 0;
        const payload = {
          currency,
          items: items.map((item) => {
            value += item.quantity * item.price;
            return {
              item_id: item.meta.storeSku,
              item_name: item.meta.name,
              item_category: item.meta.categoryName,
              // item_variant: item.meta.variation,
              price: item.price,
              quantity: item.quantity,
            };
          }),
        };
        payload.value = value;
        this.sendEvent('view_cart', payload);
        return true;
      }
      case 'CART_ADD': {
        const {
          price,
          meta,
          quantity = 1,
          currency,
        } = data;
        const payload = {
          currency,
          value: quantity * price,
          items: [{
            item_id: meta.storeSku,
            item_name: `${meta.name} (${meta.variation})`,
            item_category: meta.categoryName,
            // item_variant: meta.variation,
            price,
            quantity,
          }],
        };
        this.sendEvent('add_to_cart', payload);
        return true;
      }
      case 'CART_REMOVE': {
        const {
          price,
          meta,
          quantity = 1,
          currency,
        } = data;
        const payload = {
          currency,
          value: quantity * price,
          items: [{
            item_id: meta.storeSku,
            item_name: `${meta.name} (${meta.variation})`,
            item_category: meta.categoryName,
            // item_variant: meta.variation,
            price,
            quantity,
          }],
        };
        this.sendEvent('remove_from_cart', payload);
        return true;
      }
      case 'ORDER_SUBMIT': {
        const { order, currency } = data;
        const payload = {
          value: order.cost.charged,
          currency,
          items: order.items.map(item => ({
            item_id: item.storeSku,
            item_name: item.name,
            // TODO Missing properties, populate order or take from store categories
            // item_category: item.meta.categoryName,
            // item_variant: item.variation,
            price: item.price,
            quantity: item.quantity,
          })),
        };
        this.sendEvent('begin_checkout', payload);
        return true;
      }
      case 'ORDER_SUBMIT_SUCCESS': {
        const { order, currency } = data;
        const payload = {
          value: order.cost.charged,
          currency,
          transaction_id: order._id,
          items: order.items.map(item => ({
            item_id: item.storeSku,
            item_name: item.name,
            // TODO Missing properties, populate order or take from store categories
            // item_category: item.meta.categoryName,
            // item_variant: item.variation,
            price: item.price,
            quantity: item.quantity,
          })),
        };
        this.sendEvent('purchase', payload);
        return true;
      }
      case 'PRODUCT_SEARCH': {
        const { searchString } = data;
        if (searchString && searchString.trim().length) {
          const payload = {
            search_term: searchString,
          };
          this.sendEvent('search', payload);
          this.debug(event, payload);
          return true;
        }
        break;
      }
      case 'PRODUCT_OPEN': {
        const { product, variation, currency } = data;
        const payload = {
          value: variation.price,
          currency,
          items: [{
            item_id: variation.storeSku,
            item_name: `${product.name} (${variation.name})`,
            item_category: product.category?.name,
            // item_variant: variation.name,
            price: variation.price,
            quantity: 1,
          }],
        };
        this.sendEvent('view_item', payload);
        return true;
      }
      default:
    }
    return false;
  }
}

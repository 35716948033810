import React from 'react';

import Base from './Base';

const Iframe = ({ node }) => (
  <Base
    node={node}
    as="iframe"
    src={node.iframe_src}
    width={node.iframe_width}
    height={node.iframe_height}
    frameBorder="0"
    allowFullScreen
  />
);

export default Iframe;

import React from 'react';
import useMedia from 'use-media';

import Content from './Content';
import Slider from './Slider';
import Stats from './Stats';
import Button from './Button';
import Cards from './Cards';

import Divider from './Divider';

export default function Sections({ sections }) {
  const isMobile = useMedia({ maxWidth: 1024 });

  return sections.map((section) => {
    if (
      section.show === 'hidden'
      || (section.show === 'desktop' && isMobile)
      || (section.show === 'mobile' && !isMobile)
    ) {
      return null;
    }

    if (section.type === 'CARDS') {
      return (
        <Cards
          id={section.id}
          key={section._id}
          section={section}
        />
      );
    }

    if (section.type === 'CONTENT') {
      return (
        <Content
          id={section.id}
          key={section._id}
          title={section.content_title}
          subtitle={section.content_subtitle}
          content={section.content_content}
          image={section.content_image}
          video={section.content_video}
          contentAlignment={section.content_alignContent}
          imageAlignment={section.content_alignImage}
          buttonLabel={section.content_buttonLabel}
          buttonLink={section.content_buttonLink}
          imageWidth={section.content_imageWidth}
        />
      );
    }

    if (section.type === 'SLIDER') {
      return (
        <Slider
          id={section.id}
          key={section._id}
          size={section.slider_size}
          style={section.slider_style}
          autoplay={section.slider_autoplay}
          overflow={section.slider_overflow}
          slides={section.slider_items}
        />
      );
    }

    if (section.type === 'STATS') {
      return (
        <Stats
          id={section.id}
          key={section._id}
          items={section.stats_items}
        />
      );
    }

    if (section.type === 'BUTTON') {
      return (
        <Button
          id={section.id}
          key={section._id}
          section={section}
        />
      );
    }

    if (section.type === 'DIVIDER') {
      return (
        <Divider
          id={section.id}
          key={section._id}
          height={section.divider_height}
        />
      );
    }

    return null;
  });
}

import React, { useMemo } from 'react';
import toHtmlString from 'draftjs-to-html';

import { createNamedStyled } from '../../stitches.config';

const styled = createNamedStyled('WYSIWYG');

const ProductHTMLFieldContentOverflow = styled.named('Wrapper')('div', {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    width: '100%',
    padding: '$s 0 0',
    fontFamily: '$text',
    color: '$text',
    opacity: 0.9,
    lineHeight: '135%',

    h1: {
      fontSize: '150%',
      fontWeight: '$title',
      marginBottom: '20px',
      marginTop: '20px',
      fontFamily: '$title',

      '&:first-child': {
        marginTop: '10px',
      },
    },

    p: {
      marginBottom: '18px',
    },

    strong: {
      fontWeight: '$textBold',
      color: '$textBold',
    },

    em: {
      fontStyle: 'italic',
    },

    ul: {
      listStyleType: 'disc',

      li: {
        marginBottom: '10px',
      },
    },

    ol: {
      listStyleType: 'decimal',

      li: {
        marginBottom: '10px',
      },
    },

    sup: {
      verticalAlign: 'super',
      fontSize: '80%',
    },

    sub: {
      verticalAlign: 'sub',
      fontSize: '80%',
    },

    img: {
      width: '100%',
    },

    blockquote: {
      padding: '5px 0 5px 10px',
      borderLeft: '3px solid hsla(0, 0%, 0%, 0.2)',
      marginBottom: '20px',
    },
  }
);

const WYSIWYG = ({ content: draftjsContent }) => {
  function parseCustomHtmlEntity({ type, data: { target, url, src } },
     text) {
    if (type === 'LINK') {
      return `<a href="${url}">${text}</a>`;
    }
    if (type === 'IMAGE') {
      return `<img src="${src || ''}" />`;
    }
    // eslint-disable-next-line no-console
    console.log('unhandled entity', type, { target, url, src },
     text);
    return text;
  }

  const content = useMemo(() => toHtmlString(
    draftjsContent,
    null,
    false,
    parseCustomHtmlEntity,
  ), [draftjsContent]);

  return (
    <ProductHTMLFieldContentOverflow
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default WYSIWYG;

/* eslint-disable max-len */
import * as React from 'react';

import { useTheme } from '../../theme';
import Wrapper from '../Wrapper';

function Facebook({ type, ...props }) {
  const { custom: { iconsStyle } } = useTheme();

  return (
    <Wrapper
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`type-${iconsStyle}`}
      {...props}
    >
      {iconsStyle === 'duotone' || iconsStyle === 'duotoneOffset' ? (
        <path
          d="M12.139 22.992c-.057 0-.113-.001-.168-.004a1.312 1.312 0 01-.11.004c-1.304 0-2.14-.591-2.95-1.163-.558-.394-1.085-.767-1.706-.87a5.524 5.524 0 00-.896-.076c-.525 0-.94.081-1.242.14a2.722 2.722 0 01-.463.067c-.126 0-.262-.027-.321-.23a7.102 7.102 0 01-.125-.51c-.092-.423-.158-.683-.335-.71-2.067-.32-2.66-.755-2.79-1.064A.401.401 0 011 18.444a.233.233 0 01.195-.243c3.177-.523 4.602-3.77 4.662-3.909l.005-.011c.194-.394.232-.737.113-1.017-.218-.514-.93-.74-1.4-.889-.115-.036-.224-.07-.31-.105-.94-.371-1.018-.752-.98-.947.062-.33.505-.562.862-.562.098 0 .185.018.257.052.423.198.804.298 1.132.298.454 0 .653-.19.677-.216l-.04-.671c-.095-1.503-.213-3.37.263-4.435 1.424-3.194 4.445-3.442 5.337-3.442l.391-.004h.053c.894 0 3.922.249 5.347 3.445.476 1.066.358 2.934.263 4.435l-.004.066c-.013.209-.026.412-.036.606.022.023.205.199.616.215h0c.314-.012.673-.112 1.068-.297a.804.804 0 01.331-.065c.134 0 .27.026.382.073l.007.003c.319.113.528.337.532.57.004.22-.164.551-.988.877a5.146 5.146 0 01-.31.105c-.472.15-1.183.375-1.4.889-.12.28-.081.622.113 1.016l.005.012c.06.138 1.483 3.384 4.662 3.908.118.02.201.124.195.243a.387.387 0 01-.033.133c-.13.307-.722.742-2.79 1.061-.169.026-.234.246-.335.708a7.111 7.111 0 01-.125.506c-.044.15-.138.221-.296.221h-.025c-.11 0-.266-.02-.463-.058a6.171 6.171 0 00-1.242-.132 5.53 5.53 0 00-.896.076c-.62.103-1.147.475-1.705.87-.811.572-1.648 1.163-2.951 1.163z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="duotone"
          opacity={0.6}
        />
      ) : null}
      {iconsStyle === 'solid' ? (
        <path
          d="M12.139 22.992c-.057 0-.113-.001-.168-.004a1.312 1.312 0 01-.11.004c-1.304 0-2.14-.591-2.95-1.163-.558-.394-1.085-.767-1.706-.87a5.524 5.524 0 00-.896-.076c-.525 0-.94.081-1.242.14a2.722 2.722 0 01-.463.067c-.126 0-.262-.027-.321-.23a7.102 7.102 0 01-.125-.51c-.092-.423-.158-.683-.335-.71-2.067-.32-2.66-.755-2.79-1.064A.401.401 0 011 18.444a.233.233 0 01.195-.243c3.177-.523 4.602-3.77 4.662-3.909l.005-.011c.194-.394.232-.737.113-1.017-.218-.514-.93-.74-1.4-.889-.115-.036-.224-.07-.31-.105-.94-.371-1.018-.752-.98-.947.062-.33.505-.562.862-.562.098 0 .185.018.257.052.423.198.804.298 1.132.298.454 0 .653-.19.677-.216l-.04-.671c-.095-1.503-.213-3.37.263-4.435 1.424-3.194 4.445-3.442 5.337-3.442l.391-.004h.053c.894 0 3.922.249 5.347 3.445.476 1.066.358 2.934.263 4.435l-.004.066c-.013.209-.026.412-.036.606.022.023.205.199.616.215.314-.012.673-.112 1.068-.297a.804.804 0 01.331-.065c.134 0 .27.026.382.073l.007.003c.319.113.528.337.532.57.004.22-.164.551-.988.877a5.146 5.146 0 01-.31.105c-.472.15-1.183.375-1.4.889-.12.28-.081.622.113 1.016l.005.012c.06.138 1.483 3.384 4.662 3.908.118.02.201.124.195.243a.387.387 0 01-.033.133c-.13.307-.722.742-2.79 1.061-.169.026-.234.246-.335.708a7.111 7.111 0 01-.125.506c-.044.15-.138.221-.296.221h-.025c-.11 0-.266-.02-.463-.058a6.171 6.171 0 00-1.242-.132 5.53 5.53 0 00-.896.076c-.62.103-1.147.475-1.705.87-.811.572-1.648 1.163-2.951 1.163z"
          fill="#000"
          className="solid"
        />
      ) : null}
      <path
        d="M12.139 22.992c-.057 0-.113-.001-.168-.004a1.312 1.312 0 01-.11.004c-1.304 0-2.14-.591-2.95-1.163-.558-.394-1.085-.767-1.706-.87a5.524 5.524 0 00-.896-.076c-.525 0-.94.081-1.242.14a2.722 2.722 0 01-.463.067c-.126 0-.262-.027-.321-.23a7.102 7.102 0 01-.125-.51c-.092-.423-.158-.683-.335-.71-2.067-.32-2.66-.755-2.79-1.064A.401.401 0 011 18.444a.233.233 0 01.195-.243c3.177-.523 4.602-3.77 4.662-3.909l.005-.011c.194-.394.232-.737.113-1.017-.218-.514-.93-.74-1.4-.889-.115-.036-.224-.07-.31-.105-.94-.371-1.018-.752-.98-.947.062-.33.505-.562.862-.562.098 0 .185.018.257.052.423.198.804.298 1.132.298.454 0 .653-.19.677-.216l-.04-.671c-.095-1.503-.213-3.37.263-4.435 1.424-3.194 4.445-3.442 5.337-3.442l.391-.004h.053c.894 0 3.922.249 5.347 3.445.476 1.066.358 2.934.263 4.435l-.004.066c-.013.209-.026.412-.036.606.022.023.205.199.616.215h0c.314-.012.673-.112 1.068-.297a.804.804 0 01.331-.065c.134 0 .27.026.382.073l.007.003c.319.113.528.337.532.57.004.22-.164.551-.988.877a5.146 5.146 0 01-.31.105c-.472.15-1.183.375-1.4.889-.12.28-.081.622.113 1.016l.005.012c.06.138 1.483 3.384 4.662 3.908.118.02.201.124.195.243a.387.387 0 01-.033.133c-.13.307-.722.742-2.79 1.061-.169.026-.234.246-.335.708a7.111 7.111 0 01-.125.506c-.044.15-.138.221-.296.221h-.025c-.11 0-.266-.02-.463-.058a6.171 6.171 0 00-1.242-.132 5.53 5.53 0 00-.896.076c-.62.103-1.147.475-1.705.87-.811.572-1.648 1.163-2.951 1.163z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={iconsStyle === 'solid' ? 1 : 2}
        className="base"
      />
    </Wrapper>
  );
}

export default Facebook;

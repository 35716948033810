export default Main => class AsyncState extends Main {
  async setState(update, callback) {
    return new Promise(resolve => super.setState(update, () => {
      callback && callback();
      resolve(this.state);
    }));
  }

  async getState() {
    return new Promise(resolve => super.setState(state => resolve(state)));
  }
};

import React, { useRef } from 'react';

import { createNamedStyled } from '../../stitches.config';

import { useTrueElementSize } from '../../helpers/useTrueElementSize';

const styled = createNamedStyled('AutoLayout');

const Container = styled.named('Container')('div', {
  position: 'relative',
  display: 'flex',
  flex: 1,
});

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flex: '1 0 100%',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  width: '100%',
  top: 0,

  '& > *': { whiteSpace: 'nowrap' },
});

const TestContainer = styled.named('TestContainer')('div', {
  position: 'absolute',
  visibility: 'hidden',
  inset: 0,
  overflow: 'hidden',
  pointerEvents: 'none',
});

const Test = styled.named('Test')('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  position: 'absolute',
  visibility: 'hidden',
  height: 'auto',
  width: 'auto',
  whiteSpace: 'nowrap',
  pointerEvents: 'none',
  '*': { visibility: 'hidden !important' },
  '& > *': { whiteSpace: 'nowrap' },
});

const AutoLayout = ({
  children,
  gap,
  padding,
  align,
  justify,
  swapWith,
  alignSwap,
  justifySwap,
  hide,
  disabled,
  ...props
}) => {
  const containerRef = useRef();
  const testRef = useRef();

  const { elementWidth: requiredWidth } = useTrueElementSize(testRef);
  const { elementWidth: containerWidth } = useTrueElementSize(containerRef);

  return (
    <>
      <Container
        ref={containerRef}
        css={{ justifyContent: justifySwap }}
        {...props}
      >
        {disabled ? (
          <Wrapper
            css={{
              gap,
              padding,
              alignItems: align,
              justifyContent: justify,
            }}
          >
            {children}
          </Wrapper>
        ) : (
          <>
            {((containerWidth <= requiredWidth) && swapWith) ? (
              <>
                { swapWith }
              </>
            ) : (
              <>
              {((containerWidth <= requiredWidth) && hide) ? null : (
                <Wrapper
                  css={{
                    gap,
                    padding,
                    alignItems: align,
                    justifyContent: justify,
                    ...(containerWidth <= requiredWidth ? {
                      flexDirection: 'column',
                    } : {}),
                  }}
                >
                  {children}
                </Wrapper>
              )}
              </>
            )}
          </>
        )}
      </Container>
      <TestContainer>
        <Test
          className="test"
          ref={testRef}
          css={{ gap, padding }}
        >
          {children}
        </Test>
      </TestContainer>
    </>
  );
};

export default AutoLayout;

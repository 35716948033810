import React, { useState, useRef, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import isObject from 'lodash/isObject';

import { createNamedStyled } from '../../stitches.config';
// import { useTheme } from '../../theme';

import useClickOutside from '../../helpers/useClickOutside';

import Link from './Link';
import { Label, Paragraph } from '../Elements/Text';

const styled = createNamedStyled('DropSelect');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',

  padding: 'calc($xs / 1.5) 0',

  outline: 'none',
  userSelect: 'none',
  '*': { userSelect: 'none' },

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    // disabled: {
    //   true: {
    //     pointerEvents: 'none',
    //     opacity: 0.5,
    //   },
    // },
  },
});

const Value = styled.named('Value')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',

  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$s',
  cursor: 'pointer',

  padding: 'calc($xs / 2) 0',

  transition: 'border-bottom $s',

  variants: {
    underlined: {
      true: {
        borderBottom: '1px solid $borderLight',
      },
    },
    isOpen: {
      true: {
        borderColor: '$border',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },

  // TODO: Do this dynamically
  minHeight: 36,
});

const Arrow = styled.named('Arrow')('div', {
  position: 'relative',
  width: 12,
  height: 12,

  '&:before, &:after': {
    content: '',
    position: 'absolute',
    top: '50%',
    width: 8,
    height: 1,
    borderRadius: 1,
    background: '$icon',
    transition: 'background $s',
  },

  '&:before': {
    transform: 'translateX(-100%) translateX(3px) rotate(45deg)',
  },

  '&:after': {
    transform: 'rotate(-45deg)',
  },

  [`${Wrapper}:hover &`]: {
    '&:before, &:after': {
      background: '$accent',
    },
  },

  variants: {
    position: {
      top: {
        transform: 'rotate(180deg)',
      },
    },
  },
});

const Options = styled.named('Options')('div', {
  position: 'absolute',
  zIndex: 2,

  variants: {
    position: {
      bottom: {
        top: '100%',
        paddingTop: 2,
        translate: '0 -10%',
      },
      top: {
        bottom: '100%',
        paddingBottom: 2,
        translate: '0 10%',
      },
    },

    isOpen: {
      true: {
        opacity: 1,
        translate: '0 0 !important',
        pointerEvents: 'auto',
        transition: 'opacity $s, translate $m $ease',
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity $s, translate $xl',
      },
    },

    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
});

const OptionsList = styled.named('OptionsList')('div', {
  display: 'flex',
  flexDirection: 'column',
  background: '$dropdownBackground',
  borderRadius: '$m',
  boxShadow: '$m',

  overflow: 'scroll',
  maxHeight: 400,

  width: 'fit-content',
  minWidth: '100%',
});

const Option = styled.named('Option')('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 'calc($s / 1.5)',
  cursor: 'pointer',

  transition: 'background $s, color $s',
  '&:hover': {
    background: '$buttonBackground',
    color: '$buttonForeground',
    '*': { color: '$buttonForeground' },
  },

  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        opacity: 0.25,
      },
    },
    faded: {
      true: {
        opacity: 0.4,
      },
    },
  },
});

const Search = styled.named('Search')('input', {
  // all: 'unset',
  border: 'none',
  outline: 'none',
  WebkitBoxShadow: 'none',
  MozBoxShadow: 'none',
  boxShadow: 'none',
  // borderRadius: 0,
  // background: 'none',
  borderRadius: '$m $m 0 0',
  background: '$dropdownBackground',

  width: '100%',
  boxSizing: 'border-box',
  padding: 'calc($s / 1.5)',
  borderBottom: '1px solid $borderLight',

  position: 'sticky',
  top: 0,
  zIndex: 1,

  fontFamily: '$text',
  fontSize: '80%',

  tabIndex: -1,
});

const ValueWrapper = ({ children, valueStyle, ...props }) => (isObject(children)
  ? children
  : valueStyle === 'label'
    ? (
      <Label {...props}>
        {children}
      </Label>
    ) : (
      <Paragraph {...props}>
        {children}
      </Paragraph>
    )
);

const DropSelect = ({
  label,
  value,
  onChange,
  options = [],
  hoverable = false,
  searchable = false,
  fullWidth = false,
  underlined = false,
  valueStyle = 'body',
  position = 'bottom',
  align = 'start',
  alignOptions = 'start',
  ...props
}) => {
  // const { colors: { brand } } = useTheme();

  const ref = useRef();

  const searchRef = useRef();
  const [search, setSearch] = useState('');

  const optionsRef = useRef();
  const [optionsWidth, setOptionsWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setOptionsWidth(optionsRef.current.offsetWidth);
    }
  }, []);

  const filteredOptions = options.filter(option => (
    option?.label?.toLowerCase().includes(search.toLowerCase())
    || option?.description?.toLowerCase().includes(search.toLowerCase())
  ));

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(0);

  const selectedIndex = options.findIndex(option => option.value === value);

  useEffect(() => {
    if (isOpen) {
      setSelected(selectedIndex);

      searchable && searchRef.current.focus();
    } else {
      setSearch('');
    }
  }, [isOpen, selectedIndex, searchable]);

  useClickOutside(ref, () => setIsOpen(false));
  useHotkeys('esc', () => setIsOpen(false));

  const handleKeyDown = (event) => {
    if (isOpen) {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();

        setSelected(prev => Math.min(prev + 1, filteredOptions.length - 1));
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        event.stopPropagation();

        setSelected(prev => Math.max(prev - 1, 0));
      } else if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        onChange(filteredOptions[selected]);
        setIsOpen(false);
      } else if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();

        setIsOpen(false);
      } else {
        setSelected(0);
      }
    }
  };

  return (
    <Wrapper
      {...props}
      ref={ref}
      onMouseEnter={() => hoverable && setIsOpen(true)}
      onMouseLeave={() => hoverable && setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      fullWidth={fullWidth}
      tabIndex={0}
    >
      <Value
        fullWidth={fullWidth}
        underlined={underlined}
        isOpen={isOpen}
      >
        <Paragraph
          css={{
            position: 'absolute',
            bottom: 'calc($space$xs / 4)',
            opacity: 0.8,
            transformOrigin: '$custom$inlineStart bottom',
            transition: 'transform $m $ease',
            ...(value !== '' || isOpen) && {
              transform:
                'translateY(-100%) scale(0.75)',
            },
          }}
        >
          {label}
        </Paragraph>
        <ValueWrapper
          valueStyle={valueStyle}
          css={{
            transition: 'color $s',
            [`${Wrapper}:hover &`]: { color: '$buttonBackground' },
          }}
        >
          {value}
        </ValueWrapper>
        { onChange ? <Arrow position={position} /> : null }
      </Value>

      <Options
        ref={optionsRef}
        isOpen={isOpen}
        position={position}
        fullWidth={fullWidth}
        css={{
          insetInlineStart: align === 'start'
            ? 0 : align === 'center' ? '50%' : 'unset',
          insetInlineEnd: align === 'end' ? 0 : 'unset',
          transform: align === 'center'
            ? '$custom$inlineTranslateCenter' : 'unset',
          alignItems: alignOptions === 'stat'
            ? 'flex-start'
            : alignOptions === 'center'
              ? 'center'
              : 'flex-end',
        }}
      >
        <OptionsList>
          {searchable ? (
            <Search
              ref={searchRef}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              value={search}
              placeholder="Search..."
              css={{ minWidth: optionsWidth }}
              tabIndex={-1}
            />
          ) : null}
          {filteredOptions.map((option, index) => (
            <Option
              key={option?._id || option?.value}
              as={onChange ? 'div' : Link}
              to={onChange ? undefined : option?.value}
              onClick={() => {
                option.onClick && option.onClick();
                onChange && onChange(option);
                setIsOpen(false);
                ref.current.blur();
              }}
              css={{
                textAlign: alignOptions,
                background: index === selected ? '$accentLight' : 'unset',
              }}
              disabled={option.disabled}
              faded={option.faded}
            >
              <ValueWrapper
                valueStyle={valueStyle}
                css={{
                  color: '$dropdownForeground',
                  ':hover > &': { color: '$buttonForeground' },
                }}
              >
                {option?.label}
              </ValueWrapper>
              <Paragraph css={{
                fontSize: '75%',
                opacity: 0.5,
                whiteSpace: 'nowrap',
                ':hover > &': { color: '$buttonForeground' },
                color: '$dropdownForeground',
              }}
              >
                {option?.description}
              </Paragraph>
            </Option>
          ))}
        </OptionsList>
      </Options>
    </Wrapper>
  );
};

export default DropSelect;

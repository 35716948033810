/* eslint-disable import/prefer-default-export */

import { useEffect, useRef, useState } from 'react';

export const useTrueElementSize = (elementRef) => {
  const [elementSize, setElementSize] = useState({
    elementWidth: 0,
    elementHeight: 0,
  });

  const observerRef = useRef(
    new ResizeObserver((entries) => {
      const { clientWidth, clientHeight } = entries[0].target;
      setElementSize({
        elementWidth: clientWidth,
        elementHeight: clientHeight,
      });
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observerRef;
    const { current: currentElement } = elementRef;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      currentElement && currentObserver.unobserve(currentElement);
    };
  }, [elementRef, observerRef]);

  return elementSize;
};

export default class AnalyticsTracker {
  static NAME = 'Tracker'

  static debug(...args) {
    // eslint-disable-next-line no-console
    console.log(`Tracker/${this.NAME}`, ...args);
  }

  constructor() {
    this.active = false;
  }

  debug = (...args) => {
    this.constructor.debug(...args);
  }

  // eslint-disable-next-line no-unused-vars
  setup(props) {

  }

  // eslint-disable-next-line no-unused-vars
  trigger(event, ...args) {

  }
}

/* eslint-disable max-len */
import * as React from 'react';

import { useTheme } from '../../theme';
import Wrapper from '../Wrapper';

function Whatsapp({ type, ...props }) {
  const { custom: { iconsStyle } } = useTheme();

  return (
    <Wrapper
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`type-${iconsStyle}`}
      {...props}
    >
      {iconsStyle === 'duotone' || iconsStyle === 'duotoneOffset' ? (
        <path
          d="M6.727 20.49A9.977 9.977 0 0012.01 22C17.527 22 22 17.527 22 12S17.527 2 12.01 2c-5.52 0-10 4.473-10 10 0 1.954.563 3.773 1.526 5.318L2 21.973l4.727-1.482z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          strokeLinejoin="round"
          className="duotone"
          opacity={0.6}
        />
      ) : null}
      {iconsStyle === 'solid' ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.01 22a9.977 9.977 0 01-5.283-1.51L2 21.974l1.536-4.655A10.013 10.013 0 012.01 12c0-5.527 4.482-10 10-10S22 6.473 22 12s-4.473 10-9.99 10zm2.226-8.743c.363-.355.809-.355 1.172 0l1.31 1.309c.372.364.372.818 0 1.19-.099.103-.2.201-.3.3a9.696 9.696 0 00-.446.455c-.345.382-.773.51-1.264.482-.718-.036-1.372-.272-2.009-.582a10.92 10.92 0 01-3.627-2.836c-.755-.882-1.364-1.854-1.773-2.945-.19-.528-.336-1.073-.29-1.646a1.31 1.31 0 01.418-.9c.181-.176.363-.36.545-.543l.273-.275c.363-.355.809-.355 1.172 0 .152.146.3.296.447.444l.217.22.646.645c.372.382.372.818 0 1.2-.264.273-.537.545-.81.81-.072.072-.081.126-.045.217.182.428.446.81.736 1.173.582.718 1.255 1.364 2.046 1.864.096.06.197.11.299.159.08.039.16.078.237.123.091.045.155.027.228-.046l.818-.818z"
          fill="#000"
          className="solid"
        />
      ) : (
        <path
          d="M15.408 13.257c-.363-.355-.809-.355-1.172 0l-.819.818c-.072.073-.136.09-.227.046-.172-.1-.363-.173-.536-.282-.791-.5-1.464-1.146-2.046-1.864-.29-.364-.554-.745-.736-1.173-.036-.09-.027-.145.045-.218.273-.264.546-.536.81-.809.372-.382.372-.818 0-1.2l-.646-.645c-.218-.219-.436-.446-.664-.664-.363-.355-.809-.355-1.172 0-.273.273-.546.554-.818.818a1.31 1.31 0 00-.419.9c-.045.573.1 1.118.291 1.646.41 1.09 1.018 2.063 1.773 2.945a10.92 10.92 0 003.627 2.836c.637.31 1.291.546 2.01.582.49.027.918-.1 1.263-.482.236-.263.5-.5.746-.754.372-.373.372-.827 0-1.191l-1.319-1.318.01.009z"
          stroke="#000"
          strokeWidth={1.5}
          strokeMiterlimit={10}
          className="line"
        />
      )}
      <path
        d="M6.727 20.49A9.977 9.977 0 0012.01 22C17.527 22 22 17.527 22 12S17.527 2 12.01 2c-5.52 0-10 4.473-10 10 0 1.954.563 3.773 1.526 5.318L2 21.973l4.727-1.482z"
        stroke="#000"
        strokeWidth={iconsStyle === 'solid' ? 1 : 2}
        strokeLinejoin="round"
        className="base"
      />
    </Wrapper>
  );
}

export default Whatsapp;

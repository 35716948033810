import {
  useMemo,
} from 'react';

import { useStore, useStoreCurrency } from '../context/Store';

import Types from '../modules/types';

export function getPriceWithVat(
  value,
  storeData,
  currencyObject,
  vat = 0,
) {
  const { VAT } = Types.getSystemCountry(storeData.systemCountry);
  const finalVat = (
    Number.isFinite(vat)
    ? vat
    : vat === true && storeData?.trn?.active
    ? VAT
    : 0
  );
  const { rate = 1 } = storeData?.storeCurrenciesMap?.[
    currencyObject?.code
  ] || {};
  return Types.getRoundedAmount((value + (value * finalVat)) * rate);
}

export function getPriceWithCurrency(
  value,
  storeData,
  currencyObject,
  vat = 0,
  format = true,
  skipCurrencyConversion = false,
) {
  const systemCountry = Types.getSystemCountry(storeData.systemCountry);
  const { VAT } = systemCountry;
  const finalVat = (
    Number.isFinite(vat)
    ? vat
    : vat === true && storeData?.trn?.active
    ? VAT
    : 0
  );
  const { code, rate } = (
    storeData.storeCurrenciesMap[currencyObject?.code]
    || { code: systemCountry.currency, rate: 1 }
  );
  const price = skipCurrencyConversion
    ? Types.getRoundedAmount(value + (value * finalVat))
    : Types.getRoundedAmount((value + (value * finalVat)) * rate);
  return (
    format
    ? `${code} ${Types.decimalize(price)}`
    : price
  );
}

export function getProductPriceWithCurrency(
  value,
  storeData,
  currencyObject,
  format,
  skipCurrencyConversion = false,
) {
  return getPriceWithCurrency(
    value,
    storeData,
    currencyObject,
    storeData?.showPricesWithVat === false ? false : true,
    format,
    skipCurrencyConversion,
  );
}

export function usePriceWithCurrency(
  value,
  product = false,
  vat = 0,
  useDefaultCurrency = false,
  format = true,
  skipCurrencyConversion = false,
) {
  const { data: storeData } = useStore();
  const [currencyObjectRaw] = useStoreCurrency();
  const currencyObject = useDefaultCurrency ? null : currencyObjectRaw;
  return useMemo(
    () => (
      product
      ? getProductPriceWithCurrency(
        value, storeData, currencyObject, format, skipCurrencyConversion
      )
      : getPriceWithCurrency(
        value, storeData, currencyObject, vat, format, skipCurrencyConversion
      )
    ),
    [
      storeData,
      currencyObject,
      vat,
      product,
      value,
      format,
      skipCurrencyConversion,
    ],
  );
}

const PriceWithCurrency = ({
  value, vat = 0, product = false, skipCurrencyConversion = false,
}) => (
  usePriceWithCurrency(value, product, vat, false, true, skipCurrencyConversion)
);

export default PriceWithCurrency;

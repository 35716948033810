import React, { useMemo } from 'react';

import optimizeInlineCss from '../../helpers/optimizeInlineCss';

export default function Component({
  node,
  renderNode,
}) {
  const finalCss = useMemo(
    () => ({
      ...node.css,
      ...(node?.hide || []).reduce((acc, device) => ({
        ...acc,
        [`@${device.toLowerCase()}`]: { display: 'none !important' },
      }), {}),
      ...node?.stitchesCss,
    }),
    [node.css, node?.hide, node?.stitchesCss],
  );

  return (
    <>
      {node.component_customCss && (
        <style>{optimizeInlineCss(node.component_customCss)}</style>
      )}
      {(node.content || node.component_items || []).map(item => renderNode({
        ...item,
        name: node.name || item.name,
        id: node.id || item.id,
        className: `${item.className || ''} ${node.className || ''}`,
        css: finalCss,
    }))}
    </>
  );
}

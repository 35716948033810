import React, { useRef, useEffect, useState } from 'react';
import { styled } from '../../stitches.config';

import arIcon from '../../assets/cube.svg';

const Icon = styled('img', {
  width: 80,
});

const Wrapper = styled('div', {});

const ArPreview = ({ models, ...props }) => {
  const ref = useRef(null);

  const [isIos, setIsIos] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIos(
      userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1
    );
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsAndroid(userAgent.indexOf('android') !== -1);
  }, []);

  if (!isIos && !isAndroid) { return null; }

  if (isIos && models?.ios?.src) {
    return (
      <Wrapper as="a" ref={ref} rel="ar" href={models?.ios?.src} {...props}>
        <Icon src={arIcon} alt="Launch in AR" />
      </Wrapper>
    );
  }

  if (isAndroid && models?.android?.src) {
    return (
      // eslint-disable-next-line max-len
      <a href={`intent://arvr.google.com/scene-viewer/1.0?file=${models?.android?.src}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`}>
        <Icon src={arIcon} alt="Launch in AR" />
      </a>
    );
  }

  return null;
};

export default ArPreview;

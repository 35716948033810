import React from 'react';
import { createNamedStyled } from '../../stitches.config';

import { Title, Label, Paragraph } from '../Elements/Text';

const styled = createNamedStyled('LegacyStats');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  gap: '$m',

  maxWidth: '$siteWidth',
  margin: '0 auto',

  padding: '$m $s',
  '@desktop+': { padding: '$l $m' },

  '@mobile': {
    flexDirection: 'column',
  },

  zoom: '1.25',
});

const Stat = styled.named('Stat')('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '$xs',
});

const Stats = ({ items, ...props }) => (
  <Wrapper {...props}>
    {items.map(item => (
      <Stat key={item._id} {...props}>
        <Label css={{ marginBottom: -10 }}>{item.title}</Label>
        <Title bold style={{ fontSize: '320%' }}>{item.value}</Title>
        <Paragraph css={{ opacity: 0.5, fontSize: '75%' }}>
          {item.subtitle}
        </Paragraph>
      </Stat>
    ))}
  </Wrapper>
);

export default Stats;

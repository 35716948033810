import getKey from 'lodash/get';

import React, { createContext, useContext } from 'react';

import ContextProviderComponent from './ContextProviderComponent';
import ApiContext from './Api';
import StoreContext from './Store';
import CartContext from './Cart';
import { useDictionary } from './Language';

import Types from '../modules/types';

const {
  CONSTANTS: {
    StoreProductType,
  },
} = Types;

const Context = createContext();

export default Context;

export function Provider(props) {
  const api = useContext(ApiContext);
  const { loading, data } = useContext(StoreContext);
  const cart = useContext(CartContext);
  const dictionary = useDictionary();
  if (loading || !data) {
    return null;
  }
  const { currency: dictionaryCurrency } = dictionary;
  const { currency: systemCurrency } = Types.getSystemCountry(
    data.systemCountry,
  );
  return (
    <ProductProvider
      api={api}
      store={data}
      toggleCart={cart.toggleShowCart}
      currency={dictionaryCurrency[systemCurrency]}
      {...props}
    />
  );
}

export function parseProduct(
  product,
  store,
  currency,
  parseRelatedProducts = false,
) {
  const {
    VAT,
  } = Types.getSystemCountry(product.systemCountry);
  const hasVat = store?.trn?.active === true;

  product.stockLevel = 0;
  product.inStock = false;
  product.priceLowestFormatted = `${
    currency
  } ${
    Types.decimalize(
      Types.getRoundedAmount(
        product.priceLowest + (
          hasVat
          ? (product.priceLowest * VAT)
          : 0
        ),
      ),
    )
  }`;
  product.priceHighestFormatted = `${
    currency
  } ${
    Types.decimalize(
      Types.getRoundedAmount(
        product.priceHighest + (
          hasVat
          ? (product.priceHighest * VAT)
          : 0
        ),
      ),
    )
  }`;
  if (
       product.type === StoreProductType.MERCHANT_PRODUCT
    || product.type === StoreProductType.RESELLER_PRODUCT
  ) {
    product.skusInStock = [];
    if (
      parseRelatedProducts
      && product.relatedProducts
      && product.relatedProducts.length
    ) {
      for (let p = 0; p < product.relatedProducts.length; p++) {
        try {
          parseProduct(product.relatedProducts[p], store, currency, false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(
            'error parsing related product',
            product.relatedProducts[p],
            error,
          );
        }
      }
    }
    for (let v = 0; v < product.variations.length; v++) {
      const variation = product.variations[v];
      variation.stock = 0;
      variation.stockLevel = 0;
      variation.priceFormatted = `${
        currency
      } ${
        Types.decimalize(
          Types.getRoundedAmount(
            variation.price + (
              hasVat
              ? (variation.price * VAT)
              : 0
            ),
          ),
        )
      }`;
      variation.priceBeforeSaleFormatted = `${
        currency
      } ${
        Types.decimalize(
          Types.getRoundedAmount(
            variation.priceBeforeSale + (
              hasVat
              ? (variation.priceBeforeSale * VAT)
              : 0
            ),
          ),
        )
      }`;
      if (!product.firstSkuInStock) {
        product.price = variation.price;
        product.priceBeforeSale = variation.priceBeforeSale;
      }
      for (let s = 0; s < Types.SHIPPING_STORAGE.length; s++) {
        const shippingStorage = Types.SHIPPING_STORAGE[s];
        if (store.storageOptions.includes(shippingStorage)) {
          const stock = (
            Types.PRODUCT_KIND_ABSTRACT.includes(product.kind)
            ? Infinity
            : getKey(
                product,
                `stock.${variation._id}.${shippingStorage}`,
                0,
              )
          );
          if (stock > 0) {
            product.inStock = true;
            product.stockLevel = (product.stockLevel || 0) + stock;
            variation.inStock = true;
            variation.stock = (variation.stock || 0) + stock;
            variation.stockLevel = (variation.stockLevel || 0) + stock;
            if (!product.skusInStock.includes(variation.sku)) {
              product.skusInStock.push(variation.sku);
            }
            if (!product.firstSkuInStock) {
              product.firstSkuInStock = variation.sku;
              product.firstSkuInStockPrice = variation.price;
              product.firstSkuInStockPriceBeforeSale = (
                variation.priceBeforeSale
              );
              product.price = variation.price;
              product.priceBeforeSale = variation.priceBeforeSale;
            }
            break;
          }
        }
      }
    }
  } else if (
    product.type === StoreProductType.REFERRAL_PRODUCT
  ) {
    if (product.referralStore) {
      product.referralStoreSlug = product.referralStore.slug;
      delete product.referralStore;
    }
    if (product.referralProduct) {
      product.referralProductSlug = product.referralProduct.slug;
      delete product.referralProduct;
    }
  }
  return product;
}

export class ProductProvider extends ContextProviderComponent {
  static NAME = 'PRODUCT'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  constructor(props) {
    super(props);
    this.state.loading = true;
    this.state.data = null;
    this.state.slug = null;
    this.state.variationSlug = null;
    this.exposeMethods(
      'setVariationSlug',
      'setVariationSlugFromSku',
    );
  }

  componentDidMount() {
    this.fetchProduct(this.props);
  }

  componentDidUpdate(props) {
    if (
      this.props.store !== props.store
      || this.props.slug !== props.slug
      || this.props.currency !== props.currency
    ) {
      this.fetchProduct(this.props);
    } else if (
      this.props.variationSlug !== props.variationSlug
      || this.props.currency !== props.currency
    ) {
      this.updateVariationSlug(this.props);
    }
  }

  updateVariationSlug({ variationSlug }) {
    this.props.toggleCart(false);
    this.setState({ variationSlug });
  }

  async fetchProduct({
    store,
    slug = null,
    variationSlug = null,
    currency,
  }) {
    if (this.aborter) {
      this.aborter.abort();
    }
    if (!slug) {
      this.setState({
        loading: false,
        data: null,
        slug: null,
        variationSlug: null,
      });
    } else {
      this.setState({
        loading: true,
        data: null,
      });
      const aborter = new AbortController();
      try {
        const result = await this.props.api.get(
          'products',
          {
            where: {
              store: store._id,
              slug,
            },
            populate: {
              referralStore: true,
              referralProduct: true,
              relatedProducts: true,
            },
            limit: 1,
            includeSections: true,
          },
          this.aborter,
          this.aborter = aborter,
        );
        if (result && result.length) {
          this.props.toggleCart(false);
          this.setState({
            loading: false,
            data: parseProduct(
              { ...result[0] },
              store,
              currency,
              true,
            ),
            slug,
            variationSlug,
          });
        } else {
          throw new Error(`Product "${slug}" not found`);
        }
      } catch (error) {
        this.setState({
          loading: false,
          data: null,
          slug,
          variationSlug,
        });
      }
    }
  }

  setVariationSlug = (slug) => {
    this.setState({ variationSlug: slug });
  }

  setVariationSlugFromSku = (sku) => {
    if (this.state.data.variations) {
      const variation = this.state.data.variations.find(
        testVariation => testVariation.sku === sku
      );
      if (variation && variation.inStock) {
        this.setVariationSlug(variation.slug);
      }
    }
  }

  render() {
    return super.render();
  }
}

export function useProduct() {
  return useContext(Context);
}

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useStore } from '../../context/Store';

import { styled } from '../../stitches.config';
import { useTheme } from '../../theme';

import Button from './Button';
import { Whatsapp } from '../../icons';

const Wrapper = styled(motion.div, {
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  bottom: '$s',
  insetInlineEnd: '$s',
});

export default function Chat({ visible = true }) {
  const { transitions: { spring } } = useTheme();
  const { data: store } = useStore();

  return (
    <AnimatePresence>
      {visible && store.integrations.whatsApp.supported && (
        <Wrapper
          key="floating-chat-button"
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 200, opacity: 0 }}
          transition={{ ...spring }}
        >
          <Button
            round
            to={`https://wa.me/${store.integrations.whatsApp.phoneNumber}`}
          >
            <Whatsapp css={{
                '.line': { stroke: '$buttonForeground' },
                '.solid': { fill: '$buttonForeground' },
              }}
            />
          </Button>
        </Wrapper>
      )}
    </AnimatePresence>
  );
}

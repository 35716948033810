import React from 'react';
// import { styled } from '../../stitches.config';

// const HEIGHT = {
//   desktop: {
//     small: '$space$m',
//     medium: '$space$l',
//     large: '$space$xl',
//   },
//   mobile: {
//     small: '$space$s',
//     medium: '$space$m',
//     large: '$space$l',
//   },
// };

// const Wrapper = styled('div', {});

// const Divider = ({ height, id }) => (
//   <Wrapper
//     id={id}
//     css={{
//       height: HEIGHT.desktop[height],
//       '@tablet': { height: HEIGHT.mobile[height] },
//     }}
//   />
// );

// export default Divider;

// TODO: Remove divider
export default () => (<></>);

import React from 'react';
import isString from 'lodash/isString';

import { rem } from 'polished';
import { createNamedStyled } from '../../stitches.config';

const styled = createNamedStyled('Text');

export const Title = styled.named('Title')('h1', {
  fontFamily: '$title',
  lineHeight: '$title',
  color: '$title',

  letterSpacing: '$title',
  textTransform: '$textTransforms$title',

  userSelect: 'text !important',

  variants: {
    size: {
      // HACK: 18px is the smallest size we want to use for titles
      xs: {
        fontSize: rem('18px'),
        '@desktop-': { fontSize: rem('16px') },
        '@mobile': { fontSize: rem('14px') },
      },

      s: { fontSize: '$s' },
      m: { fontSize: '$m' },
      l: { fontSize: '$l' },
      xl: { fontSize: '$xl' },
    },
    bold: {
      false: { fontWeight: '$title' },
      true: { fontWeight: '$titleBold' },
    },
  },

  defaultVariants: {
    size: 'm',
    bold: false,
  },
});

export const Paragraph = styled.named('Paragraph')('p', {
  fontFamily: '$text',
  fontSize: '$text',
  fontWeight: '$text',
  lineHeight: '$text',
  color: '$text',

  letterSpacing: '$text',
  textTransform: '$textTransforms$text',

  userSelect: 'text !important',

  variants: {
    bold: {
      false: { fontWeight: '$text' },
      true: { fontWeight: '$textBold' },
    },
    small: {
      true: { fontSize: '$copy', opacity: 0.75 },
    },
  },
});

export const Copyright = styled.named('Copyright')(Paragraph, {
  opacity: 0.5,
});

export const LabelWrapper = styled.named('Label')('p', {
  fontFamily: '$label',
  fontSize: '$label',
  fontWeight: '$label',
  lineHeight: '$label',
  color: '$label',

  letterSpacing: '$label',
  textTransform: '$textTransforms$label',

  display: 'flex',
  alignItems: 'center',
  gap: '$xs',

  variants: {
    bold: {
      false: { fontWeight: '$label' },
      true: { fontWeight: '$labelBold' },
    },
  },
});

export const Label = ({ children, css, ...props }) => {
  const isArabic = isString(children) && children?.match(/[\u0600-\u06FF]/);

  return (
    <LabelWrapper
      css={{
        ...isArabic && { letterSpacing: 0 },
        ...css,
      }}
      {...props}
    >
      {children}
    </LabelWrapper>
  );
};

export const LinkWrapper = styled.named('Link')(Label, {
  fontFamily: '$label',
  fontSize: '$label',
  fontWeight: '$label',
  lineHeight: '$label',
  letterSpacing: '$label',
  color: '$label',
  cursor: 'pointer',
  position: 'relative',
  transition: 'color $s',
  pointerEvents: 'auto',
  '&:hover': { color: '$accent' },
});

export const Link = ({ isActive, children, css, ...props }) => {
  const isArabic = isString(children) && children?.match(/[\u0600-\u06FF]/);

  return (
    <LinkWrapper
      css={{
        ...isActive && { color: '$accent' },
        ...isArabic && { letterSpacing: 0 },
        ...css,
      }}
      {...props}
    >
      {children}
    </LinkWrapper>
  );
};

import React from 'react';

import Component from '../components/Component';
import Block from '../components/Block';
import Frame from '../components/Frame';
import Text from '../components/Text';
import TextBlock from '../components/TextBlock';
import Media from '../components/Media';
import Slider from '../components/Slider';
import Toggle from '../components/Toggle';
import Products from '../components/Products';
import Iframe from '../components/Iframe';

import AdapterButton from '../adapters/Button';

import LegacyContent from '../legacy/Content';
import LegacySlider from '../legacy/Slider';
import LegacyCards from '../legacy/Cards';
import LegacyStats from '../legacy/Stats';
import LegacyButton from '../legacy/Button';

const renderNode = (node) => {
  switch (node.type) {
    case 'COMPONENT':
      return (
        <Component key={node._id} node={node} renderNode={renderNode} />
      );
    case 'BLOCK':
      return (
        <Block key={node._id} node={node} renderNode={renderNode} />
      );
    case 'FRAME':
      return (
        <Frame key={node._id} node={node} renderNode={renderNode} />
      );
    case 'SLIDER':
      return (
        <Slider key={node._id} node={node} renderNode={renderNode} />
      );
    case 'TOGGLE':
      return (
        <Toggle key={node._id} node={node} renderNode={renderNode} />
      );
    case 'TEXT':
      return (
        <Text key={node._id} node={node} />
      );
    case 'TEXT_BLOCK':
      return (
        <TextBlock key={node._id} node={node} />
      );
    case 'MEDIA':
      return (
        <Media key={node._id} node={node} />
      );
    case 'PRODUCTS':
      return (
        <Products key={node._id} node={node} />
      );
    case 'IFRAME':
      return (
        <Iframe key={node._id} node={node} />
      );
    // ADAPTERS
    case 'BUTTON':
      return (
        <AdapterButton key={node._id} node={node} />
      );
    // LEGACY
    case 'LEGACY_CONTENT':
      return (
        <LegacyContent key={node._id} node={node} />
      );
    case 'LEGACY_SLIDER':
      return (
        <LegacySlider key={node._id} node={node} />
      );
    case 'LEGACY_CARDS':
      return (
        <LegacyCards key={node._id} node={node} />
      );
    case 'LEGACY_STATS':
      return (
        <LegacyStats key={node._id} node={node} />
      );
    case 'LEGACY_BUTTON':
      return (
        <LegacyButton key={node._id} node={node} />
      );
    default:
      return null;
  }
};

export default renderNode;

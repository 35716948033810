import React, { useCallback } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import { globalCss } from './stitches.config';
import resetCss from './helpers/resetCss';

import RedirectToHome from './helpers/RedirectToHome';
import {
  Provider as LocationProvider,
  useLocation,
} from './context/Location';
import { Provider as ApiProvider } from './context/Api';
import { Provider as ViewerProvider } from './context/Viewer';

import Store from './templates/Store/index';
import Order from './templates/Order';

import Types from './modules/types';

const resetSyles = globalCss(resetCss);

const globalStyles = globalCss({
  html: {
    width: '100%',
    overflowX: 'hidden',
  },

  'html, body': {
    position: 'relative',
    userSelect: 'none',
  },

  body: {
    '@desktop-': {
      fontSize: 14,
    },

    '@mobile': {
      fontSize: 12,
    },
  },

  img: {
    pointerEvents: 'none',
  },

  '*, *:before, *:after': {
    userSelect: 'none',
  },

  input: {
    userSelect: 'initial',
  },

  '*': {
    WebkitTapHighlightColor: 'transparent',
  },

  '::selection': {
    background: 'hsla(0, 0%, 60%, 0.2)',
  },
});

function renderRouteCheckout({ match, isCustomDomain }) {
  const props = (
    match && match.params
    ? {
        orderId: match.params.orderId,
        language: match.params.language,
        isCustomDomain,
      }
    : {}
  );
  return <Order {...props} checkout />;
}

function renderRouteOrder({ match, isCustomDomain }) {
  const props = (
    match && match.params
    ? {
        orderId: match.params.orderId,
        language: match.params.language,
        isCustomDomain,
      }
    : {}
  );
  return <Order {...props} />;
}

function renderRouteStore({
  children,
  ...props
}) {
  return <RenderRouteStoreComponent {...props} />;
}

function RenderRouteStoreComponent({
  match,
  isStoreShopPage,
  isCustomDomain,
}) {
  const { searchParams: search } = useLocation();
  const posOrderId = `${search.pos}`;
  const props = (
      !match || !match.params
    ? {}
    : {
        slug: match.params.storeSlug || window.location.hostname,
        language: match.params.language,
        productOrPageSlug: match.params.productOrPageSlug,
        variationSlug: match.params.variationSlug,
        referral: search.referral,
        posOrderId: (
            posOrderId === 'true'
          ? true
          : posOrderId && posOrderId.length === 8
          ? posOrderId
          : null
        ),
        isCustomDomain,
      }
  );
  return (
    <Store
      isStoreShopPage={isStoreShopPage}
      {...props}
    />
  );
}

// function renderRouteResellerSignup() {
//   return <ResellerSignup />;
// }

function GlobalErrorMessage() {
  const { searchParams } = useLocation();
  const { error: errorMessage } = searchParams;
  // eslint-disable-next-line no-console
  if (errorMessage) {
    console.log(`error message: ${errorMessage}`);
  }
  return null;
}

export default function App({
  apiUrl,
  isCustomDomain,
  iframe,
  token,
}) {
  const renderOrder = useCallback(
    props => renderRouteOrder({ ...props, isCustomDomain }),
    [isCustomDomain],
  );
  const renderStoreShopPage = useCallback(
    props => renderRouteStore({
      ...props,
      isCustomDomain,
      isStoreShopPage: true,
    }),
    [isCustomDomain],
  );
  const renderStore = useCallback(
    props => renderRouteStore({
      ...props,
      isCustomDomain,
    }),
    [isCustomDomain],
  );
  // const renderResellerSignup = useCallback(
  //   props => renderRouteResellerSignup(props),
  //   [],
  // );
  const checkoutRoute = (
    <Route
      path="/checkout/:orderId"
      render={renderRouteCheckout}
    />
  );
  // const resellerSignupRoute = (
  //   <Route
  //     path="/reseller"
  //     render={renderResellerSignup}
  //   />
  // );
  const orderRoute = (
    <Route
      path="/order/:orderId"
      render={renderOrder}
    />
  );
  const prefix = isCustomDomain ? '' : '/:storeSlug';
  const languagePrefix = '/:language([a-z0-9]{2})';
  const shopPrefix = `/${Types.SHOP_PRODUCTS_ARCHIVE_PATH}`;

  const getStoreRoutePath = (
    localPrefix = '',
    localLanguagePrefix = '',
    localShopPreffix = '',
  ) => (
    `${
      localPrefix
    }${
      localLanguagePrefix
    }${
      localShopPreffix
    }/:productOrPageSlug?/:variationSlug?`
  );

  resetSyles();
  globalStyles();

  return (
    <Router>
      <LocationProvider>
        <ApiProvider
          baseUrl={apiUrl}
          token={token}
          iframe={iframe}
        >
          <ViewerProvider>
            <Switch>
              {checkoutRoute}
              {orderRoute}
              <Route
                path={getStoreRoutePath(prefix, languagePrefix, shopPrefix)}
                render={renderStoreShopPage}
              />
              <Route
                path={getStoreRoutePath(prefix, languagePrefix)}
                render={renderStore}
              />
              <Route
                path={getStoreRoutePath(prefix, '', shopPrefix)}
                render={renderStoreShopPage}
              />
              <Route
                path={getStoreRoutePath(prefix)}
                render={renderStore}
              />
              <Route>
                <RedirectToHome isCustomDomain={isCustomDomain} />
              </Route>
            </Switch>
            <GlobalErrorMessage />
          </ViewerProvider>
        </ApiProvider>
      </LocationProvider>
    </Router>
  );
}

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import {
  Redirect,
  // useHistory,
} from 'react-router-dom';

import {
  stringify as stringifyQuery,
} from 'querystring';

import { useLocation } from './Location';
import ContextProviderComponent from './ContextProviderComponent';
import { session as sessionStorage } from '../storage';

const Context = createContext();

export default Context;

export function Provider(props) {
  const { searchParams, pathname } = useLocation();
  // const history = useHistory();
  let tokenFromSearchParams = null;
  if (searchParams.token?.length) {
    tokenFromSearchParams = searchParams.token;
    sessionStorage.setItem(
      'SHOP_ACCESS_TOKEN',
      JSON.stringify(searchParams.token),
    );
  }
  const tokenFromStorage = sessionStorage.getItem('SHOP_ACCESS_TOKEN');
  let token = useMemo(
    () => {
      try {
        return JSON.parse(tokenFromStorage);
      } catch (error) {
        return tokenFromSearchParams || null;
      }
    },
    [tokenFromStorage, tokenFromSearchParams],
  );

  if (!props.iframe) {
    token = null;
  }

  if (searchParams.token) {
    return <Redirect to={pathname} />;
  }

  return (
    <ApiProvider
      token={token}
      {...props}
    />
  );
}

export function StoreApiProvider(props) {
  return (
    <ApiProvider
      {...props}
    />
  );
}

export const { Consumer } = Context;

export class ApiProvider extends ContextProviderComponent {
  static NAME = 'API'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  static async request({
    url,
    token,
    body,
    method = 'GET',
    headers = {},
    abort,
    aborter,
    ...config
  } = {}) {
    // eslint-disable-next-line no-useless-catch
    try {
      abort = abort || null;
      aborter = aborter || new AbortController();
      try { abort.abort(); } catch (error) { /* */ }
      const request = fetch(`${url}`, {
        method,
        headers: Object.assign(
          {
            authorization: token,
            accept: 'application/json',
            'content-type': 'application/json',
          },
          body instanceof FormData ? body.getHeaders() : {},
          headers,
        ),
        body: (
          body instanceof FormData
          ? body
          : body
          ? JSON.stringify(body)
          : undefined
        ),
        signal: aborter.signal,
        ...config,
      });

      request.aborter = aborter;

      return (
        request
        .then(res => res.json())
        .then(res => (res && res.data ? res.data : res))
        .then(async (res) => {
          if (res.status >= 400) {
            throw Object.assign(new Error(res.message), {
              response: res,
            });
          }
          return res;
        })
      );
    } catch (error) {
      throw error;
    }
  }

  constructor(props) {
    super(props);
    this.state.token = props.token;
    this.state.baseUrl = props.baseUrl;
    this.state.iframe = props.iframe;
    this.exposeMethods(
      'debug',
      'request',
      'get',
      'getCached',
      'post',
      'delete',
      'report',
      'reportSafe',
    );
  }

  componentDidMount() {
    if (
         this.state.token !== this.props.token
      // || this.state.baseUrl !== this.props.baseUrl
      // || this.state.iframe !== this.props.iframe
    ) {
      this.setState({
        token: this.props.token,
        // baseUrl: this.props.apiUrl,
        // iframe: this.props.iframe,
      });
    }
  }

  componentDidUpdate() {
    if (
         this.state.token !== this.props.token
      || this.state.baseUrl !== this.props.baseUrl
      || this.state.iframe !== this.props.iframe
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        token: this.props.token,
        baseUrl: this.props.apiUrl,
        iframe: this.props.iframe,
      });
    }
  }

  getRequestUrl = (url) => {
    if (this.props.getRequestUrl) {
      return this.props.getRequestUrl(url, this.props, this);
    }
    return url;
  }

  request = (method, url, body, abort, aborter) => (
    this.constructor.request({
      url: (
        /^https?:\/\//.test(url || '')
        ? url
        : this.getRequestUrl(`${this.props.baseUrl}/${url}`)
      ),
      token: this.state.token,
      method,
      body,
      abort,
      aborter,
      headers: this.props.defaultHeaders || {},
    })
  )

  get = async (url, query = {}, abort, aborter) => this.request(
    'GET',
    `${url}?${stringifyQuery({ query: JSON.stringify(query) })}`,
    undefined,
    abort,
    aborter,
  )

  getCached = async (key, url, query = {}, abort, aborter) => {
    if (!this.cache[key]) {
      this.cache[key] = await this.get(url, query, abort, aborter);
    }
    return this.cache[key];
  }

  post = async (url, body, query = {}, abort, aborter) => this.request(
    'POST',
    `${url}?${stringifyQuery({ query: JSON.stringify(query) })}`,
    body,
    abort,
    aborter,
  )

  delete = async (url, query, abort, aborter) => this.request(
    'DELETE',
    `${url}?${stringifyQuery({ query: JSON.stringify(query) })}`,
    undefined,
    abort,
    aborter,
  )

  report = async (...data) => {
    this.debug('reporting:', data);
    await this.post('debug/shop', data);
  }

  reportSafe = async (...data) => {
    try {
      await this.report(...data);
    } catch (error) {
      this.debug(`could not report: ${JSON.stringify(data)}`, error);
    }
  }
}

export function useApi() {
  const api = useContext(Context);
  return api;
}

function defaultExtractData(data) {
  return data ? data : null;
}

function defaultExtractError(error) {
  return error ? error : null;
}

export function useApiRequest({
  url,
  body,
  skip = false,
  method = 'GET',
  initialData = null,
  extractData = defaultExtractData,
  extractError = defaultExtractError,
}) {
  const api = useApi();
  const cache = useRef({
    request: null,
    aborter: new AbortController(),
    abort: null,
  });
  const [refreshCount, setRefreshCount] = useState(0);
  const updateRefreshCount = useCallback(
    () => setRefreshCount(refreshCount + 1),
    [refreshCount],
  );
  const skipCache = useRef(skip);
  const urlCache = useRef(url);
  const justMounted = useRef(!!initialData);
  const [
    state,
    setState,
  ] = useState({
    loading: (skip || initialData) ? false : true,
    data: initialData,
    error: extractError(null),
    initialData,
  });
  useEffect(
    () => {
      if (cache.current.request) {
        cache.current.request = null;
        cache.current.abort = cache.current.aborter;
        cache.current.aborter = new AbortController();
        if (cache.current.abort) {
          try { cache.current.abort.abort(); } catch (error) { /* noop */ }
        }
      }
      urlCache.current = url;
      skipCache.current = skip;
      if (!justMounted.current) {
        if (!skip) {
          try {
            cache.current.request = api.request(
              method,
              url,
              body,
              cache.current.abort,
              cache.current.aborter,
            )
            .then((response) => {
              setState({
                ...state,
                loading: false,
                error: null,
                data: extractData(response),
              });
            })
            .catch((error) => {
              setState({
                ...state,
                loading: false,
                data: null,
                error: extractError(error),
              });
            });
          } catch (error) {
            setState({
              ...state,
              loading: false,
              data: null,
              error: extractError(error),
            });
          }
        } else {
          setState({
            ...state,
            data: extractData(null),
            error: null,
            loading: false,
          });
        }
      } else {
        justMounted.current = false;
        setState({
          ...state,
          loading: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      refreshCount,
      url,
      method,
      body,
      skip,
      api.token,
      // initialData,
    ],
  );
  const willBeLoading = (
    (
      !skip
    ) && (
      urlCache.current !== url
    )
  );
  return [
    { ...state, loading: state.loading || willBeLoading },
    updateRefreshCount,
  ];
}

import React, { useMemo, useState, useRef, useEffect } from 'react';
import { VolumeX, Volume2 } from 'react-feather';

import { createNamedStyled } from '../../stitches.config';

import Base from './Base';

const styled = createNamedStyled('Media');

const Node = styled(Base, {
  width: '100%',
});

const IconWrapper = styled.named('IconWrapper')('div', {
  position: 'absolute',
  bottom: 10,
  right: 10,
  padding: '12px 10px 9px 11px',
  borderRadius: '$xs',
  cursor: 'pointer',
  zIndex: 9,
  opacity: 0.75,
  transition: 'opacity 0.2s ease-in-out, background 0.2s ease-in-out',
  background: 'hsla(0, 0%, 0%, 0.25)',
  '&:hover': {
    opacity: 1,
    background: 'hsla(0, 0%, 0%, 0.5)',
  },
});

const Media = ({ node, css, ...props }) => {
  const [isMuted, setIsMuted] = useState(true);
  const [hasAudio, setHasAudio] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const checkAudio = () => {
      if (videoRef.current.mozHasAudio
          || Boolean(videoRef.current.webkitAudioDecodedByteCount)
          || Boolean(
            videoRef.current.audioTracks
            && videoRef.current.audioTracks.length)) {
        setHasAudio(true);
      }
    };

    let timeout = null;

    const videoElem = videoRef.current;
    if (videoElem) {
      videoElem.addEventListener('loadedmetadata', () => {
        timeout = setTimeout(checkAudio, 500);
      });
    }

    return () => {
      if (videoElem) {
        videoElem.removeEventListener('loadedmetadata', checkAudio);
        clearTimeout(timeout);
      }
    };
  }, [node?.media_video]);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  const finalCss = useMemo(
    () => ({
      ...node?.media_objectFit !== 'NORMAL' ? {
        objectFit: node?.media_objectFit.toLowerCase(),
      } : {},
      aspectRatio:
        node?.media_image?.meta?.width && node?.media_image?.meta?.height
        ? `${node?.media_image?.meta.width} / ${node?.media_image?.meta.height}`
        : node?.media_video?.meta?.width && node?.media_video?.meta?.height
        ? `${node?.media_video?.meta.width} / ${node?.media_video?.meta.height}`
        : 'auto',
      ...css,
    }),
    [
      css,
      node?.media_objectFit,
      node?.media_image?.meta,
      node?.media_video?.meta,
    ],
  );

  return (
    !!(node?.media_video?.src || node?.media_image?.src) && (
      <div style={{
        position: 'relative', width: '100%', display: 'flex', flex: '1 1 auto',
      }}
      >
        <Node
          ref={node?.media_video ? videoRef : null}
          {...props}
          node={node}
          tag={node?.media_video ? 'video' : 'img'}
          src={node?.media_video?.src || node?.media_image?.src}
          alt={
            node?.name
            || node?.media_image?.meta?.filename
            || node?.media_video?.meta?.filename
            || 'Media'
          }
          {...node?.media_video ? {
            poster: node?.media_image?.src,
            autoPlay: true,
            loop: true,
            muted: true,
            playsInline: true,
            } : {}
          }
          css={finalCss}
        />
        {hasAudio && (
          <IconWrapper onClick={toggleMute}>
            {isMuted
              ? <VolumeX color="white" opacity={0.5} />
              : <Volume2 color="white" opacity={1} />}
          </IconWrapper>
        )}
      </div>
    )
  );
};

export default Media;

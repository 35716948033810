import React from 'react';
import { styled } from '../../stitches.config';

import ContentComponent from '../../components/Legacy/Content';

import Base from '../components/Base';

const Node = styled(Base, {});

const Content = ({ node, css, renderNode, ...props }) => (
  <Node
    {...props}
    node={node}
    css={{
      cursor: 'pointer',
      ...css,
    }}
    id={node._id}
    className={node.className}
  >
    <ContentComponent
      title={node.content_title}
      subtitle={node.content_subtitle}
      content={node.content_content}
      image={node.content_image}
      video={node.content_video}
      contentAlignment={node.content_alignContent}
      imageAlignment={node.content_alignImage}
      buttonLabel={node.content_buttonLabel}
      buttonLink={node.content_buttonLink}
      imageWidth={node.content_imageWidth}
    />
  </Node>
);

export default Content;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animate } from 'framer-motion';
import Helmet from 'react-helmet';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import Sections from '../../../components/Legacy/Sections';
import renderNode from '../../../builder/functions/renderNode';

import { useEditMode, EditModeUI } from '../../../tools/EditMode';

const styled = createNamedStyled('Page');

const Loader = styled.named('Loader')('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '2px',
  background: 'linear-gradient(to right, $loaderStart, $loaderEnd)',
  zIndex: 1000,
  transformOrigin: 'left',
  willChange: 'transform',

  variants: {
    state: {
      idle: {
        transform: 'scaleX(0)',
      },
      loading: {
        transform: 'scaleX(0.9)',
        transition: 'transform 4000ms $easeOutExpo',
      },
      loaded: {
        opacity: 0,
        transform: 'scaleX(1)',
        transition: 'opacity 300ms, transform 300ms $easeOutCubic',
      },
    },
  },
});

export default function Page({ page, store }) {
  const { sections = [], content, name, _id: pageId } = page || {};

  const {
    isActive,
    setIsActive,
    isEditMode,
    toggleIsEditMode,

    user,
    pageContent,
  } = useEditMode(pageId, content);

  const { navigation } = useTheme();
  const { hash, pathname } = useLocation();

  const [savedHash, setSavedHash] = useState(hash);
  const [loadingState, setLoadingState] = useState('idle');

  useEffect(() => {
    if (loadingState === 'loading') return;
    setLoadingState('loading');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    try {
      document.documentElement.scrollTo({
        top: hash ? window.scrollY
          + document.querySelector(hash).getBoundingClientRect().top
          * getComputedStyle(document.querySelector(hash)).zoom
          - (navigation.position === 'fixed' ? 145 : 0) : 0,
        left: 0,
        behavior: 'instant',
      });
    } catch (error) { /* noop */ }

    setLoadingState('loaded');
    setTimeout(() => setLoadingState('idle'), 300);

    setSavedHash(hash);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (savedHash === hash) return;

    try {
      animate(
        window.scrollY,
        window.scrollY
          + document.querySelector(hash).getBoundingClientRect().top
          * getComputedStyle(document.querySelector(hash)).zoom
          - (navigation.position === 'fixed' ? 145 : 0),
      {
        // TODO: update with theme values
        type: 'spring',
        stiffness: 100,
        damping: 15,
        mass: 0.5,
        onUpdate: v => { window.scroll({ top: v }); },
      });
    } catch (error) { /* noop */ }

    setSavedHash(hash);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  // NOTE: this is a hack to zoom out the
  // iframe on Onboarding / Homepage Picker
  useEffect(() => {
    if (window.self !== window.top) {
      if (window.innerWidth > 768) {
        // TODO: Revisit this nonsense
        document.body.style.zoom = 0.7;
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {`${store.name} | ${name}`}
        </title>
        <meta name="description" content={`${store.name} | ${name}`} />
      </Helmet>

      <EditModeUI
        pageId={pageId}
        user={user}
        isActive={isActive}
        setIsActive={setIsActive}
        isEditMode={isEditMode}
        toggleIsEditMode={toggleIsEditMode}
      />
      <Loader state={loadingState} />
      <Sections sections={sections} />
      <div id="builder-content">
        {pageContent.map(item => renderNode(item))}
      </div>
    </>
  );
}

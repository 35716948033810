function replaceStitchesCustomProps(customProps, stitchesCSS) {
  if (!customProps) return stitchesCSS;

  return Object.entries(stitchesCSS).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      acc[key] = replaceStitchesCustomProps(customProps, value);
    } else if (typeof value === 'string') {
      let hasMatch = true;
      while (hasMatch) {
        const matches = value.match(/\[\[([a-zA-Z0-9_]+)\]\]/);
        if (matches) {
          const propKey = matches[1];
          if (Object.prototype.hasOwnProperty.call(customProps, propKey)) {
            value = value.replace(matches[0], customProps[propKey]);
          } else {
            hasMatch = false;
          }
        } else {
          hasMatch = false;
        }
      }
      acc[key] = value;
    }
    return acc;
  }, {});
}

export default replaceStitchesCustomProps;

import React, { useState } from 'react';
import useMedia from 'use-media';

import { createNamedStyled } from '../../../stitches.config';
// import { useTheme } from '../../../theme';

import { useDictionary } from '../../../context/Language';

import { Title, Paragraph } from '../../../components/Elements/Text';
import Collapse from '../../../components/Patterns/Collapse';

import DropSelect from '../../../components/Patterns/DropSelect';
import Input from '../../../components/Elements/Input';

const styled = createNamedStyled('ProductsSidebar');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 auto',
  width: '100%',
  margin: '0 auto $m',

  '@desktop+': {
    flex: '0 0 250px',
    position: 'sticky',
    marginTop: 0,
  },
});

// Required for the framer-motion to work
const Space = styled.named('Space')('div', {
  height: '$space$m',
});

const CategoriesWrapper = styled.named('CategoriesWrapper')('div', {
  marginTop: '$m',
});

const Categories = styled.named('Categories')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',

  variants: {
    sub: {
      true: {
        paddingLeft: 'calc($xs / 1.5)',
        gap: 'calc($xs / 2)',
        margin: 'calc($xs / 2) 0 calc($xs / 6)',
      },
    },
    active: {
      false: {
        display: 'none',
      },
    },
  },
});

const Category = styled.named('Category')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  cursor: 'pointer',
});

const CategoryItemWrapper = styled.named('CategoryItemWrapper')(Paragraph, {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  cursor: 'pointer',

  transition: 'color $s',
  '&:hover': { color: '$accent' },

  fontSize: '90%',

  variants: {
    active: {
      true: {
        color: '$accent',
      },
    },
  },
});

const Number = styled.named('Number')('span', {
  fontSize: '115%',
});

const Sidebar = ({
  category,
  subcategory,
  categoryOptions,
  onCategoryChange,
  collection,
  collectionOptions,
  onCollectionChange,
  sort,
  sortOptions,
  onSortChange,
  search,
  onSearchChange,
}) => {
  const {
    filtersSearch,
    filtersSortBy,
    filtersTitle,
    filtersShow,
    filtersHide,
  } = useDictionary();

  const [collapsed, setCollapsed] = useState(false);
  const isDesktop = useMedia({ minWidth: 1024 });

  let Component = Collapse;
  let title = filtersShow;
  if (collapsed) {
    title = filtersHide;
  }

  if (isDesktop) {
    Component = 'div';
    title = filtersTitle;
  }

  return (
    <Wrapper>
      <Title
        onClick={() => setCollapsed(!collapsed)}
        as="div"
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: isDesktop ? 'initial' : 'pointer',
        }}
      >
        {title}
      </Title>
      <Space />
      <Input
        label={filtersSearch}
        value={search}
        onChange={value => onSearchChange(value)}
      />
      <Component isopen={collapsed ? 1 : 0}>
        <Space css={{ marginTop: '$s' }} />
        <DropSelect
          fullWidth
          valueStyle="label"
          options={sortOptions}
          value={sortOptions.find(option => option.key === sort).label}
          label={filtersSortBy}
          onChange={(option) => {
            onSortChange(option.value);
            setCollapsed(false);
          }}
        />
        {
          [
            ['category', category, categoryOptions, onCategoryChange],
            ['collection', collection, collectionOptions, onCollectionChange],
          ].map(
            ([key, optionValue, options, onOptionChange]) => (
              <CategoriesWrapper key={key}>
                <Categories>
                  {options.map((option) => (
                    <Category
                      key={`${option.value}`}
                      onClick={() => {
                        onOptionChange(option.value);
                        setCollapsed(false);
                      }}
                    >
                      <CategoryItemWrapper
                        active={optionValue === option.value}
                      >
                        {option.label}
                        <Number>{option.products}</Number>
                      </CategoryItemWrapper>
                      {
                        option.subcategories?.length
                        ? (
                            <Categories
                              sub
                              active={category === option.value}
                            >
                              {option.subcategories.map((suboption) => (
                                <Category
                                  key={`${option.value}___${suboption.value}`}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onOptionChange(
                                      option.value,
                                      suboption.value,
                                    );
                                    setCollapsed(false);
                                  }}
                                >
                                  <CategoryItemWrapper
                                    active={
                                      optionValue === option.value
                                      && subcategory === suboption.value
                                    }
                                  >
                                    {suboption.label}
                                    <Number>{suboption.products}</Number>
                                  </CategoryItemWrapper>
                                </Category>
                              ))}
                            </Categories>
                          )
                        : null
                      }
                    </Category>
                  ))}
                </Categories>
              </CategoriesWrapper>
            ),
          )
        }
      </Component>
    </Wrapper>
  );
};

export default Sidebar;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Redirect } from 'react-router-dom';
import {
  stringify as stringifyQuery,
  parse as parseQuery,
} from 'querystring';

import storage from '../storage';

import ContextProviderComponent from './ContextProviderComponent';
import { useLocation } from './Location';
import { StoreApiProvider, useApi, useApiRequest } from './Api';
import { useViewer } from './Viewer';

// import NotFound from '../components/Sections/NotFound';
import RedirectToHome from '../helpers/RedirectToHome';

import Types from '../modules/types';

const {
  STORE_OID_TOKEN_KEY: OID_TOKEN_KEY,
  STORE_OID_TOKEN_SEARCH_PARAM: OID_TOKEN_SEARCH_PARAM,
} = Types;

const Context = createContext();

export default Context;

export function Provider(props) {
  const api = useApi();
  const viewer = useViewer();
  const location = useLocation();
  return (
    <StoreProvider
      api={api}
      location={location}
      viewer={viewer}
      {...props}
    />
  );
}

export const { Consumer } = Context;

export class StoreProvider extends ContextProviderComponent {
  static NAME = 'STORE'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  // static setData(key, data) {
  //   try {
  //     return storage.setItem(key, JSON.stringify(data));
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log(error.message);
  //     return null;
  //   }
  // }

  // static getData(key) {
  //   try {
  //     return JSON.parse(storage.getItem(key));
  //   } catch (error) {
  //     return null;
  //   }
  // }

  constructor(props) {
    super(props);
    global.STORE = this;
    this.state.loading = true;
    this.state.data = null;
    this.exposeMethods(
      'setData',
      'getData',
      'setLanguage',
      'setCurrency',
      'getCurrencyConverted',
      'removeData',
      'getUrl',
      'getProductsUrl',
    );
    Object.assign(this.state, {
      mode: this.props.mode,
      isCustomDomain: this.props.isCustomDomain,
    });
  }

  componentDidMount() {
    this.getStore(this.props);
  }

  componentDidUpdate(props) {
    const refresh = (
         this.props.id !== props.id
      || this.props.slug !== props.slug
      || this.props.language !== props.language
      || this.props.isCustomDomain !== props.isCustomDomain
    );
    if (
         refresh
      || this.props.referral !== props.referral
      || this.props.viewer !== props.viewer
      || this.props.mode !== props.mode
    ) {
      this.getStore(this.props, refresh);
    }
  }

  async getStore(
    {
      id,
      slug,
      language,
      mode,
      referral: referralStoreSlug,
      isCustomDomain,
      isStoreShopPage,
      productOrPageSlug,
      location,
      useCustomDomain = true,
      viewer,
    },
    refresh = false
  ) {
    if (refresh) {
      window.location.reload();
      return;
    }
    this.setState({ loading: true, redirect: null });
    let redirect = null;
    let referral = null;
    let aborter = new AbortController();
    try {
      const data = await this.props.api.get(
        `stores/${id || slug}`,
        {
          // TODO Revise these params so they make more sense
          setupShopData: (language || mode === 'order') ? 'true' : 'language',
          setupShopPages: (
            (isStoreShopPage || mode === 'order' || !language)
            ? undefined
            : productOrPageSlug || ''
          ),
          // includeCurrencies: 'true',
          i18nTranslate: (
            language
            ? language
            : (mode === 'order' || isStoreShopPage)
            ? 'true'
            : undefined
          ),
        },
        this.aborter,
        this.aborter = aborter,
      );
      if (window.location.hostname === 'localhost') {
        if (data) {
          data.domains = [];
        }
      }
      if (
        data
        && (
          mode === 'order'
          || (viewer?.data || data.internal !== true)
        )
      ) {
        const savedLanguage = viewer.getData(`${data._id}.LANGUAGE`);
        let validLanguage = (
            !language
          ? data.language
          : !data.languages.includes(language)
          ? data.language
          : language
        );
        if (
          !language
          && savedLanguage
          && data.languages.includes(savedLanguage)
        ) {
          validLanguage = savedLanguage;
        }
        if (language === validLanguage) {
          viewer.setData(`${data._id}.LANGUAGE`, language);
        }
        if (!useCustomDomain && isCustomDomain) {
          window.location.replace(`${
            process.env.REACT_APP_PACKMAN_SHOP_URL
          }${
            mode === 'store'
            ? `/${slug}${location.pathname}`
            : location.pathname
          }${
            location.search
          }`);
          return;
        }
        if (
          useCustomDomain
          && !isCustomDomain
          && data.domains
          && data.domains[0]
        ) {
          let path = (
            mode === 'store'
            ? `/${location.pathname.split('/').slice(
                language ? 3 : 2
              ).join('/')}`
            : location.pathname
          );
          if (mode === 'store' && validLanguage !== language) {
            path = `/${validLanguage}${path}`;
          }
          window.location.replace(`https://${data.domains[0]}${
            path
          }${
            location.search
          }`);
          return;
        }
        if (mode === 'store' && validLanguage !== language) {
          if (isCustomDomain) {
            const newLocation = `/${
              validLanguage
            }${
              language
              ? location.pathname.split('/').slice(2).join('/')
              : location.pathname
            }${
              location.search
            }`;
            window.location.replace(newLocation);
          } else {
            const newLocation = `/${
              slug
            }/${
              validLanguage
            }/${
              location.pathname.split('/').slice(language ? 3 : 2).join('/')
            }${
              location.search
            }`;
            window.location.replace(newLocation);
          }
          return;
        }
        // OPENID CONNECT START
        if (
          typeof location.searchParams[OID_TOKEN_SEARCH_PARAM] !== 'undefined'
        ) {
          this.props.viewer.setData(
            OID_TOKEN_KEY,
            location.searchParams[OID_TOKEN_SEARCH_PARAM],
          );
          const newSearchParams = { ...location.searchParams };
          delete newSearchParams[OID_TOKEN_SEARCH_PARAM];
          const newQueryString = stringifyQuery(newSearchParams);
          window.location.href = `${
            window.location.origin
          }${location.pathname}${
              newQueryString.length
            ? `?${newQueryString}`
            : ''
          }`;
          return;
        }
        let customer = null;
        if (
          mode === 'store'
          && data?.integrations?.openId?.supported
          && data?.integrations?.openId?.backend
        ) {
          aborter = new AbortController();
          const response = await this.props.api.get(
            'openid/store/authenticate',
            {
              url: window.location.href,
              store: data._id,
              token: this.props.viewer.getData(OID_TOKEN_KEY),
            },
            this.aborter,
            this.aborder = aborter,
          );
          if (response.redirect) {
            window.location = response.redirect;
            return;
          }
          if (response.customer) {
            customer = response.customer;
          }
        }
        // OPENID CONNECT END
        // aborter = new AbortController();
        const languageData = data.dataLanguages?.[
          language || data.language
        ] || {};
        // const languageData = await this.props.api.get(
        //   `languages/${language || data.language}`,
        //   {},
        //   this.aborter,
        //   this.aborter = aborter,
        // );
        // aborter = new AbortController();
        const productsCount = data.dataProductsCount || 0;
        // const productsCount = await this.props.api.get(
        //   'products',
        //   {
        //     where: { store: data._id },
        //     limit: 0,
        //   },
        // );
        const categories = data.dataCategories || [];
        // const categories = await this.props.api.get(
        //   'categories',
        //   {
        //     where: { store: data._id, products: { GT: 0 } },
        //     sort: { sortWeight: -1, createdAt: 1 },
        //     limit: 1000,
        //     i18nTranslate: language || 'true',
        //   },
        //   this.aborter,
        //   this.aborter = aborter,
        // );
        const collections = data.dataCollections || [];
        // const collections = await this.props.api.get(
        //   'collections',
        //   {
        //     where: { store: data._id, products: { GT: 0 } },
        //     sort: { sortWeight: -1, createdAt: 1 },
        //     limit: 1000,
        //     i18nTranslate: language || 'true',
        //   },
        // )
        // const productsCount = 0;
        // const categories = [];
        // const collections = [];
        if (referralStoreSlug) {
          aborter = new AbortController();
          if (referralStoreSlug) {
            try {
              aborter = new AbortController();
              referral = await this.props.api.get(
                `stores/${id || slug}/referral/${referralStoreSlug}`,
                {},
                this.aborter,
                this.aborter = aborter,
              );
              if (!referral) {
                throw new Error(`Referral "${referralStoreSlug}" not found`);
              }
              // this.props.viewer.setData(
              //   `${data._id}.referral`,
              //   { data: referral },
              // );
            } catch (error) {
              referral = null;
              // this.props.viewer.setData(`${data._id}.referral`, null);
              this.debug(`error getting referral ${referralStoreSlug}`, error);
              redirect = `${location.pathname}`;
            }
          } else {
            // const referralSaved = this.props.viewer.getData(
            //   `${data._id}.referral`,
            // );
            const referralSaved = null;
            if (referralSaved) { // TODO And not expired
              try {
                aborter = new AbortController();
                const referralStore = await this.props.api.get(
                  `stores/${referralSaved.data.referralStore}`,
                  {},
                  this.aborter,
                  this.aborter = aborter,
                );
                if (!referralStore) {
                  throw new Error('Referral store not found');
                }
                redirect = `${location.pathname}?referral=${
                  referralStore.slug
                }`;
              } catch (error) {
                this.debug('error getting saved referral store:', error);
                this.props.viewer.setData(`${data._id}.referral`, null);
                referral = null;
              }
            } else {
              referral = null;
              this.props.viewer.setData(`${data._id}.referral`, null);
            }
          }
        }
        const storeCurrenciesMap = (data.storeCurrencies || []).reduce(
          (agr, currency) => {
            agr[currency.code] = currency;
            return agr;
          },
          {},
        );
        let storeCurrency = this.props.viewer.getData(`${
          data._id
        }.storeCurrency`);
        if (!storeCurrency || !storeCurrenciesMap[storeCurrency]) {
          storeCurrency = data.storeCurrencies[0].code;
        }
        this.setState({
          loading: false,
          mode,
          currency: storeCurrency,
          language: languageData,
          data: {
            ...data,
            storeCurrenciesMap,
            productsCount: productsCount?.count || 0,
            categories,
            collections,
            customer,
            referral: (
              referral
              ? {
                  ...referral,
                  referralStoreSlug,
                }
              : null
            ),
          },
          isCustomDomain,
          redirect,
        });
      } else if (data && data.internal) {
        this.setState({
          loading: false,
          mode,
          redirect: `${
            process.env.REACT_APP_PACKMAN_SHOP_ADMIN_URL
          }/admin/store/orders?packman-marketplace=${
            data._id
          }`,
        });
      } else {
        throw new Error(`Store "${id || slug}" not found`);
      }
    } catch (error) {
      this.debug('error fetching store:', error);
      this.setState({
        loading: false,
        data: null,
        redirect,
        mode,
      });
    }
  }

  setData = (key, data) => {
    try {
      return this.props.viewer.setData(
        `${this.state.data._id}.${key}`,
        data,
      );
    } catch (error) {
      return null;
    }
  }

  getData = (key) => {
    try {
      return this.props.viewer.getData(
        `${this.state.data._id}.${key}`,
      );
    } catch (error) {
      return null;
    }
  }

  removeData = (key) => {
    try {
      return storage.removeItem(
        `${this.state.data._id}.${key}`,
      );
    } catch (error) {
      return null;
    }
  }

  getUrl = (to, mode = 'store') => {
    const languagePath = (
      this.props.language
      ? `/${this.props.language}`
      : ''
    );
    return (
        this.props.isCustomDomain
      ? `${languagePath}${to || '/'}`
      : `${
          mode === 'order'
          ? `${to || '/'}`
          : `/${this.state.data.slug}${languagePath}${to || ''}`
        }`
    );
  }

  getProductsUrl = (productSlug, variationSlug, search) => this.getUrl(`/${
    Types.SHOP_PRODUCTS_ARCHIVE_PATH
  }${
    productSlug ? `/${productSlug}` : ''
  }${
    variationSlug ? `/${variationSlug}` : ''
  }${
    search || ''
  }`)

  setCurrency = (currency) => {
    if (
      this.state.data.storeCurrenciesMap
      && this.state.data.storeCurrenciesMap[currency]
    ) {
      this.setState({ currency });
      this.setData('storeCurrency', currency);
    }
  }

  setLanguage = (language) => {
    const {
      language: { _id: currentLanguage },
      data: { slug },
    } = this.state;
    const { isCustomDomain } = this.props;
    if (currentLanguage !== language) {
      if (isCustomDomain) {
        window.location.replace(`/${
          language
        }/${
          window.location.pathname.split('/').slice(2).join('/')
        }${
          window.location.search
        }`);
      } else {
        window.location.replace(`/${
          slug
        }/${
          language
        }/${
          window.location.pathname.split('/').slice(3).join('/')
        }${
          window.location.search
        }`);
      }
    }
  }

  getCurrencyConverted = (value = 1) => (
    this.state.data.storeCurrenciesMap[this.storeCurrency] * value
  )

  getStoreRequestUrl = (initialUrl) => {
    if (this.props.language) {
      const url = new URL(initialUrl, window.location.origin);
      const searchParams = parseQuery(url.search.slice(1));
      searchParams.i18nTranslate = this.props.language;
      const finalUrl = `${url.origin}${url.pathname}?${stringifyQuery(
        searchParams
      )}`;
      return finalUrl;
    }
    return initialUrl;
  }

  render() {
    const { loading, data, redirect } = this.state;
    if (!loading && redirect) {
      if (redirect.indexOf('http') === 0) {
        window.location.href = redirect;
        return null;
      }
      return <Redirect to={redirect} />;
    }
    if (!loading && !data) {
      // return <NotFound message="Store not found" />;
      return <RedirectToHome isCustomDomain={this.props.isCustomDomain} />;
    }
    if (!loading) {
      return (
        <StoreApiProvider
          baseUrl={this.props.api.baseUrl}
          iframe={this.props.api.iframe}
          getRequestUrl={this.getStoreRequestUrl}
          // defaultHeaders={{
          //   'x-packman-i18n-translate': this.props.language,
          // }}
        >
          {super.render()}
        </StoreApiProvider>
      );
    }
    return null;
  }
}

export function useStore() {
  return useContext(Context);
}

export function useIsStoreInternal() {
  const store = useStore();
  return store.data?.internal === true;
}

export function useStoreCurrency() {
  const { data: store, currency, setCurrency } = useStore();
  const currencyObject = useMemo(
    () => (
      store && store.storeCurrenciesMap[currency]
      ? store.storeCurrenciesMap[currency]
      : null
    ),
    [store, currency],
  );
  const result = useMemo(
    () => ([currencyObject, setCurrency]),
    [currencyObject, setCurrency],
  );
  return result;
}

export function useStorePage(
  where,
  skip = false,
  reloadIndex = 0,
  initialData = null,
) {
  const { data: store, loading: storeLoading } = useStore();
  const [errorReloadIndex, setErrorReloadIndex] = useState(0);
  const finalReloadIndex = reloadIndex + errorReloadIndex;
  const extractDataPages = useCallback(
    data => (data?.[0] || data?.data?.[0] || null),
    [],
  );
  const [{
    data: pageData,
    loading: pageDataLoading,
  }] = useApiRequest({
    url: `pages/render?query=${JSON.stringify({
      compileContent: true,
      limit: 1,
      where: {
        store: store?._id,
        active: true,
        ...(where || {}),
      },
      reloadIndex: finalReloadIndex,
    })}`,
    initialData,
    skip: storeLoading || !store || skip,
    extractData: extractDataPages,
  });
  const [{
    data: pageContent,
    error: pageContentError,
    loading: pageContentLoading,
  }] = useApiRequest({
    url: `storage/cbcache/${
      pageData?.contentCache || 'PAGE_NOT_FOUND'
    }`,
    skip: storeLoading || !store || !pageData,
  });
  const pageLoading = storeLoading || pageDataLoading || pageContentLoading;
  const page = useMemo(
    () => (
      !pageLoading && pageData && pageContent
      ? {
          ...pageData,
          content: pageContent,
        }
      : null
    ),
    [pageLoading, pageData, pageContent],
  );
  useEffect(
    () => {
      // let timeout;
      if (pageContentError) {
        setErrorReloadIndex(errorReloadIndex + 1);
      }
      // return () => clearTimeout(timeout);
    },
    [pageContentError, errorReloadIndex],
  );
  const result = useMemo(
    () => [{
      page,
      loading: !!(
        pageLoading
        || (!!pageData && !pageContent)
      ),
    }],
    [page, pageLoading, pageData, pageContent],
  );
  return result;
}

/* eslint-disable max-len */
import * as React from 'react';

import { useTheme } from '../../theme';
import Wrapper from '../Wrapper';

function Youtube({ type, ...props }) {
  const { custom: { iconsStyle } } = useTheme();

  return (
    <Wrapper
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`type-${iconsStyle}`}
      {...props}
    >
      {iconsStyle === 'duotone' || iconsStyle === 'duotoneOffset' ? (
        <path
          d="M22.54 6.69a2.78 2.78 0 00-1.94-2c-1.72-.42-8.6-.42-8.6-.42s-6.88 0-8.6.46a2.78 2.78 0 00-1.94 2A29 29 0 001 12.02a29 29 0 00.46 5.33 2.78 2.78 0 001.94 1.92c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 001.94-2c.312-1.732.466-3.49.46-5.25a29.005 29.005 0 00-.46-5.33z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="duotone"
          opacity={0.6}
        />
      ) : null}
      {iconsStyle === 'solid' ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.839 5.43c.34.35.582.785.701 1.26.317 1.759.471 3.543.46 5.33a28.999 28.999 0 01-.46 5.25 2.78 2.78 0 01-1.94 2c-1.72.46-8.6.46-8.6.46s-6.88 0-8.6-.46a2.78 2.78 0 01-1.94-1.92A29 29 0 011 12.02a29 29 0 01.46-5.29 2.78 2.78 0 011.94-2c1.72-.46 8.6-.46 8.6-.46s6.88 0 8.6.42c.47.133.898.388 1.239.74zm-11.79 9.69a.2.2 0 01-.299-.174V9.094a.2.2 0 01.299-.174l5.145 2.926a.2.2 0 010 .348L10.05 15.12z"
          fill="#000"
          className="solid"
        />
      ) : (
        <path
          d="M9.75 15.29l5.75-3.27-5.75-3.27v6.54z"
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="line"
        />
      )}
      <path
        d="M22.54 6.69a2.78 2.78 0 00-1.94-2c-1.72-.42-8.6-.42-8.6-.42s-6.88 0-8.6.46a2.78 2.78 0 00-1.94 2A29 29 0 001 12.02a29 29 0 00.46 5.33 2.78 2.78 0 001.94 1.92c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 001.94-2c.312-1.732.466-3.49.46-5.25a29.005 29.005 0 00-.46-5.33z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={iconsStyle === 'solid' ? 0 : 2}
        className="base"
      />
    </Wrapper>
  );
}

export default Youtube;

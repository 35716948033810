import MetaPixel from 'react-facebook-pixel';

import Tracker from './Tracker';

// import Types from '../../../modules/types';

// const { ANALYTICS_EVENTS } = Types;

export default class TrackerMeta extends Tracker {
  static NAME = 'Meta';

  setup({ store } = {}) {
    if (
         !this.active
      && store?.isCustomDomain
      && store?.data?.integrations?.metaPixel?.supported
      && store?.data?.integrations?.metaPixel?.trackingID
    ) {
      this.debug(
        'initializing',
        `(mode: ${store.mode})`,
        store?.data?.integrations?.metaPixel?.trackingID,
      );
      MetaPixel.init(
        store?.data?.integrations?.metaPixel?.trackingID,
        {},
        {
          autoConfig: true,
          debug: true,
        },
      );
      MetaPixel.pageView();
      this.active = true;
    } else {
      this.active = false;
    }
  }

  trigger(event, data) {
    if (!this.active) {
      return false;
    }
    switch (event) {
      case 'CART_ADD': {
        const {
          // eslint-disable-next-line no-unused-vars
          sku,
          price,
          meta,
          quantity = 1,
          // eslint-disable-next-line no-unused-vars
          replace = false,
          currency,
        } = data;
        const payload = {
          content_ids: meta.storeSku,
          content_name: `${meta.name} (${meta.variation})`,
          content_type: 'product',
          contents: [{
            id: meta.storeSku,
            quantity,
          }],
          value: quantity * price,
          currency,
        };
        this.debug(event, payload);
        MetaPixel.track('AddToCart', payload);
        return true;
      }
      case 'ORDER_SUBMIT': {
        const { order, currency } = data;
        const payload = {
          content_ids: order.items.map(item => item.storeSku),
          contents: order.items.map(item => ({
            id: item.storeSku,
            quantity: item.quantity,
          })),
          num_items: order.items.reduce(
            (agr, item) => agr + item.quantity,
            0,
          ),
          value: order.cost.charged,
          currency,
        };
        this.debug(event, payload);
        MetaPixel.track('InitiateCheckout', payload);
        return true;
      }
      case 'ORDER_SUBMIT_SUCCESS': {
        const { order, currency } = data;
        const payload = {
          content_ids: order.items.map(item => item.storeSku),
          content_type: 'product',
          contents: order.items.map(item => ({
            id: item.storeSku,
            quantity: item.quantity,
          })),
          num_items: order.items.reduce(
            (agr, item) => agr + item.quantity,
            0,
          ),
          value: order.cost.charged,
          currency,
        };
        this.debug(event, payload);
        MetaPixel.track('Purchase', payload);
        return true;
      }
      case 'PRODUCT_SEARCH': {
        const { searchString, category } = data;
        if ((searchString && searchString.trim().length) || category) {
          const payload = {
            search_string: searchString,
            content_category: category,
          };
          this.debug(event, payload);
          MetaPixel.track('Search', payload);
          return true;
        }
        break;
      }
      case 'PRODUCT_OPEN': {
        const { product, variation, currency } = data;
        const payload = {
          content_ids: variation.storeSku,
          content_category: product.category?.name,
          content_name: `${product.name} (${variation.name})`,
          content_type: 'product',
          value: variation.price,
          currency,
        };
        this.debug(event, payload);
        MetaPixel.track('ViewContent', payload);
        return true;
      }
      default:
    }
    return false;
  }
}

import React, { forwardRef } from 'react';

import useInView from '../../helpers/useInView';

const getTimeSafeValue = value => (
  // eslint-disable-next-line no-restricted-globals
  !isNaN(value) ? `${value}ms` : value
);

const convertToCamelCase = string => string
  .toLowerCase()
  .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

const ANIMATIONS = {
  FADE: { opacity: 0 },
  UP: { opacity: 0, translate: '0 100%' },
  DOWN: { opacity: 0, translate: '0 -10%' },
  LEFT: { opacity: 0, translate: '-10% 0' },
  RIGHT: { opacity: 0, translate: '10% 0' },
  SCALE: { opacity: 0, scale: 0.8 },
  INSTANT: {},
};

const Reveal = forwardRef(({ children, effect, ...props }, ref) => {
  const [inViewRef, inView] = useInView({
    // triggerOnce: true,
    threshold: 0.4,
  });

  const duration = effect.reveal_duration
    ? getTimeSafeValue(effect.reveal_duration) : false;
  const delay = effect.reveal_delay
    ? getTimeSafeValue(effect.reveal_delay) : 0;

  const timingFunction = effect.reveal_easeType === 'AUTO'
    ? '$ease'
    : effect.reveal_easeType === 'LINEAR'
      ? 'linear'
      : `$${convertToCamelCase([
          effect.reveal_easeType || 'EASE_OUT',
          effect.reveal_easeFunction || 'QUINT',
        ].join('_'))}`;

  const child = React.Children.only(children);

  return React.cloneElement(child, {
    ...child.props,
    ...props,
    ref: React.useCallback(node => {
      inViewRef(node);
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    }, [inViewRef, ref]),
    css: {
      ...props.css,
      ...child.props.css,
      ...!inView && ANIMATIONS[effect.reveal_type],
      ...!inView
        ? {
          // eslint-disable-next-line max-len
          transition: 'opacity $s, translate 0ms $s, scale 0ms $s !important',
        }
        : {
          transition: duration
            ? `opacity ${duration}, translate ${duration}, scale ${duration}`
            : 'opacity $m, translate $l, scale $l',
          transitionDelay: delay,
          transitionTimingFunction: timingFunction,
        },
    },
  });
})

const RevealWrapper = forwardRef(({ children, effect, ...props }, ref) => {
  if (effect) {
    return (
      <Reveal ref={ref} effect={effect} {...props}>
        {children}
      </Reveal>
    )
  }

  const child = React.Children.only(children);
  return React.cloneElement(children, {
    ref,
    ...child.props,
    ...props,
    css: {
      ...props.css,
      ...child.props.css,
    },
  });
})

export default RevealWrapper;

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { createNamedStyled } from '../../stitches.config';
import { useColorOverrides } from '../../theme';

import { Title, Paragraph } from './Text';
import Button from './Button';

const styled = createNamedStyled('Consent');

const Container = styled.named('Container')(motion.div, {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 2,

  background: '$shade',
  backdropFilter: 'blur(10px)',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,

  '@tablet': {
    padding: [0, 25],
  },
});

const Wrapper = styled.named('Wrapper')(motion.div, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 500,
  background: '$backgroundConsent',
  padding: '$s',
  borderRadius: '$m',
  gap: '$m',
});

const ButtonsWrapper = styled.named('ButtonsWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: 10,

  '@media (min-width: 460px)': {
    flexDirection: 'row',
    justifyContent: 'center',

    '& > *': {
      width: '100%',
      maxWidth: '100% !important',
    },
  },

  div: {
    textTransform: 'uppercase',
  },
});

const bodyVariants = {
  show: {
    opacity: 1,
    y: 0,
  },
  hide: {
    opacity: 0,
    y: '100%',
  },
};

const wrapperVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};

const Consent = ({
  onConfirm,
  onDeny,
  title,
  body,
  confirmLabel,
  denyLabel,
}) => {
  const [show, setShow] = useState(true);
  const colorOverrides = useColorOverrides('consent');

  const handleConfirm = () => {
    setShow(false);
    onConfirm && onConfirm();
  };

  const handleDeny = () => {
    setShow(false);
    onDeny && onDeny();
  };

  return (
    <AnimatePresence>
      {show && (
        <Container
          variants={wrapperVariants}
          initial="hide"
          animate="show"
          exit="hide"
          className={colorOverrides}
        >
          <Wrapper
            variants={bodyVariants}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <Title fontSize={30}>
              {title}
            </Title>
            <Paragraph
              fontSize={14}
              style={{
                opacity: 0.7,
              }}
            >
              {body}
            </Paragraph>
            <ButtonsWrapper>
              <Button onClick={() => handleConfirm()}>
                {confirmLabel}
              </Button>
              <Button onClick={() => handleDeny()}>
                {denyLabel}
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default Consent;

const Types = {};

Types.STORE_COLOR_CONTEXTS_MAP = {};

Types.STORE_COLOR_CONTEXTS = [{
  label: 'Website',
  value: 'website',
  choices: [
    ['background'],
    ['title'],
    ['label'],
    ['text'],
    ['icon'],
    ['cover'],
    ['dropdown', true],
    ['shade'],
    ['notification', true],
    ['button', true],
    ['accent'],
    ['border'],
    ['cover'],
    ['shadow'],
    ['loaderStart', false, 'Loader Start'],
    ['loaderEnd', false, 'Loader End'],
  ],
}, {
  label: 'Announcement',
  value: 'announcement',
  choices: [
    ['background'],
    ['text'],
  ],
}, {
  label: 'Header',
  value: 'header',
  choices: [
    ['background'],
    ['text'],
    ['label'],
    ['icon'],
    ['accent'],
    ['notification', true],
    ['dropdown', true],
  ],
}, {
  label: 'Sticky Header',
  value: 'headerSticky',
  choices: [
    ['background'],
    ['text'],
    ['label'],
    ['icon'],
    ['accent'],
    ['notification', true],
    ['dropdown', true],
    ['shadow'],
  ],
}, {
  label: 'Navigation',
  value: 'navigation',
  choices: [
    ['background'],
    ['shade'],
    ['text'],
    ['label'],
    ['icon'],
    ['accent'],
    ['notification', true],
    ['dropdown', true],
  ],
}, {
  label: 'Footer',
  value: 'footer',
  choices: [
    ['background'],
    ['title'],
    ['text'],
    ['icon'],
    ['accent'],
  ],
}, {
  label: 'Cart',
  value: 'cart',
  choices: [
    ['background'],
    ['shade'],
    ['title'],
    ['text'],
    ['label'],
    ['icon'],
    ['cover'],
    ['accent'],
    ['button', true],
    ['border'],
    ['dropdown', true],
  ],
}, {
  label: 'Card',
  value: 'card',
  choices: [
    ['background'],
    ['title'],
    ['text'],
    ['label'],
    ['cover'],
    ['icon'],
    ['accent'],
    ['button', true],
  ],
}, {
  label: 'Order Page',
  value: 'pageOrder',
  choices: [
    ['background'],
    ['title'],
    ['label'],
    ['text'],
    ['accent'],
    ['button', true],
  ],
}, {
  label: 'Consent',
  value: 'consent',
  choices: [
    ['background'],
    ['shade'],
    ['title'],
    ['label'],
    ['text'],
    ['button', true],
  ],
}, {
  label: 'Slider',
  value: 'slider',
  choices: [
    ['title'],
    ['label'],
    ['text'],
    ['cover'],
    ['button', true],
    ['cover'],
  ],
}].map((context) => {
  context.id = context.value;
  context.choices = context.choices.reduce(
    (agr, [name, bgfg, label]) => {
      if (!label) {
        label = `${name[0].toUpperCase()}${name.slice(1)}`;
      }
      if (!bgfg) {
        agr.push({
          label,
          value: name,
        });
      } else {
        agr.push({
          label: `${label} Foreground`,
          value: `${name}Foreground`,
        }, {
          label: `${label} Background`,
          value: `${name}Background`,
        });
      }
      return agr;
    },
    [],
  );
  context.choices.sort((a, b) => (
      a.label > b.label ? 1
    : a.label < b.label ? -1
    : 0
  ));
  Types.STORE_COLOR_CONTEXTS_MAP[context.id] = context;
  return context;
});

export default Types;

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Collapse = ({ children, isopen }) => {
  const variants = {
    open: {
      height: 'auto',
      opacity: 1,
    },
    collapse: {
      height: 0,
      opacity: 0,
    },
  };

  return (
    <>
      <AnimatePresence initial={false}>
        {isopen && (
          <motion.div
            initial="collapse"
            animate="open"
            exit="collapse"
            variants={variants}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Collapse;

import React, { useMemo } from 'react';
import { styled } from '../../stitches.config';

import { useApiRequest } from '../../context/Api';
import { useStore } from '../../context/Store';
import { useDictionary } from '../../context/Language';
import { parseProduct } from '../../context/Product';

import Carousel from '../Patterns/Carousel';
import Card from '../Elements/Card';

import Types from '../../modules/types';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '$siteWidth',
  margin: '0 auto',
  overflow: 'hidden',
});

function useSectionCardsContentProducts(section) {
  const { cards_contentProducts: config } = section;
  const { data: store } = useStore();
  const { currency: dictionaryCurrency } = useDictionary();
  const { currency: systemCurrency } = Types.getSystemCountry(
    store.systemCountry,
  );
  const currency = dictionaryCurrency[systemCurrency];
  const [{ data }] = useApiRequest({
    url: `products?query=${JSON.stringify(
      config.mode === 'dynamic'
      ? {
          where: {
            AND: [
              {
                store: store._id,
                'variations.priceBeforeSale': (
                    config.onSale
                  ? { GT: 0 }
                  : undefined
                ),
                featured: config.featured ? { EQ: true } : undefined,
              },
              config.categories.length
              ? {
                  OR: [
                    { category: { IN: config.categories } },
                    { categories: { IN: config.categories } },
                  ],
                }
              : null,
            ].filter(condition => condition),
          },
          sort: {
            [
                config.sortBy === 'createdAt'
              ? 'createdAt'
              : config.sortBy === 'price'
              ? 'priceLowest'
              : 'name'
            ]: config.sortDirection === 'ASC' ? 1 : -1,
            name: 1,
          },
          populate: (
            store.type === 'REFERRAL'
            ? {
                referralStore: true,
                referralProduct: true,
              }
            : undefined
          ),
          limit: 20,
        }
      : {
          where: {
            _id: { IN: config.items.map(item => item.product) },
            store: store._id,
          },
        }
    )}`,
  });
  return useMemo(
    () => {
      if (data) {
        if (config.mode === 'manual') {
          const map = data.reduce(
            (agr, product) => {
              agr[product._id] = parseProduct(
                product,
                store,
                currency,
                false,
              );
              return agr;
            },
            {},
          );
          return config.items.reduce(
            (agr, { product }) => {
              if (map[product]) {
                agr.push(map[product]);
              }
              return agr;
            },
            [],
          );
        }
        if (config.mode === 'dynamic') {
          return data.map(product => parseProduct(
            product,
            store,
            currency,
            false,
          ));
        }
      }
      return data || [];
    },
    [data, config.mode, config.items, store, currency],
  );
}

function SectionCardsContentProducts({ section, id }) {
  const products = useSectionCardsContentProducts(section);
  if (!products.length) { return null; }
  return (
    <Wrapper>
      <Carousel key={section._id} id={id}>
        {products.map(product => <Card key={product._id} product={product} />)}
      </Carousel>
    </Wrapper>
  );
}

function SectionCards({ section, id }) {
  return (
    <SectionCardsContentProducts id={id} section={section} />
  );
  // return <Debug section={section} />;
}

export default SectionCards;

import React, { useContext } from 'react';
import getKey from 'lodash/get';

import { createNamedStyled } from '../../stitches.config';
import { useColorOverrides } from '../../theme';

import { Paragraph, Title } from './Text';
import SocialIcons from './SocialIcons';

import StoreContext from '../../context/Store';
import { useDictionary } from '../../context/Language';

const { REACT_APP_PACKMAN_HOME_URL } = process.env;

const styled = createNamedStyled('Footer');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$backgroundFooter',
  width: '100%',
  gap: '$s',

  padding: 'calc($s * 1.5) $s $s',
  '@desktop+': { padding: 'calc($m * 1.5) $m $m' },
});

const Content = styled.named('Content')('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const Footer = () => {
  const { data: store } = useContext(StoreContext);

  const colorOverrides = useColorOverrides('footer');

  const {
    footerPoweredBy,
    footerCopyright,
    footerAllRightsReserved,
  } = useDictionary();

  if (!store) {
    return null;
  }

  const social = [
    'facebook',
    'instagram',
    'twitter',
    'whatsapp',
    'tiktok',
    'youtube',
    'snapchat',
  ].reduce(
    (agr, media) => {
      const item = getKey(store, `${media}Href`, null);
      if (item) {
        agr[`${media}Href`] = item;
      }
      return agr;
    },
    {},
  );

  return (
    <Wrapper className={colorOverrides}>
      <Title size="xs">{store.name}</Title>
      <Content>
        <Paragraph
          small
          as="a"
          href={`tel:${store.phoneNumberString}`}
          css={{ direction: 'ltr' }}
        >
          {store.phoneNumberString}
        </Paragraph>
        <Paragraph small as="a" href={`mailto:${store.email}`}>
          {store.email}
        </Paragraph>
      </Content>
      {Object.keys(social).length > 0
        ? <SocialIcons /> : null}
      <Content>
        <Paragraph small as="a" href={REACT_APP_PACKMAN_HOME_URL}>
          {footerPoweredBy}
        </Paragraph>
        <Paragraph small css={{ textAlign: 'center' }}>
          {
            `${footerCopyright} ${
              new Date().getFullYear()
            }, ${
              store.name
            }. ${footerAllRightsReserved}`
          }
        </Paragraph>
      </Content>
    </Wrapper>
  );
};

export default Footer;

import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';
import { useLocation as useLocationOriginal } from 'react-router-dom';
import { parse as parseQuery } from 'querystring';

const LocationContext = createContext();
export const { Consumer } = LocationContext;
export default LocationContext;

export const Provider = ({ children }) => {
  const location = useLocationOriginal();
  const searchParams = useMemo(
    () => parseQuery(
        location.search?.length > 1
      ? location.search.slice(1)
      : ''
    ) || {},
    [location.search],
  );
  const locationNew = useMemo(
    () => ({
      ...location,
      searchParams,
    }),
    [location, searchParams],
  );
  return (
    <LocationContext.Provider value={locationNew}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);

import React, { useMemo, useEffect } from 'react';

import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import Autoplay from 'embla-carousel-autoplay'

import { createNamedStyled } from '../../stitches.config';
import { useTheme } from '../../theme';

import Base from './Base';

const styled = createNamedStyled('Slider');

const Node = styled(Base, {
  width: '100%',
  // overflow: 'hidden',

  cursor: 'grab',
  ':active': { cursor: 'grabbing' },
});

const Track = styled('div', {
  display: 'flex',
  width: '100%',

  '& > *': { flexShrink: 0 },

  // scrollBehavior: 'smooth',
  // overflowX: 'scroll',
  // scrollSnapStop: 'always',
  // scrollSnapType: 'x mandatory',

  // '&::-webkit-scrollbar': { display: 'none' },

  // '& > *': {
  //   flexShrink: 0,
  //   scrollSnapAlign: 'start',
  // },
});

const getPixelSafeValue = value => (
  // eslint-disable-next-line no-restricted-globals
  !isNaN(value) ? `${value}px` : value
);

const Slider = ({ node, renderNode, css, ...props }) => {
  const finalCss = useMemo(
    () => ({
      ...node?.slider_gap ? {
        // gap: getPixelSafeValue(node.slider_gap),
        '& > *': { marginInlineEnd: getPixelSafeValue(node.slider_gap) },
      } : {},
      ...css,
    }),
    [css, node?.slider_gap],
  );

  const { language } = useTheme();

  const wheelGestures = useMemo(() => WheelGesturesPlugin(), []);

  const autoplay = useMemo(() => (node?.slider_autoplay ? [Autoplay({
    // stopOnMouseEnter: true,
    stopOnLastSnap: !node?.slider_loop,
  })] : []), [node?.slider_autoplay, node?.slider_loop]);

  const [emblaRef, embla] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    skipSnaps: true,
    draggable: true,
    align: 'start',
    direction: language.direction,
    loop: node?.slider_loop,
  }, [wheelGestures, ...autoplay]);

  useEffect(() => {
    if (!embla) return;
    embla.reInit();
    // setTimeout(() => { embla.reInit() }, 500);
  }, [embla]);

  // const trackRef = useRef(null);

  // useEffect(() => {
  //   if (!trackRef.current) return;
  //   trackRef.current.addEventListener('mousedown', (e) => mouseIsDown(e));
  //   trackRef.current.addEventListener('mouseup', (e) => mouseUp(e));
  //   trackRef.current.addEventListener('mouseleave', (e) => mouseLeave(e));
  //   trackRef.current.addEventListener('mousemove', (e) => mouseMove(e));

  //   let startX;
  //   let scrollLeft;
  //   let isDown;

  //   function mouseIsDown(e) {
  //     isDown = true;
  //     startX = e.pageX - trackRef.current.offsetLeft;
  //     scrollLeft = trackRef.current.scrollLeft;
  //     trackRef.current.style.scrollSnapType = 'initial';
  //     trackRef.current.style.scrollBehavior = 'initial';
  //   }
  //   function mouseUp(e) {
  //     isDown = false;
  //     trackRef.current.style.scrollSnapType = 'x mandatory';
  //     trackRef.current.style.scrollBehavior = 'smooth';
  //   }
  //   function mouseLeave(e) {
  //     isDown = false;
  //   }
  //   function mouseMove(e) {
  //     if (isDown) {
  //       e.preventDefault();
  //       // Move vertcally
  //       const x = e.pageX - trackRef.current.offsetLeft;
  //       const walkX = (x - startX) * 1;
  //       trackRef.current.scrollLeft = scrollLeft - walkX;
  //     }
  //   }
  // }, []);

  return (
    <Node
      {...props}
      node={node}
      ref={emblaRef}
      data-items-length={node?.slider_items?.length}
    >
      <Track
        css={finalCss}
        // ref={trackRef}
      >
        {node?.slider_items?.map(item => renderNode(item))}
      </Track>
    </Node>
  );
};

export default Slider;

import React from 'react';
import { createNamedStyled } from '../../stitches.config';

import { Title, Label } from '../Elements/Text';
import Button from '../Elements/Button';
import StoreDefinedLink from '../../helpers/StoreDefinedLink';
import WYSIWYG from './WYSIWYG';
import PriceWithCurrency from '../../helpers/PriceWithCurrency';

const styled = createNamedStyled('LegacyContent');

const ALIGNMENT = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const INLINE = {
  left: '$custom$inlineStart',
  center: 'center',
  right: '$custom$inlineEnd',
};

const Wrapper = styled.named('Wrapper')('div', {
  background: '$backgroundWebsite',
  display: 'flex',
  flexDirection: 'column',

  maxWidth: '$siteWidth',
  margin: '0 auto',

  '@tablet+': {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '$m',
  },

  padding: '$s $s',
  gap: '$m',
  '@desktop+': {
    padding: '$m $m',
    gap: '$l',
  },
});

const Inner = styled.named('Inner')('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  textAlign: 'center',
  gap: 10,
});

const ImageWrapper = styled.named('ImageWrapper')('div', {
  flex: '0 0 auto',
  width: '100%',
  position: 'relative',
});

const Image = styled.named('Image')('img', {
  width: '100%',
  maxWidth: '100%',
  borderRadius: '$l',
  objectFit: '$custom$objectFit',

  variants: {
    withVideo: {
      true: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
});

const ButtonWrapper = styled.named('ButtonWrapper')('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Content = ({
  id,
  title,
  subtitle,
  content,
  image,
  video,
  contentAlignment,
  imageAlignment,
  buttonLabel,
  buttonLink,
  imageWidth,
  ...props
}) => {
  // TODO: Hack, revisit other options
  const ensureCurrency = (value) => {
    if (value.startsWith('AED') || value.startsWith('OMR')) {
      const price = parseFloat(value.replace(/[^0-9.]/g, ''), 10);
      return <PriceWithCurrency value={price} vat />;
    }

    return value;
  };

  return (
    <Wrapper
      id={id}
      {...props}
      css={{
        justifyContent: (title || subtitle) ? 'space-between' : 'center',
      }}
    >
      {(image || video) && (
        <ImageWrapper
          css={{
            '@tablet+': {
              width: `${imageWidth || 50}%`,
              order: imageAlignment === 'right' ? 1 : 0,
              ...(image && video) && { aspectRatio: '$custom$aspectRatio' },
            },
          }}
        >
          {image ? (
            <Image
              as="img"
              src={image.src}
              withVideo={(image && video)}
            />
          ) : null}
          {video ? (
            <Image
              withVideo={!!(image && video)}
              as="video"
              src={video.src}
              autoPlay
              muted
              loop
              playsInline
            />
          ) : null}
        </ImageWrapper>
      )}
      {(title || subtitle || content || buttonLabel) && (
        <Inner
          css={{
            '@tablet+': {
              textAlign: INLINE[contentAlignment],
              alignItems: ALIGNMENT[contentAlignment],
            },
          }}
        >
          {title && <Title bold fontSize={50}>{title}</Title>}
          {subtitle && <Label fontSize={15}>{ensureCurrency(subtitle)}</Label>}
          {content && <WYSIWYG content={content} />}
          {buttonLabel && (
            <ButtonWrapper>
              <Button as={StoreDefinedLink} to={buttonLink}>
                {ensureCurrency(buttonLabel)}
              </Button>
            </ButtonWrapper>
          )}
        </Inner>
      )}
    </Wrapper>
  );
};

export default Content;

import React from 'react';

import { createNamedStyled } from '../../../stitches.config';

import Types from '../../../modules/types';

import { useCart } from '../../../context/Cart';
import { useStore, useStoreCurrency } from '../../../context/Store';
import { useDictionary } from '../../../context/Language';

import { Title, Label, Paragraph } from '../Text';
import PriceWithCurrency from '../../../helpers/PriceWithCurrency';

const styled = createNamedStyled('CartTotal');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'calc($xs / 2)',
  marginTop: '$m',
});

const Row = styled.named('Row')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

const Price = ({
  name, label, value, skipCurrencyConversion, ...props
}) => (value ? (
  <Row key={name} {...props}>
    {label ? <Label css={{ opacity: 0.5 }}>{`${label}: `}</Label> : null}
    <Label {...props}>
      <PriceWithCurrency
        value={value}
        skipCurrencyConversion={skipCurrencyConversion}
      />
    </Label>
  </Row>
) : null);

const Divider = () => (
  <Label css={{ opacity: 0.25 }}>- - - </Label>
);

const Total = ({ formValues = {} }) => {
  const { data: { systemCountry } } = useStore();

  const [currencyObject] = useStoreCurrency();
  const { currency: systemCurrency } = Types.getSystemCountry(systemCountry);

  const cart = useCart();
  const {
    priceItems,
    priceItemsDiscount,
    priceShipping,
    pricePayment,
    priceServiceFee,
    priceVat,
    priceCharged,
    priceChargedFormatted,
    defaultSubmitFields: { payment } = {},
    payment: cartPayment,
    // ...rest
  } = cart;
  // console.log('cartRest: ', rest);

  const chargedNow = cartPayment?.[payment]?.chargedNow;

  const {
    cartTotalTitle: labelTitle,
    cartTotalProducts: labelProducts,
    cartTotalDiscount: labelDiscount,
    cartTotalShipping: labelShipping,
    cartTotalServiceFee: labelServiceFee,
    cartTotalDueNow: labelDueNow,
    cartTotalVat: labelVat,
    cartRealChargeDisclaimer: labelRealChargeDisclaimer,
    currency,
    PAYMENT_METHOD_LABELS_MAP,
  } = useDictionary();

  const labelPayment = PAYMENT_METHOD_LABELS_MAP[payment || 'CARD'];

  const labelCurrency = currency[systemCurrency];

  return (
    <Wrapper>
      <Title css={{ fontSize: 'calc($s * 1.35)' }} bold>{labelTitle}</Title>
      <Divider />
      <Price name="products" label={labelProducts} value={priceItems} />
      <Price name="discount" label={labelDiscount} value={priceItemsDiscount} />
      <Price name="shipping" label={labelShipping} value={priceShipping} />
      <Price name="payment" label={labelPayment} value={pricePayment} />
      <Price
        name="serviceFee"
        label={labelServiceFee}
        value={priceServiceFee}
      />
      <Price name="vat" label={labelVat} value={priceVat} />
      <Divider />
      <Price
        name="charged"
        value={priceCharged}
        css={{
          fontSize: 'calc($label * 1.2)',
          fontWeight: 'bold',
          ...(
            chargedNow > 0
            ? { textDecoration: 'line-through' }
            : {}
          ),
        }}
      />
      {
        chargedNow > 0
        ? (
            <Price
              name="chargedNow"
              label={labelDueNow}
              value={chargedNow}
              // skipCurrencyConversion
            />
          )
        : null
      }
      {/* DISCLAIMER */}
      {
        currencyObject.code !== labelCurrency
        && formValues?.payment !== 'TABBY.PAY_BY_INSTALMENTS'
        ? (
            <Paragraph css={{ fontSize: '80%', opacity: 0.5 }}>
              {'* '}
              {labelRealChargeDisclaimer}
              {' '}
              {`${labelCurrency} ${priceChargedFormatted}`}
            </Paragraph>
          )
        : null
      }
    </Wrapper>
  );
};

export default Total;

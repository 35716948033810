import React, { createContext, useContext } from 'react';

// import { ObjectId } from 'bson';

import ContextProviderComponent from './ContextProviderComponent';
import ApiContext from './Api';
// import Debug from '../Debug';

// import NotFound from '../components/Sections/NotFound';
import RedirectToHome from '../helpers/RedirectToHome';

const Context = createContext();

const isValidObjectId = (id) => (
  typeof id === 'string' && /^[a-f\d]{24}$/i.test(id)
);

export default Context;

export function Provider(props) {
  const api = useContext(ApiContext);
  return <OrderProvider api={api} {...props} />;
}

export const { Consumer } = Context;

export class OrderProvider extends ContextProviderComponent {
  static NAME = 'ORDER'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  constructor(props) {
    super(props);
    this.state.loading = true;
    this.state.data = null;

    this.exposeMethods([
      'getOrder',
    ])
  }

  componentDidMount() {
    this.getOrder(this.props);
  }

  componentDidUpdate(props) {
    if (this.props.id !== props.id) {
      this.getOrder(props);
    }
  }

  getOrder = async ({ id } = this.props) => {
    this.setState({ loading: true });
    const isAbandonedCart = isValidObjectId(id);
    const aborter = new AbortController();
    try {
      const data = await this.props.api.get(
        (
            isAbandonedCart
          ? `abandoned-carts/${id}`
          : `orders/${id}`
        ),
        undefined,
        this.aborter,
        this.aborter = aborter,
      );
      if (isAbandonedCart) {
        // Pretend this is an order with a draft status
        data.isAbandonedCart = true;
        data.status = 'DRAFT';
      }
      this.setState({
        loading: false,
        data,
      });
    } catch (error) {
      this.setState({ loading: false, data: null });
    }
  }

  render() {
    const { loading, data } = this.state;
    if (!loading && !data) {
      return <RedirectToHome isCustomDomain={this.props.isCustomDomain} />;
    }
    return super.render();
  }
}

export function useOrder() {
  return useContext(Context);
}

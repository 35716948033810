import TypesHelpers from './types.helpers';

const Types = {};

Object.assign(
  Types,
  TypesHelpers.createValues('CONTENT_BUILDER_LIBRARY_FIELD_TYPE', [
    { id: 'TEXT', label: 'Text' },
    { id: 'IMAGE', label: 'Image' },
  ]),
);

Object.assign(
  Types,
  {
    CONTENT_BUILDER_EFFECT: {
      node: 'EFFECT',
      ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
        id: 'BACKGROUND',
        label: 'Background',
        realms: ['FRAME', 'TEXT'],
        children: {
          background_items: {
            node: 'EFFECT:BACKGROUND:ITEM',
            ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
              id: 'COLOR',
              label: 'Color',
            }, {
              id: 'GRADIENT',
              label: 'Gradient',
            }, {
              id: 'IMAGE',
              label: 'Image',
              fileKeys: ['image_image'],
            }]),
          },
        },
      }, {
        id: 'MASK',
        label: 'Mask',
        realms: ['FRAME'],
        children: {
          mask_items: {
            node: 'EFFECT:MASK:ITEM',
            ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
              id: 'IMAGE',
              label: 'Image',
              fileKeys: ['image_image'],
            }, {
              id: 'GRADIENT',
              label: 'Gradient',
            }]),
          },
        },
      }, {
        id: 'SHADOW',
        label: 'Shadow',
        realms: ['FRAME'],
        children: {
          shadow_items: {
            node: 'EFFECT:SHADOW:ITEM',
            ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
              id: 'DEFAULT',
              label: 'Shadow Layer',
            }]),
          },
        },
      }, {
        id: 'LINK',
        label: 'Link',
        realms: ['FRAME'],
      }, {
        id: 'BORDER',
        label: 'Border',
        realms: ['FRAME'],
      }, {
        id: 'BORDER_RADIUS',
        label: 'Border Radius',
        realms: ['FRAME'],
      }, {
        id: 'ZOOM',
        label: 'Zoom',
        realms: ['FRAME'],
      }, {
        id: 'BLEND_MODE',
        label: 'Blend Mode',
        realms: ['FRAME'],
      }, {
        id: 'PARALLAX',
        label: 'Parallax',
        realms: ['FRAME'],
      }, {
        id: 'REVEAL',
        label: 'Reveal',
        realms: ['FRAME'],
      }, {
        id: 'COLOR',
        label: 'Color',
        realms: ['TEXT'],
      }, {
        id: 'LETTER_SPACING',
        label: 'Letter Spacing',
        realms: ['TEXT'],
      }, {
        id: 'WHITE_SPACE',
        label: 'White Space',
        realms: ['TEXT'],
      }, {
        id: 'TRANSFORM',
        label: 'Transform',
        realms: ['FRAME', 'TEXT'],
        children: {
          transform_items: {
            node: 'EFFECT:TRANSFORM:ITEM',
            ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
              id: 'TRANSLATE',
              label: 'Translate',
            }, {
              id: 'SCALE',
              label: 'Scale',
            }, {
              id: 'ROTATE',
              label: 'Rotate',
            }]),
          },
        },
      }, {
        id: 'FILTER',
        label: 'Filter',
        realms: ['FRAME', 'TEXT'],
        children: {
          filter_items: {
            node: 'EFFECT:FILTER:ITEM',
            ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
              id: 'BLUR',
              label: 'Blur',
              addonAfter: 'px',
            }, {
              id: 'BRIGHTNESS',
              label: 'Brightness',
              addonAfter: '%',
            }, {
              id: 'CONTRAST',
              label: 'Contrast',
              addonAfter: '%',
            }, {
              id: 'SATURATION',
              label: 'Saturation',
              addonAfter: '%',
            }, {
              id: 'OPACITY',
              label: 'Opacity',
              addonAfter: '%',
            }]),
          },
        },
      }, ...(
        ['FRAME', 'TEXT'].map(suffix => ({
          id: `TRANSITION_${suffix}`,
          label: 'Transition',
          realms: [suffix],
          transitionable: false,
          children: {
            [`transition_${suffix.toLowerCase()}_items`]: {
              node: `EFFECT:TRANSITION_${suffix}:ITEM`,
              ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
                id: 'DEFAULT',
                label: 'Transition',
              }]),
            },
          },
        }))
      )]),
    },
  },
);

['FRAME', 'TEXT'].forEach((realm) => {
  const key = `TRANSITION_${realm}`;
  Types.CONTENT_BUILDER_EFFECT.TYPE_MAP[key].propertyChoices = (
    Types.CONTENT_BUILDER_EFFECT.TYPE_ITEMS.reduce(
      (agr, item) => {
        if (item.transitionable !== false && item.realms.includes(realm)) {
          agr.push({
            value: item.id,
            label: item.label,
          });
        }
        return agr;
      },
      [],
    )
  );
});

Object.assign(
  Types,
  {
    CONTENT_BUILDER_ITEM: {
      node: 'ITEM',
      ...TypesHelpers.createValues.withAssignedTypes('TYPE', [{
        id: 'FRAME',
        label: 'Frame',
        children: {
          frame_items: () => Types.CONTENT_BUILDER_ITEM,
          frame_effects: () => Types.CONTENT_BUILDER_EFFECT,
          frame_effectsHover: () => Types.CONTENT_BUILDER_EFFECT,
        },
      }, {
        id: 'REVEAL',
        label: 'Reveal',
        hidden: true,
        children: {
          reveal_items: () => Types.CONTENT_BUILDER_ITEM,
        },
      }, {
        id: 'PARALLAX',
        label: 'Parallax',
        hidden: true,
        children: {
          parallax_items: () => Types.CONTENT_BUILDER_ITEM,
        },
      }, {
        id: 'COMPONENT',
        label: 'Component',
        hidden: true,
        children: {
          component_items: () => Types.CONTENT_BUILDER_ITEM,
        },
        childrenResolve: {
          component_data: true,
        },
      }, {
        id: 'BLOCK',
        label: 'Block',
        hidden: true,
        children: {
          block_items: () => Types.CONTENT_BUILDER_ITEM,
        },
      }, {
        id: 'BUTTON',
        label: 'Button',
      }, {
        id: 'TEXT',
        label: 'Text',
        children: {
          text_effects: () => Types.CONTENT_BUILDER_EFFECT,
          text_effectsHover: () => Types.CONTENT_BUILDER_EFFECT,
        },
      }, {
        id: 'TEXT_BLOCK',
        label: 'Text Block',
      }, {
        id: 'LINK',
        label: 'Link',
        hidden: true,
        children: {
          link_items: () => Types.CONTENT_BUILDER_ITEM,
        },
      }, {
        id: 'MEDIA',
        label: 'Media',
        fileKeys: ['media_image', 'media_video'],
      }, {
        id: 'SLIDER',
        label: 'Slider',
        children: {
          slider_items: () => Types.CONTENT_BUILDER_ITEM,
        },
      }, {
        id: 'TOGGLE',
        label: 'Toggle',
        children: {
          toggle_items: () => Types.CONTENT_BUILDER_ITEM,
          toggle_trigger: () => Types.CONTENT_BUILDER_ITEM,
        },
      }, {
        id: 'PRODUCTS',
        label: 'Products',
      }, {
        id: 'IFRAME',
        label: 'iFrame',
      }, {
        id: 'LEGACY_CONTENT',
        label: 'Content (Legacy)',
        hidden: true,
      }, {
        id: 'LEGACY_SLIDER',
        label: 'Slider',
        hidden: true,
      }, {
        id: 'LEGACY_CARDS',
        label: 'Cards',
        hidden: true,
      }, {
        id: 'LEGACY_STATS',
        label: 'Stats (Legacy)',
        hidden: true,
      }, {
        id: 'LEGACY_BUTTON',
        label: 'Button (Legacy)',
        hidden: true,
      }]),
    },
  },
);

(() => {
  const result = Types.CONTENT_BUILDER_CHILDREN_KEYS = {};
  const resultResolve = Types.CONTENT_BUILDER_CHILDREN_KEYS_RESOLVE = {};
  function extract(data, done = []) {
    if (data === true) {
      return;
    }
    const { node, TYPE_ITEMS } = data;
    if (!done.includes(node)) {
      done.push(node);
      TYPE_ITEMS.forEach((item) => {
        const key = `${node}/${item.type}`;
        if (item.children) {
          result[key] = Object.keys(item.children);
          result[key].forEach((childKey) => {
            if (typeof item.children[childKey] === 'function') {
              item.children[childKey] = item.children[childKey]();
            }
            extract(item.children[childKey], done);
          });
          resultResolve[key] = [...result[key]];
        }
        if (item.childrenResolve) {
          resultResolve[key] = resultResolve[key] || [];
          resultResolve[key].push(...Object.keys(item.childrenResolve));
        }
      });
    }
  }
  extract(Types.CONTENT_BUILDER_ITEM);
})();

Object.assign(
  Types,
  TypesHelpers.createValues('CONTENT_BUILDER_SEGMENT', [{
    id: 'COMPONENT',
    label: 'Component',
  }, {
    id: 'BLOCK',
    label: 'Block',
  }, {
    id: 'LIBRARY_ITEM',
    label: 'Library Item',
  }]),
);

Object.assign(
  Types,
  TypesHelpers.createValues('CONTENT_BUILDER_CACHE_TYPE', [{
    id: 'PAGE',
    label: 'Page',
  }, {
    id: 'BLOCK',
    label: 'Block',
  }]),
);

Types.traverseContentBuilderNodes = (
  nodeInput,
  nodeCallback,
  traverseKeysMap = Types.CONTENT_BUILDER_CHILDREN_KEYS,
  ...restArgs
) => TypesHelpers.traverse.objects(
  nodeInput,
  (input, path, cache, callback, traverse) => {
    const nodeId = input['@node'];
    cache = { ...cache };
    if (nodeId) {
      const nodeKey = `${nodeId}/${input.type}`;
      nodeCallback(input, path, cache);
      const traverseKeys = traverseKeysMap[nodeKey];
      if (Array.isArray(traverseKeys)) {
        traverseKeys.forEach(key => traverse(
          input[key],
          callback,
          cache,
          [...path, key],
        ));
        return true;
      }
    }
    return false;
  },
  ...restArgs,
);

Types.traverseContentBuilderNodesAsync = (
  nodeInput,
  nodeCallback,
  traverseKeysMap = Types.CONTENT_BUILDER_CHILDREN_KEYS,
  ...restArgs
) => TypesHelpers.traverseAsync.objects(
  nodeInput,
  async (input, path, cache, callback, traverse) => {
    // TODO Check in original traverse if path longer than N and abort anyway to prevent infinite loops (easy fix, not the best)
    // console.log(path, input);
    const nodeId = input?.['@node'];
    cache = { ...cache };
    if (nodeId) {
      const nodeKey = `${nodeId}/${input.type}`;
      await nodeCallback(input, path, cache);
      const traverseKeys = traverseKeysMap[nodeKey];
      if (Array.isArray(traverseKeys)) {
        await Promise.all(traverseKeys.map(key => traverse(
          input[key],
          callback,
          cache,
          [...path, key],
        )));
        return true;
      }
    }
    return false;
  },
  ...restArgs,
);

export default Types;

import isFunction from 'lodash/isFunction';

import React, { Component } from 'react';

export default class ContextProviderComponent extends Component {
  static defaultProps = {}

  static debug(...args) {
    // eslint-disable-next-line no-console
    console.log(`${this.NAME || this.name || this.componentName}`, ...args);
  }

  constructor(props) {
    super(props);
    this.state = { debug: this.debug };
  }

  debug = (...args) => {
    this.constructor.debug(...args);
  }

  setState(...args) {
    if (!this.unmounted) {
      return super.setState(...args);
    }
    return undefined;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  exposeMethods(...methods) {
    methods.forEach((method) => {
      if (!isFunction(this[method])) {
        throw new Error(`Cannot expose undefined method ${
          method
        } on Context ${
          this.NAME
        }`);
      }
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state[method] = this[method];
    });
  }

  render(extraChildren = null) {
    const { ProviderComponent, children } = this.props;
    return (
      <ProviderComponent value={this.state}>
        {children}
        {extraChildren}
      </ProviderComponent>
    );
  }
}

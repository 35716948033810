import React, { useMemo } from 'react';
import { styled } from '../../stitches.config';

import replaceStitchesCustomProps from
  '../../helpers/replaceStitchesCustomProps';

const NodeHTML = React.forwardRef(
  ({ tag = 'div', dangerouslySetInnerHTML, ...props }, ref) => {
    const render = React.createElement(tag, { ref });
    return React.cloneElement(render, { dangerouslySetInnerHTML, ...props });
});

const Node = styled.unnamed(NodeHTML, {});

const selfClosingTags = [
  'input', 'img', 'br', 'hr', 'meta', 'link',
  'area', 'base', 'col', 'command', 'embed',
  'keygen', 'param', 'source', 'track', 'wbr',
];

function parseHtmlTag(tagString) {
  if (!tagString?.includes('[')) {
    return { htmlTag: tagString, attributes: {} };
  }

  const [tag, attributesString] = tagString.split('[');
  const attributes = attributesString
    .slice(0, -1)
    .split(' ')
    .reduce((acc, attr) => {
      const equalsIndex = attr.indexOf('=');
      if (equalsIndex !== -1) {
        const key = attr.substring(0, equalsIndex);
        let value = attr.substring(equalsIndex + 1);
        if (value.startsWith('"') && value.endsWith('"')) {
          value = value.slice(1, -1);
        }
        acc[key] = value;
      } else {
        acc[attr] = true;
      }
      return acc;
    }, {});

  return { htmlTag: tag, attributes };
}

function parseHtmlAttributes(props) {
  return Object.keys(props).reduce((acc, key) => {
    if (!props[key] || props[key] !== 'false') {
      acc[key] = props[key];
    }
    return acc;
  }, {});
}

const Base = React.forwardRef(({ node, tag, children, css, ...props }, ref) => {
  const finalCss = useMemo(
    () => ({
      ...css,
      ...(node?.hide || []).reduce((acc, device) => ({
        ...acc,
        [`@${device.toLowerCase()}`]: { display: 'none !important' },
      }), {}),
      ...replaceStitchesCustomProps(node?.customProps, node?.stitchesCss),
      ...node.css,
    }),
    [css, node?.hide, node?.customProps, node?.stitchesCss, node.css],
  );

  const { htmlTag, attributes } = useMemo(
    () => parseHtmlTag(node?.htmlTag || tag),
    [node?.htmlTag, tag]
  );

  const htmlAttributes = useMemo(() => (
    parseHtmlAttributes(node?.htmlAttributes || {})
    ), [node?.htmlAttributes]
  );

  const StyledNode = useMemo(() => styled.unnamed(Node, finalCss), [finalCss]);

  const isSelfClosing = useMemo(
    () => selfClosingTags.includes(htmlTag || tag), [htmlTag, tag]
  );

  return (
    <>
      {!!node?.component_customCss && (
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: node?.component_customCss }}
        />
      )}
      {isSelfClosing ? (
        <StyledNode
          {...props}
          {...node?.stitchesVariants || {}}
          {...Object.fromEntries(
            Object.entries(node?.stitchesVariantsBoolean || {}).map(
              ([key, value]) => [key, !!value],
            ),
          )}
          ref={ref}
          tag={htmlTag || tag}
          {...attributes}
          {...htmlAttributes}
          id={node.id}
          className={`${props.className} ${node.className || ''}`}
          {...Object.fromEntries(
            Object.entries(node?.dataAttributes || {}).map(([key, value]) => [
              `data-${key}`,
              value,
            ]),
          )}
        />
      ) : (
        <StyledNode
          {...props}
          {...node?.stitchesVariants || {}}
          {...Object.fromEntries(
            Object.entries(node?.stitchesVariantsBoolean || {}).map(
              ([key, value]) => [key, !!value],
            ),
          )}
          ref={ref}
          tag={htmlTag || tag}
          {...attributes}
          {...htmlAttributes}
          id={node.id}
          className={`${props.className} ${node.className || ''}`}
          data-custom-name={node.name}
          {...Object.fromEntries(
            Object.entries(node?.dataAttributes || {}).map(([key, value]) => [
              `data-${key}`,
              value,
            ]),
          )}
        >
          {children}
        </StyledNode>
      )}
    </>
  );
});

export default Base;

import TypesHelpers from './types.helpers';

const Types = {};
Object.assign(
  Types,
  TypesHelpers.createValues('PRODUCT_ATTRIBUTE', [{
    id: 'FLAMMABLE',
    label: 'Flammable',
  }, {
    id: 'FRAGILE',
    label: 'Fragile',
  }, {
    id: 'WEAPON',
    label: 'Weapon',
  }, {
    id: 'BATTERY',
    label: 'Battery',
  }]),
);

export default Types;

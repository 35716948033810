import React from 'react';
import getKey from 'lodash/get';

import { createNamedStyled } from '../../stitches.config';
import { useStore } from '../../context/Store';

import {
  Facebook,
  Twitter,
  Instagram,
  Whatsapp,
  Tiktok,
  Snapchat,
  Youtube,
} from '../../icons';

const styled = createNamedStyled('SocialIcons');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  gap: '$s',
});

const Divider = styled.named('Divider')('div', {
  width: 1,
  height: 12,
  background: '$text',
  opacity: 0.5,
});

const SocialIcons = ({ withDivider, ...props }) => {
  const { data: store } = useStore();

  const social = [
    'facebook',
    'instagram',
    'twitter',
    'whatsapp',
    'tiktok',
    'snapchat',
    'youtube',
  ].reduce(
    (agr, media) => {
      const item = getKey(store, `${media}Href`, null);
      if (item) {
        agr[`${media}Href`] = item;
      }
      return agr;
    },
    {},
  );

  const icons = [{
    icon: <Facebook />,
    href: social.facebookHref,
    name: 'Facebook',
  }, {
    icon: <Twitter />,
    href: social.twitterHref,
    name: 'Twitter',
  }, {
    icon: <Instagram />,
    href: social.instagramHref,
    name: 'Instagram',
  }, {
    icon: <Whatsapp />,
    href: social.whatsappHref,
    name: 'Whatsapp',
  }, {
    icon: <Youtube />,
    href: social.youtubeHref,
    name: 'Youtube',
  }, {
    icon: <Tiktok />,
    href: social.tiktokHref,
    name: 'Tiktok',
  }, {
    icon: <Snapchat />,
    href: social.snapchatHref,
    name: 'Snapchat',
  }]
  .filter(({ href }) => href);

  return (
    <>
      <Wrapper {...props}>
        {icons.map(({ href, icon, name }) => (
          <a
            className="hover-target"
            key={href}
            href={(
              href.match(/^https?:\/\//)
              ? href
              : `https://${href}`
            )}
            aria-label={name}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icon}
          </a>
        ))}
      </Wrapper>
      {!!icons.length && withDivider ? <Divider /> : null}
    </>
  );
};

export default SocialIcons;

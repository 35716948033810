const createMockStorage = () => ({
  _data: {},
  setItem(id, val) {
    this._data[id] = String(val);
  },
  getItem(id) {
    return this._data[id];
  },
  removeItem(id) {
    delete this._data[id];
  },
  clear() {
    this._data = {};
  },
});

const storage = Object.assign(
  window.localStorage || createMockStorage()
);

export default storage;
export const session = window.sessionStorage || createMockStorage();

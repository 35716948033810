import React from 'react';

import { createNamedStyled } from '../../../stitches.config';

import { useStore, useStoreCurrency } from '../../../context/Store';
import { useLanguage, useDictionary } from '../../../context/Language';
// import { useCart } from '../../../context/Cart';

import DropSelect from '../../Patterns/DropSelect';

import Types from '../../../modules/types';

const styled = createNamedStyled('NavigationSwitches');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'fit-content',
  gap: '$s',

  variants: {
    mobile: {
      true: {
        padding: '$s',
        width: '100%',
        alignItems: 'flex-start',
      },
    },
  },
});

const StoreBoundLanguageDropSelect = ({
  position,
}) => {
  const { generalLanguage } = useDictionary();
  const store = useStore();
  const currentLanguage = useLanguage();

  if (store.data.languages.length === 1) return null;

  return (
    <>
      <DropSelect
        label={generalLanguage}
        valueStyle="label"
        value={currentLanguage.name}
        onChange={(option) => store.setLanguage(option.value)}
        options={store.data.languages.map((language) => {
          const option = {
            label: Types.LANGUAGES_MAP[language].name,
            value: language,
          };
          return option;
        })}
        align="center"
        position={position || 'bottom'}
      />
    </>
  );
};

const Switches = ({ position, mobile, ...props }) => {
  const { generalCurrency } = useDictionary();
  const { data: store } = useStore();
  // const cart = useCart();
  const [currencyObject, setCurrency] = useStoreCurrency();

  return (
    <Wrapper mobile={mobile} {...props}>
      {store.navigationCurrenciesShow ? (
        <>
          <DropSelect
            label={generalCurrency}
            valueStyle="label"
            value={currencyObject.code}
            onChange={(option) => setCurrency(option.value)}
            options={store.storeCurrencies.map(currency => ({
              label: currency.code,
              value: currency.code,
              description: currency.name,
            }))}
            align="center"
            position={position || 'bottom'}
          />
        </>
      ) : null}
      <StoreBoundLanguageDropSelect position={position} />
    </Wrapper>
  );
};

export default Switches;

/**
 * UTILITY FUNCTIONS
 * -----------------
 */

const getPixelSafeValue = value => (
  // eslint-disable-next-line no-restricted-globals
  !isNaN(value) ? `${value}px` : value
);

const getTimeSafeValue = value => (
  // eslint-disable-next-line no-restricted-globals
  !isNaN(value) ? `${value}ms` : value
);

const getItemsKey = (effect) => (
  Object.keys(effect).find(key => key.endsWith('_items'))
);

const convertToKebabCase = string => string
  .replace(/([a-z])([A-Z])/g, '$1-$2')
  .replace(/[\s_]+/g, '-')
  .toLowerCase();

const convertToCamelCase = string => string
  .toLowerCase()
  .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

/**
 * SETUP
 * -----
 */

// VALUES MAP

const VALUES_MAP = {
  NONE: '$none',
  EXTRA_SMALL: '$xs',
  SMALL: '$s',
  MEDIUM: '$m',
  LARGE: '$l',
  EXTRA_LARGE: '$xl',
  FULL: '$full',
  EASE: '$easeInOutQuint',
  EASE_IN: '$easeInQuint',
  EASE_OUT: '$easeOutQuint',
};

const getValueMapped = value => VALUES_MAP[value] || convertToKebabCase(value);

/**
 * EFFECTS
 * -------
 */

// BACKGROUND

const getEffectBackground = (effect) => {
  const itemsKey = getItemsKey(effect);
  const items = effect[itemsKey];

  const getBackgroundByProperty = (item) => {
    switch (item.type) {
      case 'COLOR':
        return item.color_color;
      case 'GRADIENT':
        switch (item.gradient_type) {
          case 'SIMPLE':
            return [
              'linear-gradient(',
              `${item.gradient_direction}deg, `,
              `${item.gradient_colorStart}, `,
              item.gradient_colorEnd,
              ')'].join('');
          case 'CUSTOM':
            return item.gradient_customValue;
          default:
            return {};
        }
      case 'IMAGE':
        return [
          `url(${item.image_image?.src})`,
          item?.image_repeat ? 'repeat' : 'no-repeat',
          getPixelSafeValue(item?.image_position?.x || 'center'),
          getPixelSafeValue(item?.image_position?.y || 'center'),
          '/',
          getPixelSafeValue(item?.image_sizeCustom)
          || convertToKebabCase(item?.image_size || 'contain'),
          ].join(' ');
      default:
        return {};
    }
  };

  const background = items.reverse().reduce((acc, item) => ([
    ...acc,
    getBackgroundByProperty(item),
  ]), []).join(', ');

  const backgroundBlendMode = items.reduce((acc, item) => ([
    ...acc,
    getValueMapped(item?.blendMode || 'normal'),
  ]), []).join(', ');

  return {
    background,
    backgroundBlendMode,
  };
};

// BORDER

const getEffectBorder = (effect) => {
  const border = [
    getPixelSafeValue(effect.border_width),
    convertToKebabCase(effect.border_style),
    effect.border_color,
  ].join(' ');

  return { border };
};

// BORDER_RADIUS

const getEffectBorderRadius = (effect) => {
  const borderRadius = effect.border_radius_size === 'CUSTOM' ? [
    getPixelSafeValue(effect?.border_radius_custom?.topLeft || 0),
    getPixelSafeValue(effect?.border_radius_custom?.topRight || 0),
    getPixelSafeValue(effect?.border_radius_custom?.bottomRight || 0),
    getPixelSafeValue(effect?.border_radius_custom?.bottomLeft || 0),
  ].join(' ') : getValueMapped(effect.border_radius_size || 'MEDIUM');

  return { borderRadius };
};

// BOX_SHADOW

const getEffectShadow = (effect) => {
  const itemsKey = getItemsKey(effect);
  const items = effect[itemsKey];

  const boxShadow = items.reduce((acc, item) => ([
    ...acc,
    [
      item?.shadow_type === 'INSET' ? 'inset ' : '',
      item?.shadow_size === 'CUSTOM' ? [
        getPixelSafeValue(item?.shadow_custom?.x || 0),
        getPixelSafeValue(item?.shadow_custom?.y || 0),
        getPixelSafeValue(item?.shadow_custom?.blur || 0),
        getPixelSafeValue(item?.shadow_custom?.spread || 0),
        item?.shadow_custom?.color || 'transparent',
      ].join(' ') : getValueMapped(item?.shadow_size || 'MEDIUM'),
    ].join(''),
  ]), []).join(', ');

  return { boxShadow };
};

// TRANSFORM

const getEffectTransform = (effect) => {
  const itemsKey = getItemsKey(effect);
  const items = effect[itemsKey];

  const getTransformByProperty = (item) => {
    switch (item.type) {
      case 'TRANSLATE':
        return [
          'translate(',
            `${getPixelSafeValue(item.translate_x || 0)}, `,
            `${getPixelSafeValue(item.translate_y || 0)}`,
          ')'].join('');
      case 'SCALE':
        return `scale(${item.scale_scale})`;
      case 'ROTATE':
        return `rotate(${item.rotate_rotate}deg)`;
      default:
        return {};
    }
  };

  const transform = items.reduce((acc, item) => ([
    ...acc,
    getTransformByProperty(item),
  ]), []).join(' ');

  const transformOrigin = [
    getPixelSafeValue(effect.transform_origin?.x || 'center'),
    getPixelSafeValue(effect.transform_origin?.y || 'center'),
  ].join(' ');

  return {
    transform,
    transformOrigin,
  };
};

// TRANSITION

const getEffectTransition = (effect) => {
  const itemsKey = getItemsKey(effect);
  const items = effect[itemsKey];

  const transition = items.reduce((acc, item) => {
    const property = convertToKebabCase(item.property);
    const duration = getTimeSafeValue(item.duration || 0);
    const delay = getTimeSafeValue(item.delay || 0);
    const timingFunction = item.easeType === 'AUTO'
    ? '$ease' : item.easeType === 'LINEAR'
    ? 'linear'
    : `$${convertToCamelCase(
        `${item.easeType || 'EASE_OUT'}_${item.easeFunction || 'QUINT'}`
      )}`;
    return `${acc}${property} ${duration} ${delay} ${timingFunction}, `;
  }, '');

  return {
    transition: transition.slice(0, -2),
  };
};

// FILTER

const getEffectFilter = (effect) => {
  const itemsKey = getItemsKey(effect);
  const items = effect[itemsKey];

  const getFilterByProperty = (item) => {
    switch (item.type) {
      case 'BLUR':
        return `blur(${item.blur_value}px)`;
      case 'BRIGHTNESS':
        return `brightness(${item.brightness_value}%)`;
      case 'CONTRAST':
        return `contrast(${item.contrast_value}%)`;
      case 'SATURATION':
        return item.saturation_value >= 100
          ? `saturate(${item.saturation_value}%)`
          : `grayscale(${100 - item.saturation_value}%)`;
      case 'OPACITY':
        return `opacity(${item.opacity_value}%)`;
      default:
        return {};
    }
  };

  const filter = items.reduce((acc, item) => ([
    ...acc,
    getFilterByProperty(item),
  ]), []).join(' ');

  return {
    [effect.filter_type === 'BACKDROP' ? 'backdropFilter' : 'filter']: filter,
  };
};

// COLOR

const getEffectColor = (effect) => ({
  color: effect.color_value,
});

// LETTER SPACING

const getEffectLetterSpacing = (effect) => ({
  letterSpacing: effect.letter_spacing_size === 'CUSTOM'
    ? getPixelSafeValue(effect.letter_spacing_sizeCustom)
    : getValueMapped(effect.letter_spacing_size),
});

// COLOR

const getEffectBlendMode = (effect) => ({
  mixBlendMode: getValueMapped(effect.blend_mode_type),
});

// MASK

const getEffectMask = (effect) => {
  // hack required for current db schema
  const item = effect.mask_items[0];

  switch (item.type) {
    case 'GRADIENT':
      switch (item.gradient_type) {
        case 'SIMPLE':
          return {
            maskImage: [
              'linear-gradient(',
              `${item.gradient_direction}deg, `,
              `${item.gradient_colorStart}, `,
              item.gradient_colorEnd,
              ')',
            ].join(''),
          }
        case 'CUSTOM':
          return {
            maskImage: item.gradient_customValue,
          }
        default:
          return {};
      }
    case 'IMAGE':
      return {
        maskImage: `url(${item.image_image.src})`,
        maskSize: getPixelSafeValue(item?.image_sizeCustom)
          || convertToKebabCase(item?.image_size || 'contain'),
        maskRepeat: item?.image_repeat ? 'repeat' : 'no-repeat',
        maskPosition: 'center',
        // needed as stitches are not adding the above for some reason...
        WebkitMaskRepeat: item?.image_repeat ? 'repeat' : 'no-repeat',
        WebkitMaskPosition: 'center',
      };
    default:
      return {};
  }
};

// ZOOM

const getEffectZoom = (effect) => ({
  zoom: getValueMapped(effect.zoom_value),
});

// WHITE SPACE
const getEffectWhiteSpace = (effect) => ({
  whiteSpace: getValueMapped(effect.white_space_value),
});

/**
 * EFFECTS CONFIG
 * --------------
 */

const EFFECTS = {
  BACKGROUND: getEffectBackground,
  BORDER: getEffectBorder,
  BORDER_RADIUS: getEffectBorderRadius,
  SHADOW: getEffectShadow,
  TRANSFORM: getEffectTransform,
  TRANSITION_FRAME: getEffectTransition,
  TRANSITION_TEXT: getEffectTransition,
  FILTER: getEffectFilter,
  COLOR: getEffectColor,
  LETTER_SPACING: getEffectLetterSpacing,
  BLEND_MODE: getEffectBlendMode,
  MASK: getEffectMask,
  ZOOM: getEffectZoom,
  WHITE_SPACE: getEffectWhiteSpace,
};

/**
 * EFFECTS FUNCTION
 * ----------------
 */

export const getEffects = (nodeEffects = []) => (
  nodeEffects.reduce((acc, effect) => ({
    ...acc,
    ...((effect.type in EFFECTS) ? {
      ...effect.hide.length === 3
        ? {}
          // : {
          // ...effect.hide.length > 0 ? {
          //   [`@!${effect.hide.join('').toLowerCase()}`]:
          //     EFFECTS[effect.type](effect),
          // } : EFFECTS[effect.type](effect),
          // },
        : EFFECTS[effect.type](effect),
    } : {}),
  }), {})
);

/**
 * HOVER EFFECTS
 * -------------
 */

// TARGET

const getHoverTarget = (nodeHoverEffects, nodeHoverTarget) => {
  if (nodeHoverTarget === '') return {};
  return {
    [`${nodeHoverTarget}:hover &`]: getEffects(nodeHoverEffects),
  };
};

// EFFECTS

export const getHoverEffects = (nodeHoverEffects, nodeHoverTarget) => {
  if (nodeHoverEffects === '') return {};
  return {
    '@media (hover: hover)': {
      '&:hover': getEffects(nodeHoverEffects),
      ...getHoverTarget(nodeHoverEffects, nodeHoverTarget),
    },
  };
};

export default getEffects;

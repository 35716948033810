import React from 'react';
import { useTheme } from '../theme';

const GlobalDirection = () => {
  const { language: { direction } } = useTheme();

  return (
    <style>
      {`body { direction: ${direction}; }`}
    </style>
  );
};

export default GlobalDirection;

import React from 'react';
import { ArrowRight, ArrowLeft } from 'react-feather';
import { motion, AnimateSharedLayout } from 'framer-motion';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import { useStore } from '../../../context/Store';
import { useLanguage } from '../../../context/Language';

import { Link } from '../Text';
import LinkWrapper from '../../Patterns/Link';
import DropSelect from '../../Patterns/DropSelect';

const styled = createNamedStyled('NavigationItem');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  gap: '$m',

  variants: {
    mobile: {
      true: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      false: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },

  defaultVariants: {
    mobile: false,
  },
});

const Dot = styled.named('Dot')(motion.div, {
  position: 'absolute',
  width: 6,
  height: 6,
  left: 'calc(50% - 3px)',
  borderRadius: '50%',
  background: '$accent',

  variants: {
    position: {
      top: { top: '-80%' },
      bottom: { bottom: '-80%' },
    },
  },

  '[data-mobile-nav="true"] &': {
    top: 'calc(50% - 3px)',
    bottom: 'unset',
    left: 'calc(-$s * 1.25)',
  },
});

const Underline = styled.named('Underline')(motion.div, {
  position: 'absolute',
  width: '100%',
  height: 2,
  bottom: -5,
  borderRadius: 0,
  background: '$accent',
});

const Box = styled.named('Box')(motion.div, {
  position: 'absolute',
  inset: 'calc(-$xs / 2) -$xs',
  borderRadius: '$m',
  border: '1px solid $accent',
  opacity: 0.75,
  zIndex: 1,

  '&:before': {
    content: '',
    position: 'absolute',
    inset: 0,
    borderRadius: '$m',
    background: '$accent',
    opacity: 0.15,
  },
});

export const getActiveItem = (item, store, language) => {
  const { pathname, href, origin } = window.location;

  if (item.link === '/' && pathname?.includes('/option-')) {
    return true
  }

  return (
    item.link === '/'
      ? store.domains.length
        ? pathname.replace(`/${language}`, '')
          .split('#')[0].split('?')[0] === '/'
          || pathname.replace(`${language}`, '')
            .split('#')[0].split('?')[0] === ''
        : pathname.replace(`/${store.slug}/${language}`, '')
          .split('#')[0].split('?')[0] === '/'
          || pathname.replace(`/${store.slug}/${language}`, '')
            .split('#')[0].split('?')[0] === ''
      : store.domains.length
        ? href.replace(`${origin}/${language}`, '')
          .split('#')[0].split('?')[0]
            === item.link
        : href.replace(`${origin}/${store.slug}/${language}`, '')
          .split('#')[0].split('?')[0]
            === item.link
  );
};

export const NavItem = ({ item, action, ...props }) => {
  const { data: store } = useStore();
  const { _id: language, direction } = useLanguage();

  const {
    navigation,
    transitions: { spring },
  } = useTheme();

  return (
    <LinkWrapper
      key={item.id}
      to={item.link}
      css={{
        position: 'relative',
        width: 'fit-content',

        ...(item.link === '' && {
          '*': { pointerEvents: 'none' },
          pointerEvents: 'none',
          cursor: 'default',
          opacity: 0.35,
        }),
      }}
    >
      <Link
        {...props}
        css={{
          width: 'fit-content',
          ...getActiveItem(item, store, language.toLowerCase()) && {
            color: '$accent',
          },
        }}
      >
        {item.label}
        {!!action && (
          direction === 'RTL'
            ? <ArrowLeft size={16} />
            : <ArrowRight size={16} />
        )}
      </Link>
      {getActiveItem(item, store, language.toLowerCase()) ? (
        <>
          {navigation.activeItemStyle === 'dot' ? (
            <Dot
              layoutId="link-select-dot"
              transition={spring}
              position={navigation.activeItemPosition}
            />
          ) : navigation.activeItemStyle === 'box' ? (
            <Box
              layoutId="link-select-box"
              transition={spring}
            />
          ) : (
            <Underline
              layoutId="link-select-underline"
              transition={spring}
            />
          )}
        </>
      ) : null}
    </LinkWrapper>
  );
};

const Items = ({ items = [], actions, mobile, ...props }) => (
  <AnimateSharedLayout>
    <Wrapper mobile={mobile}>
      {items.map((item) => {
        if (item.items.length > 0) {
          return (
            <DropSelect
              key={item._id}
              hoverable
              valueStyle="label"
              value={<NavItem {...props} item={item} action={actions} />}
              options={item.items.map(option => ({
                _id: option._id || option.link,
                label: option.label,
                value: option.link,
                onClick: props.onClick,
              }))}
              css={{ margin: '-$xs 0' }}
            />
          );
        }

        return (
          <NavItem
            {...props}
            key={item?._id}
            item={item}
            action={actions}
          />
        );
      })}
    </Wrapper>
  </AnimateSharedLayout>
);

export default Items;

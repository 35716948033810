import React from 'react';
import { createNamedStyled } from '../../../stitches.config';

import { useColorOverrides } from '../../../theme';

import { Paragraph } from '../Text';

const styled = createNamedStyled('Announcement');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '10px 15px',
  background: '$backgroundAnnouncement',
});

const Announcement = ({ text, ...props }) => {
  const colorOverrides = useColorOverrides('announcement');
  if (!text) return null;

  return (
    <Wrapper {...props} className={colorOverrides}>
      <Paragraph>
        {text}
      </Paragraph>
    </Wrapper>
  );
};

export default Announcement;

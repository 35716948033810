import React from 'react';
import color from 'color';
import ReactCookieConsent from 'react-cookie-consent';

import { useDictionary } from '../../context/Language';
import { useTheme } from '../../theme';

const CookieConsent = () => {
  const { cookiesTitle, cookiesDescription, cookiesAccept } = useDictionary();
  const { colors, fonts, radii } = useTheme();

  return (
    <ReactCookieConsent
      location="bottom"
      cookieName="packmanCookies"
      expires={365}
      buttonText={cookiesAccept}
      style={{
        background: 'hsla(0, 0%, 0%, 0.8)',
        fontFamily: fonts.text,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      buttonStyle={{
        background: colors.buttonBackground,
        color: color(colors.buttonBackground)
          .luminosity() > 0.5 ? colors.dark : colors.light,
        borderRadius: radii.xs,
        fontSize: 14,
        padding: '6px 12px',
      }}
    >
      <p style={{ fontFamily: fonts.title, fontSize: 24 }}>{cookiesTitle}</p>
      <p style={{ fontSize: 12, opacity: 0.8 }}>{cookiesDescription}</p>
    </ReactCookieConsent>
  );
};

export default CookieConsent;

import React, {
  createContext, useContext, useMemo, useEffect, useState,
} from 'react';

import color from 'color';
import useDarkMode from '@retell/use-dark-mode';
import { useGoogleFonts } from '@flyyer/use-googlefonts';

import { useLocation } from '../context/Location';
import { useApiRequest } from '../context/Api';

import { theme, createTheme } from '../stitches.config';

const StoreThemeContext = createContext({});

export const useTheme = () => useContext(StoreThemeContext);

const HTMLTheme = ({ storeTheme }) => {
  useEffect(() => {
    if (!document.html) {
      document.html = document.querySelector('html');
    }

    document.html.classList.add(storeTheme);

    return () => {
      document.html.classList.remove(storeTheme);
    };
  }, [storeTheme]);

  return null;
}

const DEFAULTS = {
  fonts: {
    en: {
      title: {
        family: 'Montserrat',
        weight: {
          normal: 400,
          bold: 600,
        },
        scaling: 1,
        textTransform: 'none',
        letterSpacing: 0,
      },
      text: {
        family: 'Montserrat',
        weight: {
          normal: 400,
          bold: 600,
        },
        scaling: 1,
        textTransform: 'none',
        letterSpacing: 0,
      },
      label: {
        family: 'Montserrat',
        weight: {
          normal: 400,
          bold: 600,
        },
        scaling: 1,
        textTransform: 'uppercase',
        letterSpacing: '3px',
      },
    },
    ar: {
      title: {
        family: 'Cairo',
        weight: {
          normal: 500,
          bold: 700,
        },
        scaling: 1,
        textTransform: 'none',
        letterSpacing: 0,
      },
      text: {
        family: 'Cairo',
        weight: {
          normal: 500,
          bold: 700,
        },
        scaling: 1,
        textTransform: 'none',
        letterSpacing: 0,
      },
      label: {
        family: 'Cairo',
        weight: {
          normal: 500,
          bold: 700,
        },
        scaling: 1,
        textTransform: 'none',
        letterSpacing: 0,
      },
    },
  },
};

const ROUNDNESS = {
  none: {
    xs: '0px',
    s: '0px',
    m: '0px',
    l: '0px',
    xl: '0px',
    full: '0px',
  },
  rounded: {
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '32px',
    full: '9999px',
  },
  round: {
    xs: '9999px',
    s: '9999px',
    m: '16px',
    l: '32px',
    xl: '64px',
    full: '9999px',
  },
};

const COLOR = {
  light: {
    title: 'hsl(0, 0%, 10%)',
    label: 'hsl(0, 0%, 10%)',
    text: 'hsl(0, 0%, 20%)',
    icon: 'hsl(0, 0%, 10%)',
    shade: 'hsla(0, 0%, 10%, 0.7)',
    border: 'hsla(0, 0%, 10%, 0.5)',
    cover: 'hsla(0, 0%, 10%, 0.05)',
    shadow: 'hsla(0, 0%, 10%, 0.15)',
    accent: '$brandColor',

    buttonBackground: '$brandColor',
    buttonForeground: 'hsl(0, 0%, 100%)',
    dropdownBackground: 'hsl(0, 0%, 100%)',
    dropdownForeground: 'hsl(0, 0%, 10%)',
    notificationBackground: 'hsl(0, 0%, 10%)',
    notificationForeground: 'hsl(0, 0%, 100%)',

    backgroundWebsite: '#fff',
    backgroundNavigation: '#fff',
    backgroundCart: '#fff',
    backgroundCard: '#fff',
    backgroundPageOrder: '#fff',
    backgroundConsent: '#fff',
    backgroundHeader: 'transparent',
    backgroundHeaderSticky: '#fff',
    backgroundFooter: 'transparent',
    backgroundSlider: 'transparent',
    backgroundAnnouncement: '$brandColor',

    loaderStart: '$brandColor',
    loaderEnd: '$brandColor',
  },
  dark: {
    title: 'hsl(0, 0%, 100%)',
    label: 'hsl(0, 0%, 100%)',
    text: 'hsla(0, 0%, 100%, 0.9)',
    icon: 'hsl(0, 0%, 100%)',
    dropdown: 'hsl(0, 0%, 10%)',
    shade: 'hsla(0, 0%, 10%, 0.7)',
    border: 'hsla(0, 0%, 100%, 0.5)',
    cover: 'hsla(0, 0%, 10%, 0.05)',
    shadow: 'hsla(0, 0%, 0%, 0.15)',
    accent: '$brandColor',

    buttonBackground: '$brandColor',
    buttonForeground: 'hsl(0, 0%, 100%)',
    dropdownBackground: 'hsl(0, 0%, 10%)',
    dropdownForeground: 'hsl(0, 0%, 100%)',
    notificationBackground: 'hsl(0, 0%, 100%)',
    notificationForeground: 'hsl(0, 0%, 10%)',

    backgroundWebsite: '#111',
    backgroundNavigation: '#111',
    backgroundCart: '#111',
    backgroundCard: '#111',
    backgroundPageOrder: '#111',
    backgroundConsent: '#111',
    backgroundHeader: 'transparent',
    backgroundHeaderSticky: '#111',
    backgroundFooter: 'transparent',
    backgroundSlider: 'transparent',
    backgroundAnnouncement: '$brandColor',

    loaderStart: '$brandColor',
    loaderEnd: '$brandColor',
  },
};

const ASPECT_RATIO = {
  square: '1',
  portrait: '0.7',
  landscape: '1.3',
};

const OBJECT_FIT = {
  COVER: 'cover',
  CONTAIN: 'contain',
};

const PARALLAX = {
  none: 0,
  medium: 1,
  high: 2,
};

const TRANSITIONS = {
  smooth: {
    timingFunction: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
    spring: { type: 'spring', stiffness: 500, damping: 80 },
    duration: {
      xs: '200ms', s: '400ms', m: '800ms', l: '1200ms', xl: '1600ms',
    },
  },
  snappy: {
    timingFunction: 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
    spring: { type: 'spring', stiffness: 1000, damping: 65 },
    duration: {
      xs: '100ms', s: '200ms', m: '300ms', l: '400ms', xl: '500ms',
    },
  },
  springy: {
    timingFunction: 'cubic-bezier(0.200, 2.000, 0.200, 0.800)',
    spring: { type: 'spring', stiffness: 150, damping: 15, mass: 1.5 },
    duration: {
      xs: '200ms', s: '400ms', m: '800ms', l: '1000ms', xl: '1200ms',
    },
  },
};

const BUTTON_SIZE = {
  small: 'calc($space$s / 1.5) calc($space$m / 1.5)',
  medium: '$space$s $space$m',
  large: 'calc($space$s * 1.5) calc($space$m * 1.5)',
};

const getContrastColor = (value) => (
  color(value).luminosity() > 0.5 ? '$dark' : '$light'
);

function convertColors(data, thm) {
  return data.reduce((acc, curr) => {
    const { context } = curr;
    const { property } = curr;
    const { color: value } = curr;

    if (!acc[context]) { acc[context] = {}; }

    const camelCasedProperty = (property.startsWith('background'))
      ? `${property}${context[0].toUpperCase() + context.slice(1)}`
      : property;

    acc[context][camelCasedProperty] = value;

    if (property.includes('Background')) {
      const valueProperty = value
        .startsWith('$') ? value.slice(1)?.replace(/\d+$/, '') : value
      const valuePercentage = value?.match(/\d+$/)?.[0] || 100;

      const foregroundProperty = property.replace('Background', 'Foreground');
      acc[context][foregroundProperty] = getContrastColor(
        value.startsWith('$')
          ? color(thm[valueProperty]).fade(1 - valuePercentage / 100).string()
          : value,
      );
    }

    if (property.includes('border') || property.includes('accent')) {
      acc[context][`${property}Light`] = color(value || '#000')
        .fade(0.6).string();
    }

    return acc;
  }, {});
}

const StoreThemeProvider = ({
  children,
  data: originalStoreData,
  language,
}) => {
  const location = useLocation();

  const {
    'store-style-overrides': storeStyleOverridesId,
  } = location.searchParams;

  const optionSlugMatch = location.pathname.match(/\/option-\d+/);
  const optionSlug = optionSlugMatch
    // TODO: Revisit this, it should not cause a rerender
    // (though rerender is occuring even without the search params)
    ? `${optionSlugMatch[0]}?store-style-overrides=${storeStyleOverridesId}`
    : null;

  // NOTE: If this is not unique, it won't work on the template chooser (iframes)
  // (and making it generic, doesn't really solve the rerendering issue)
  const sessionStorageKey = `storeStyleOverrides-${storeStyleOverridesId}`;
  const sessionStorageSlugKey = 'homepageOptionSlug';

  const storedOverrides = sessionStorage.getItem(sessionStorageKey);
  const storedSlug = sessionStorage.getItem(sessionStorageSlugKey);
  const [localOverrides, setLocalOverrides] = useState(
    storedOverrides ? JSON.parse(storedOverrides) : null
  );
  const [localSlug, setLocalSlug] = useState(storedSlug);

  const [{ data: storeStyleOverrides }] = useApiRequest({
    url: `stores/${storeStyleOverridesId}/style-overrides`,
    skip: !storeStyleOverridesId || localOverrides,
  });

  useEffect(() => {
    if (storeStyleOverrides && !localOverrides) {
      sessionStorage.setItem(
        sessionStorageKey, JSON.stringify(storeStyleOverrides)
      );
      setLocalOverrides(storeStyleOverrides);
    }

    if (optionSlug && storeStyleOverridesId && !localSlug) {
      sessionStorage.setItem(sessionStorageSlugKey, optionSlug);
      setLocalSlug(optionSlug);
    }
  }, [
    storeStyleOverrides,
    localOverrides,
    sessionStorageKey,
    optionSlug,
    localSlug,
    storeStyleOverridesId,
  ]);

  const data = useMemo(() => {
    const updatedData = {
      ...originalStoreData,
      ...(localOverrides || storeStyleOverrides),
      homePath: localSlug || optionSlug,
    };

    return updatedData;
  }, [
    originalStoreData,
    storeStyleOverrides,
    localOverrides,
    localSlug,
    optionSlug,
  ]);

  const { value: isDarkMode } = useDarkMode();
  const colorTheme = data?.styleTheme === 'auto'
    ? isDarkMode
      ? 'dark'
      : 'light'
    : data?.styleTheme || 'light';

    const storeFonts = useMemo(
      () => ({
        // Title
        titleFamily: data?.styleFontTitle?.family?.replaceAll?.('+', ' ')
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .title.family,
        titleWeightNormal: data?.styleFontTitle?.variantNormal
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .title.weight.normal,
        titleWeightBold: data?.styleFontTitle?.variantBold
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .title.weight.bold,
        titleScaling: data?.styleFontTitle?.scaling
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .title.scaling,
        titleTextTransform: data?.styleFontTitle?.textTransform
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .title.textTransform,
        titleLetterSpacing: data?.styleFontTitle?.letterSpacing
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .title.letterSpacing,
        // Body
        bodyFamily: data?.styleFontBody?.family?.replaceAll?.('+', ' ')
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .text.family,
        bodyWeightNormal: data?.styleFontBody?.variantNormal
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .text.weight.normal,
        bodyWeightBold: data?.styleFontBody?.variantBold
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .text.weight.bold,
        bodyScaling: data?.styleFontBody?.scaling
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .text.scaling,
        bodyTextTransform: data?.styleFontBody?.textTransform
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .text.textTransform,
        bodyLetterSpacing: data?.styleFontBody?.letterSpacing
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .text.letterSpacing,
        // Label
        labelFamily: data?.styleFontLabel?.family?.replaceAll?.('+', ' ')
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .label.family,
        labelWeightNormal: data?.styleFontLabel?.variantNormal
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .label.weight.normal,
        labelWeightBold: data?.styleFontLabel?.variantBold
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .label.weight.bold,
        labelScaling: data?.styleFontLabel?.scaling
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .label.scaling,
        labelTextTransform: data?.styleFontLabel?.textTransform
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .label.textTransform,
        labelLetterSpacing: data?.styleFontLabel?.letterSpacing
          || DEFAULTS.fonts[data?.language || DEFAULTS.lang.key]
            .label.letterSpacing,
      }),
      [data],
    );

    useGoogleFonts([
      {
        family: storeFonts.titleFamily,
        styles: (
            storeFonts.titleWeightNormal === storeFonts.titleWeightBold
          ? [storeFonts.titleWeightNormal]
          : [
              storeFonts.titleWeightNormal,
              storeFonts.titleWeightBold,
            ]
        ),
      },
      {
        family: storeFonts.bodyFamily,
        styles: (
          storeFonts.bodyWeightNormal === storeFonts.bodyWeightBold
          ? [storeFonts.bodyWeightNormal]
          : [
            storeFonts.bodyWeightNormal,
            storeFonts.bodyWeightBold,
          ]
        ),
      },
      {
        family: storeFonts.labelFamily,
        styles: (
          storeFonts.labelWeightNormal === storeFonts.labelWeightBold
          ? [storeFonts.labelWeightNormal]
          : [
            storeFonts.labelWeightNormal,
            storeFonts.labelWeightBold,
          ]
        ),
      },
      {
        family: 'Montserrat',
        styles: [400, 600],
      },
      {
        family: 'Cairo',
        styles: [400, 600],
      },
    ]);

  const brandColor = data?.brandColor || 'hsl(47, 90%, 51%)';
  const accentColor = brandColor;
  const borderColor = COLOR[colorTheme].border;
  const notificationColor = COLOR[colorTheme].notificationBackground;

  const accentColorPrimary = data?.accentColorPrimary
    || color(data?.brandColor).darken(0.2).string() || 'black';
  const accentColorSecondary = data?.accentColorSecondary
    || color(data?.brandColor).lighten(0.4).string() || 'white';

  const themedColorOverrides = {
    light: data?.styleColorsLight,
    dark: data?.styleColorsDark,
  }[colorTheme];

  // TODO: Rework this
  const extendedData = {
    ...data,
    brandColor,
    accentColorPrimary,
    accentColorSecondary,
  };

  const themeParams = {
    colors: {
      // --- Defaults
      ...COLOR[colorTheme],
      // --- Constants
      dark: 'hsl(0, 0%, 10%)',
      light: 'hsl(0, 0%, 100%)',
      // --- Brand
      brandColor,
      brand: brandColor,
      // --- Brand Vaiants
      ...([
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
        55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
      ].reduce((acc, curr) => ({
        ...acc,
        [`brandColor${curr}`]: color(brandColor).fade(1 - curr / 100).string(),
      }), {})),
      // --- Accent Colors
      accentColorPrimary,
      accentColorSecondary,
      // --- Accent Primary Variants
      ...([
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
        55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
      ].reduce((acc, curr) => ({
        ...acc,
        [`accentColorPrimary${curr}`]: color(accentColorPrimary)
          .fade(1 - curr / 100).string(),
      }), {})),
      // --- Accent Secondary Variants
      ...([
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
        55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
      ].reduce((acc, curr) => ({
        ...acc,
        [`accentColorSecondary${curr}`]: color(accentColorSecondary)
          .fade(1 - curr / 100).string(),
      }), {})),
      // --- Adjusted Colors
      buttonBackground: brandColor,
      buttonForeground: color(brandColor)
        .luminosity() > 0.6 ? '$dark' : '$light',
      notificationBackground: notificationColor,
      notificationForeground: color(notificationColor)
        .luminosity() > 0.6 ? '$dark' : '$light',
      border: borderColor,
      borderLight: color(borderColor).fade(0.75).string(),
      accent: accentColor,
      accentLight: color(accentColor).fade(0.5).string(),
      // --- Pages
      backgroundPageOrder: convertColors(themedColorOverrides, extendedData)
        ?.pageOrder?.backgroundPageOrder,
      // --- Website
      ...convertColors(themedColorOverrides, extendedData)?.website,
      websiteOverrides: convertColors(
        themedColorOverrides, extendedData
      )?.website,
    },
    colorOverrides: {
      // --- Defaults
      slider: {
        title: '#fff',
        label: '#fff',
        text: '#fff',
      },
      // --- Overrides
      ...convertColors(themedColorOverrides, extendedData),
    },
    radii: ROUNDNESS[data?.styleRoundness || 'rounded'],
    fonts: {
      title:
        `${storeFonts.titleFamily}, Montserrat, Cairo, Avenir, sans-serif`,
      text:
        `${storeFonts.bodyFamily}, Montserrat, Cairo, Avenir, sans-serif`,
      label:
        `${storeFonts.labelFamily}, Montserrat, Cairo, Avenir, sans-serif`,
    },
    fontWeights: {
      title: storeFonts.titleWeightNormal,
      titleBold: storeFonts.titleWeightBold,
      text: storeFonts.bodyWeightNormal,
      textBold: storeFonts.bodyWeightBold,
      label: storeFonts.labelWeightNormal,
      labelBold: storeFonts.labelWeightBold,
    },
    letterSpacings: {
      title: storeFonts.titleLetterSpacing || 0,
      text: storeFonts.bodyLetterSpacing || 0,
      label: storeFonts.labelLetterSpacing || '3px',
    },
    textTransforms: {
      title: storeFonts.titleTextTransform || 'none',
      text: storeFonts.bodyTextTransform || 'none',
      label: storeFonts.labelTextTransform || 'uppercase',
    },
    transitions: {
      ease: TRANSITIONS[data?.styleTransitionStyle || 'smooth'].timingFunction,
      spring: TRANSITIONS[data?.styleTransitionStyle || 'smooth'].spring,
      xs: TRANSITIONS[data?.styleTransitionStyle || 'smooth'].duration.xs,
      s: TRANSITIONS[data?.styleTransitionStyle || 'smooth'].duration.s,
      m: TRANSITIONS[data?.styleTransitionStyle || 'smooth'].duration.m,
      l: TRANSITIONS[data?.styleTransitionStyle || 'smooth'].duration.l,
      xl: TRANSITIONS[data?.styleTransitionStyle || 'smooth'].duration.xl,
    },
    custom: {
      themeMode: `${colorTheme === 'light' ? 0 : 1}`,
      inlineStart: language?.direction === 'LTR' ? 'left' : 'right',
      inlineEnd: language?.direction === 'LTR' ? 'right' : 'left',
      inlineTranslateStart: language?.direction === 'LTR'
        ? 'translateX(-100%)' : 'translateX(100%)',
      inlineTranslateEnd: language?.direction === 'LTR'
        ? 'translateX(100%)' : 'translateX(-100%)',
      inlineTranslateCenter: language?.direction === 'LTR'
        ? 'translateX(-50%)' : 'translateX(50%)',

      // TODO: move elsewhere
      slug: data?.slug || '',
      domains: data?.domains || [],
      theme: colorTheme || 'light',
      aspectRatio: ASPECT_RATIO[data?.styleImageAspectRatio] || '100%',
      objectFit: OBJECT_FIT[data?.imageSizing] || 'cover',
      imageSizing: OBJECT_FIT[data?.imageSizing] || 'cover',
      parallax: PARALLAX[data?.styleImageParallax] || 0,
      iconsStyle: data?.styleIconsStyle || 'line',
      buttonStyle: data?.styleButtonStyle || 'accent',
      transitionStyle: TRANSITIONS[data?.styleTransitionStyle || 'smooth'],
      lang: data?.language || DEFAULTS.lang.key,
    },
    style: {
      logo: colorTheme === 'dark'
        ? data?.logoDark?.src : data?.logo?.src || data?.logo?.src || '',
      imageAspectRatio: ASPECT_RATIO[data?.styleImageAspectRatio] || '100%',
      imageSizing: OBJECT_FIT[data?.imageSizing] || 'cover',
      iconsStyle: data?.styleIconsStyle || 'line',
      buttonStyle: data?.styleButtonStyle || 'accent',
      transitionStyle: TRANSITIONS[data?.styleTransitionStyle || 'smooth'],
      // - - -
      buttonSize: BUTTON_SIZE[data?.styleButtonSize || 'medium'],
    },
    gallery: {
      thumbnails: data?.galleryThumbnails,
      dotNavigation: data?.galleryDotNavigation,
      arrowsNavigation: data?.galleryArrowsNavigation,
      overflow: data?.galleryOverflow.toLowerCase() || 'faded',
      zoom: data?.galleryZoom === 'IN_PLACE',
      fullscreen: data?.galleryZoom === 'FULLSCREEN',
    },
    navigation: {
      style: data?.navigationStyle || 'full',
      alignment: data?.navigationAlignment || 'regular',
      position: data?.navigationPosition || 'static',
      activeItemStyle: data?.navigationActiveItemStyle || 'dot',
      activeItemPosition: data?.navigationActiveItemPosition || 'top',
      currency: data?.navigationCurrenciesShow || true,
      mobileShow: data?.navigationMobileNavigationShow || 'auto',
      mobileStyle: data?.navigationMobileNavigationStyle || 'side',
    },
    shop: {
      cartAlignment: data?.shopCartAlignment === 'side' ? 'flex-end' : 'center',
    },
    language: {
      direction: language?.direction.toLowerCase() || 'ltr',
      language: language?.key || language?._id || 'en',
    },
    legal: {
      strictTermsAndConditions: data?.strictTermsAndConditions,
    },
    homePath: data?.homePath || '/',
  };

  const storeTheme = createTheme(themeParams);

  return (
    <StoreThemeContext.Provider value={{ ...theme, ...themeParams }}>
      {data?.styleCss ? <style>{data?.styleCss}</style> : null}
      <HTMLTheme storeTheme={storeTheme} />
      <div className={storeTheme}>
        {children}
      </div>
    </StoreThemeContext.Provider>
  );
};

export default StoreThemeProvider;

export const useColorOverrides = (component, page) => {
  const { colors, colorOverrides } = useTheme();

  const overrides = createTheme({
    colors: {
      // --- Colors
      ...colorOverrides?.[component],
      // --- Page Background
      ...(!!page && {
        backgroundWebsite: colors[`backgroundPage${
          component[0].toUpperCase() + component.slice(1)
        }`],
      }),
    },
  });

  return overrides;
};

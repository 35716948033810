import {
  stringify as stringifyQuery,
} from 'querystring';

import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import Helmet from 'react-helmet';

import { Redirect } from 'react-router-dom';

import { useLocation } from '../../context/Location';
import { Provider as StoreProvider, useStore } from '../../context/Store';

import {
  Provider as AnalyticsProvider,
  useAnalytics,
} from '../../context/Analytics';
import { Provider as OrderProvider, useOrder } from '../../context/Order';
import {
  KEY_CART_ITEMS,
  KEY_CART_OPEN,
  KEY_SUBMIT_FIELDS,
} from '../../context/Cart';

import {
  StoreBoundLanguageProvider,
  useLanguage,
} from '../../context/Language';

import StoreThemeProvider from '../../theme';

import Content from './Content';

import Types from '../../modules/types';

import faviconSrc from '../../helpers/faviconSrc';

import GlobalDirection from '../../helpers/GlobalDirection';
import GlobalBackgroundColor from '../../helpers/GlobalBackgroundColor';

const {
  CONSTANTS: {
    OrderStatus,
  },
} = Types;

function Order({ store, order, ...props }) {
  const { pathname, searchParams: oldSearchParams } = useLocation();
  const analytics = useAnalytics();
  const isJustSubmitted = useMemo(
    () => `${oldSearchParams?.submitted}` === 'true',
    [oldSearchParams.submitted],
  );
  // eslint-disable-next-line no-unused-vars, prefer-const
  let [search, searchParams] = useMemo(
    () => {
      const newSearchParams = { ...(oldSearchParams || {}) };
      delete newSearchParams.submitted;
      const newSearchString = stringifyQuery(newSearchParams);
      return [
        newSearchString ? `?${newSearchString}` : newSearchString,
        newSearchParams,
      ];
    },
    [oldSearchParams],
  );
  const { currency: systemCurrency } = (
      store.data
    ? Types.getSystemCountry(store.data.systemCountry)
    : {}
  );
  const [redirect, setRedirect] = useState(null);
  useEffect(
    () => {
      if (!store.data || !order.data) {
        return;
      }
      const { status } = order.data;
      switch (true) {
        case [
          OrderStatus.DRAFT,
        ].includes(status): {
          // try {
          //   store.setData(KEY_CART_ITEMS, order.data.items.map(item => ({
          //     sku: item.sku,
          //     quantity: item.quantity || 1,
          //   })));
          //   store.setData(KEY_CART_OPEN, true);
          //   setRedirect(`${store.getUrl()}${search}`);
          // } catch (error) {
          //   console.log('error on draft', error);
          // }
          // return <Redirect to={`${store.getUrl()}${search}`} />;
          return;
        }
        case [
          OrderStatus.INVALID,
          // OrderStatus.INVALID_PAYMENT,
          OrderStatus.INVALID_SHIPPING,
        ].includes(status):
          setRedirect(`${store.getUrl()}${search}`);
          // return <Redirect to={`${store.getUrl()}${search}`} />;
          return;
        default: {
          // OrderStatus.PAYMENT,
          // OrderStatus.APPROVAL
          // OrderStatus.INVALID_APPROVAL,
          // OrderStatus.NEW,
          // OrderStatus.DELIVERY,
          // OrderStatus.DELIVERED,
          // OrderStatus.FAILED,
          // all good, show order
          if (isJustSubmitted) {
            if (
              [
                'APPROVAL',
                'NEW',
                'DELIVERY',
                'DELIVERED',
              ].includes(status)
            ) {
              analytics.trigger.ORDER_SUBMIT_SUCCESS({
                order: order.data,
                currency: systemCurrency,
              });
            }
            setRedirect(`${store.getUrl(`${pathname}`, 'order')}${search}`);
          } else {
            setRedirect(null);
          }
        }
      }
    },
    [
      pathname,
      search,
      analytics,
      isJustSubmitted,
      order.data,
      store,
      systemCurrency,
    ],
  );

  useEffect(() => {
    let interval = null;
    if (
      order?.data?.payment?.provider === 'STRIPE'
      && order?.data?.status === 'PAYMENT'
    ) {
      interval = setInterval(() => {
        if (!order.loading) {
          order.getOrder()
          .then(() => {})
          // eslint-disable-next-line no-console
          .catch((error) => console.log(error))
        }
      }, 1000)
    }
    return () => { clearInterval(interval); };
  }, [order])

  if (!store.data || !order.data) {
    return null;
  }
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  if (order.data.status === 'DRAFT') {
    if (order.data.draftPurpose !== 'POS') {
      store.setData(KEY_CART_ITEMS, order.data.items.map(item => ({
        sku: item.sku,
        price: item.price,
        meta: {
          name: item.name,
          storeSku: item.storeSku,
          variation: '',
          tagline: '',
          image: (item.image || {}).src,
          options: item.options || [],
          // categoryName: categoryPopulated.name || 'Product',
        },
        quantity: item.quantity || 1,
        // sku: item.sku,
        // quantity: item.quantity || 1,
      })));
    }
    if (order.data.isAbandonedCart) {
      if (order.data.firstName) {
        const submitFields = store.getData(KEY_SUBMIT_FIELDS) || {};
        const updates = {};
        [
          'name',
          'firstName',
          'lastName',
          'email',
          'phoneNumberString',
        ].forEach((key) => {
          if (
            // Overwrite with saved fields
            order.data[key]?.trim?.()?.length
            // Only set if not set already
            // !submitFields[key]
            // && order.data[key]?.trim?.()?.length
          ) {
            updates[key] = order.data[key];
          }
        });
        if (Object.keys(updates).length) {
          store.setData(KEY_SUBMIT_FIELDS, {
            ...submitFields,
            ...updates,
          });
        }
      }
    }
    search = `?${stringifyQuery({
      ...searchParams,
      ...(
        order.data.draftPurpose === 'POS'
        ? { pos: order.data._id }
        : {}
      ),
    })}`;
    store.setData(KEY_CART_OPEN, true);
    return (
      <Redirect
        to={`${store.getUrl()}${search}`}
      />
    );
  }
  return (
    <Content
      {...props}
      isPosCheckout={order.data.draftedFromPurpose === 'POS'}
      isSelfPickup={
        ['SELF_PICKUP', 'POS'].includes(order.data.shipping.method)
      }
    />
  );
}

function BoundOrder(props) {
  const order = useOrder();
  const store = useStore();
  const language = useLanguage();

  if (!order.data || store.loading || !store.data) {
    return null;
  }

  return (
    <StoreThemeProvider data={store.data} language={language}>
      <Helmet>
        <link
          id="favicon"
          rel="icon"
          href={
              store.data.favicon?.src
            ? store.data.favicon.src
            : faviconSrc
          }
        />
        {store.data.favicon && store.data.favicon.src && (
          <link id="favicon" rel="icon" href={store.data.favicon.src} />
        )}
        <title>
          {`${store.data.name} | #${order.data._id}`}
        </title>
      </Helmet>
      <GlobalDirection />
      <GlobalBackgroundColor page="order" />
      <Order
        {...props}
        store={store}
        order={order}
      />
    </StoreThemeProvider>
  );
}

function BoundStoreProvider({ children, isCustomDomain }) {
  const { data } = useOrder();
  if (!data) {
    return null;
  }
  return (
    <StoreProvider
      id={data.store}
      mode="order"
      referral={false}
      isCustomDomain={isCustomDomain}
      useCustomDomain={data.status !== 'PAYMENT'}
    >
      <AnalyticsProvider>
        {children}
      </AnalyticsProvider>
    </StoreProvider>
  );
}

export default ({ orderId, isCustomDomain, ...props }) => (
  <OrderProvider id={orderId} isCustomDomain={isCustomDomain}>
    <BoundStoreProvider isCustomDomain={isCustomDomain}>
      <StoreBoundLanguageProvider>
        <BoundOrder {...props} />
      </StoreBoundLanguageProvider>
    </BoundStoreProvider>
  </OrderProvider>
);

import React, { forwardRef, cloneElement } from 'react';

import { createStitches } from '@stitches/react';
import { modularScale as polishedModularScale, em, rem } from 'polished';

const REPORT_UNNAMED = true;

const NAME_KEY = Symbol('NAME');
const NAME_GLUE = '';
const NAME_PROP = 'data-pcn';
const NAME_RUNTIME_PROP = '$$name';

// const modularScaleText = (scale, base = em(20), ratio = 1.5) => (
const modularScaleText = (scale, base = em(22), ratio = 1.5) => (
  polishedModularScale(scale, base, ratio)
);

const modularScaleSpace = (scale, base = em(14), ratio = 1.75) => (
  polishedModularScale(scale, base, ratio)
);

const modularScaleLetterSpacing = (scale, base = em(2), ratio = 2) => (
  polishedModularScale(scale, base, ratio)
);

const modularScaleRadii = (scale, base = '2px', ratio = 2) => (
  polishedModularScale(scale, base, ratio)
);

export const {
  styled: styledOriginal,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      brand: 'hsl(47, 90%, 51%)',
      error: 'hsl(0, 91%, 56%)',
      title: 'hsl(0, 0%, 10%)',
      titleBold: 'hsl(0, 0%, 10%)',
      text: 'hsla(0, 0%, 10%, 0.7)',
      textBold: 'hsla(0, 0%, 10%, 1)',
      label: 'hsl(0, 0%, 0%)',
      labelBold: 'hsla(0, 0%, 0%)',
      background: '#FFFFFF',
      card: '#FFFFFF',
    },
    space: {
      xs: modularScaleSpace(0),
      s: modularScaleSpace(1),
      m: modularScaleSpace(2),
      l: modularScaleSpace(3),
      xl: modularScaleSpace(4),
    },
    sizes: {
      siteWidth: '1480px',
      screenWidth: '100dvw',
      screenHeight: '100dvh',

      xs: modularScaleSpace(0),
      s: modularScaleSpace(1),
      m: modularScaleSpace(2),
      l: modularScaleSpace(3),
      xl: modularScaleSpace(4),
    },
    fonts: {
      title: 'Avenir Next, apple-system, sans-serif',
      label: 'Avenir Next, apple-system, sans-serif',
      text: 'Avenir Next, apple-system, sans-serif',
    },
    fontSizes: {
      title: modularScaleText(3),
      text: rem('16px'),
      label: rem('15px'),
      copy: rem('14px'),

      xs: modularScaleText(0),
      s: modularScaleText(1),
      m: modularScaleText(2),
      l: modularScaleText(3),
      xl: modularScaleText(4),

      h1: modularScaleText(4),
      h2: modularScaleText(3),
      h3: modularScaleText(2),
      h4: modularScaleText(1),
      h5: modularScaleText(0),
    },
    fontWeights: {
      title: 400,
      titleBold: 700,
      text: 400,
      textBold: 600,
      label: 400,
      labelBold: 600,
    },
    lineHeights: {
      title: '120%',
      label: '140%',
      text: '160%',
    },
    letterSpacings: {
      title: 0,
      text: 0,
      label: modularScaleLetterSpacing(1),
      s: modularScaleLetterSpacing(0),
      m: modularScaleLetterSpacing(1),
      l: modularScaleLetterSpacing(2),
    },
    radii: {
      xs: modularScaleRadii(0),
      s: modularScaleRadii(1),
      m: modularScaleRadii(2),
      l: modularScaleRadii(3),
      xl: modularScaleRadii(4),
      full: '9999px',
    },
    shadows: {
      xs: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
      s: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
      m: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
      l: '0 0 30px 0 rgba(0, 0, 0, 0.2)',
      xl: '0 0 40px 0 rgba(0, 0, 0, 0.2)',
    },
    zIndices: {
      hide: '-1',
      auto: 'auto',
      base: '0',
      docked: '10',
      dropdown: '100',
      sticky: '1000',
      banner: '10000',
      overlay: '100000',
      modal: '1000000',
      popover: '10000000',
      skipLink: '100000000',
      toast: '1000000000',
      tooltip: '10000000000',
    },
    transitions: {
      linear: 'linear',
      easeIn: 'ease-in',
      easeOut: 'ease-out',
      easeInOut: 'ease-in-out',
      easeInSine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
      easeOutSine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
      easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
      easeInQuad: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
      easeOutQuad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
      easeInOutQuad: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      easeInCubic: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
      easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      easeInQuart: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
      easeOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
      easeInOutQuart: 'cubic-bezier(0.77, 0, 0.175, 1)',
      easeInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
      easeInOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
      easeInExpo: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
      easeOutExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
      easeInOutExpo: 'cubic-bezier(1, 0, 0, 1)',
      easeInCirc: 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
      easeOutCirc: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
      easeInOutCirc: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
      easeInBack: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
      easeOutBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
      easeInOutBack: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
    },
    custom: {
      aspectRatio: '100%',
      objectFit: 'cover',
      style: 'classic', // classic | modern | minimal | elegant | funky | glitchy
    },
    media: {
      mobile: '(max-width: 540px)',
      tablet: '(min-width: 541px) and (max-width: 1024px)',
      desktop: '(min-width: 1025px)',

      handheld: '(max-width: 1024px)',
      notMobile: '(min-width: 541px)',

      'mobile+': '(min-width: 541px)',
      'mobile-': '(max-width: 540px)',
      'phablet+': '(min-width: 541px)',
      'phablet-': '(max-width: 540px)',
      'tablet+': '(min-width: 769px)',
      'tablet-': '(max-width: 768px)',
      'desktop+': '(min-width: 1025px)',
      'desktop-': '(max-width: 1024px)',
      'medium+': '(min-width: 1280px)',
      'medium-': '(max-width: 1279px)',
      'wide+': '(min-width: 1921px)',
      'wide-': '(max-width: 1920px)',
    },
  },
  media: {
    mobile: '(max-width: 540px)',
    tablet: '(min-width: 541px) and (max-width: 1024px)',
    desktop: '(min-width: 1025px)',

    handheld: '(max-width: 1024px)',
    notMobile: '(min-width: 541px)',

    'mobile+': '(min-width: 541px)',
    'mobile-': '(max-width: 540px)',
    'phablet+': '(min-width: 541px)',
    'phablet-': '(max-width: 540px)',
    'tablet+': '(min-width: 769px)',
    'tablet-': '(max-width: 768px)',
    'desktop+': '(min-width: 1025px)',
    'desktop-': '(max-width: 1024px)',
    'medium+': '(min-width: 1280px)',
    'medium-': '(max-width: 1279px)',
    'wide+': '(min-width: 1921px)',
    'wide-': '(max-width: 1920px)',
  },
});

const UNNAMED_COMPONENTS_TRACES_MAP = {};
const UNNAMED_COMPONENTS_TRACES = [];

export const styled = (
  process.env.NODE_ENV === 'development'
  ? (...args) => {
      if (REPORT_UNNAMED) {
        const error = new Error();
        const stackTraceLine = error.stack.split('\n')[3];
        if (!UNNAMED_COMPONENTS_TRACES_MAP[stackTraceLine]) {
          UNNAMED_COMPONENTS_TRACES.push(stackTraceLine);
          UNNAMED_COMPONENTS_TRACES_MAP[stackTraceLine] = 1;
        } else {
          UNNAMED_COMPONENTS_TRACES_MAP[stackTraceLine]++;
        }
        clearTimeout(UNNAMED_COMPONENTS_TRACES.timeout);
        UNNAMED_COMPONENTS_TRACES.timeout = setTimeout(
          () => {
            // eslint-disable-next-line no-console
            console.log(`Error: Unnamed stitches components\n${
              UNNAMED_COMPONENTS_TRACES
              .map(line => `${
                line
              } (${
                UNNAMED_COMPONENTS_TRACES_MAP[line]
              })`)
              .join('\n')
            }`);
          },
          500,
        );
      }
      return styledOriginal(...args);
    }
  : (...args) => styledOriginal(...args)
);

styled[NAME_KEY] = [];

styled.named = function getStyledNamed(name, prepend = false) {
  const styledNamed = (...args) => {
    const StyledComponent = styledOriginal(...args);
    const nameFinal = styledNamed[NAME_KEY].join(NAME_GLUE);
    return forwardRef((
      {
        [NAME_RUNTIME_PROP]: nameRuntimeProp,
        ...props
      },
      ref,
    ) => cloneElement(
      <StyledComponent ref={ref} {...props} />,
      { [NAME_PROP]: nameRuntimeProp || nameFinal },
    ));
  };
  styledNamed.named = this.named;
  styledNamed.unnamed = styledOriginal;
  if (prepend === null) {
    styledNamed[NAME_KEY] = [name];
  } else if (prepend) {
    styledNamed[NAME_KEY] = [name, ...this[NAME_KEY]];
  } else {
    styledNamed[NAME_KEY] = [...this[NAME_KEY], name];
  }
  return styledNamed;
};

styled.unnamed = styledOriginal;

export const createNamedStyled = styled.named.bind(styled);

export const Styled = styledOriginal('div', {});

import Tracker from './Tracker';

// import Types from '../../../modules/types';

// const { ANALYTICS_EVENTS } = Types;

export default class TrackerSimpleAnalytics extends Tracker {
  static NAME = 'SimpleAnalytics';

  setup({ store } = {}) {
    let script = document.getElementById('simple-analytics-script');
    if (
         store?.data?.integrations?.simpleAnalytics?.supported
      && store?.data?.integrations?.simpleAnalytics?.hostname
    ) {
      const { simpleAnalytics } = store.data.integrations;
      window.simpleAnalyticsPathOverwriter = ({ path } = {}) => {
        if (
          store
          && store.data
          && !store.isCustomDomain
          && path.startsWith(`/${store.data.slug}`)
        ) {
          path = path.replace(`/${store.data.slug}`, '') || '/';
        }
        return path;
      };
      if (!script) {
        script = document.createElement('script');
        if (process.env.REACT_APP_DXTOOLS_ENV === 'production') {
          script.src = 'https://sa.packman.app/latest.js';
        } else {
          script.src = `https://scripts.simpleanalyticscdn.com/latest${
            window.location.hostname === 'localhost'
            ? '.dev'
            : ''
          }.js`;
        }
        script.id = 'simple-analytics-script';
        script.dataset.hostname = simpleAnalytics.hostname;
        script.dataset.pathOverwriter = 'simpleAnalyticsPathOverwriter';
        script.dataset.strictUtm = false;
        script.dataset.allowParams = 'category,search';
        script.dataset.nonUniqueHostnames = 'api2.checkout.com';
        script.dataset.ignorePages = '/order/*';
        document.head.appendChild(script);
        this.active = true;
      } else if (
        script
        && (
          script.dataset.hostname
          !== simpleAnalytics?.hostname
        )
      ) {
        window.location.reload();
      } else {
        this.active = true;
      }
    } else if (script) {
      window.location.reload();
    }
  }
}

/* global Frames */

import React, { Component, forwardRef } from 'react';
import { transparentize } from 'polished';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import { useDictionary } from '../../../context/Language';

let DID_INIT = false;

const EVENTS = [
  'cardSubmitted',
  'cardTokenizationFailed',
  'cardTokenized',
  'cardValidationChanged',
  'frameActivated',
  'frameBlur',
  'frameFocus',
  'frameValidationChanged',
  'paymentMethodChanged',
  'ready',
].map((event) => ({
  prop: `on${event[0].toUpperCase()}${event.slice(1)}`,
  event,
}));

const styled = createNamedStyled('Checkout');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

const Container = styled.named('Container')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  aspectRatio: '1.6 / 1',
  overflow: 'hidden',
  padding: '$xs',
  borderRadius: '$l',
  border: '1px solid $borderLight',

  width: 'calc(100% + 2 * $space$xs)',
  maxWidth: '400px',
  marginInline: '-$xs',

  '&:before, &:after': {
    content: '',
    position: 'absolute',
    top: 0,
    insetInlineEnd: 0,
    borderEndStartRadius: '$radii$l',
    transformOrigin: '100% 0%',
  },

  '&:before': {
    width: '70%',
    height: '55%',
    transition: 'transform $l $ease, background $m, opacity $m',
    background: '$accent',
    opacity: 0.5,
  },

  '&:after': {
    width: '30%',
    height: '30%',
    transition: 'transform $m $ease, background $m',
    background: '$accent',
  },

  transition: 'border-color $s',
  '@media (hover: hover)': { '&:hover': {
    borderColor: '$border',

    '&:before': { transform: 'scale(1.2, 1.1)' },
    '&:after': { transform: 'scale(1.1, 1.2)' },
  } },

  '&.frame--invalid': {
    borderColor: '$error',

    '&:before': { background: '$error', opacity: 0.5 },
    '&:after': { background: '$error' },
  },

  iframe: { height: '40px !important' },
  '& > *': { filter: 'grayscale(1) !important' },
  // '& img': { filter: 'grayscale(1) !important' },

  'input::-webkit-autofill': {
    boxShadow: '0 0 0 1000px $backgroundCart inset',
  },
});

const Top = styled.named('Top')('div', {
  padding: '10px',
});

const Line = styled.named('Line')('div', {
  width: '100%',
  height: '1px',
  background: '$borderLight',
});

class InputCheckoutCardComponent extends Component {
  constructor(props) {
    super(props);
    this.eventHandlers = EVENTS.map(({ event, prop }) => ({
      event,
      callback: (ev) => this.props[prop] && this.props[prop](ev),
    }));
  }

  async getFrames(forceReinit = false) {
    const el = document.querySelector('[data-frames-wrapper="true"]');
    if (el) {
      if (!DID_INIT || forceReinit) {
        DID_INIT = true;
        Frames.init({
          localization: this.props.localization,
          publicKey: this.props.publicKey,
          style: {
            base: {
              color: this.props.colors.text,
            },
            focus: {
              color: this.props.colors.text,
            },
            valid: {
              color: this.props.colors.text,
            },
            invalid: {
              color: this.props.colors.error,
            },
            autofill: {
              backgroundColor: this.props.colors.backgroundCart,
            },
            placeholder: {
              base: {
                color: transparentize(0.4, this.props.colors.text),
              },
            },
          },
        });
      }
      return Frames;
    }
    return new Promise(
      (resolve) => setTimeout(resolve, 10),
    ).then(
      () => this.getFrames(),
    );
  }

  async componentDidMount() {
    const frames = await this.getFrames();
    this.eventHandlers.forEach(
      ({ event, callback }) => frames.addEventHandler(event, callback),
    );
    frames.render();
  }

  async componentWillUnmount() {
    const frames = await this.getFrames();
    this.eventHandlers.forEach(
      ({ event }) => frames.removeAllEventHandlers(event),
    );
  }

  async componentDidUpdate(previousProps) {
    if (
      previousProps.publicKey !== this.props.publicKey
      || previousProps.localization !== this.props.localization
    ) {
      await this.getFrames(true);
    }
  }

  render() {
    return (
      <Wrapper>
        <Container
          className={`card-frame${
            this.props.className ? ` ${this.props.className}` : ''
          }`}
          data-frames-wrapper="true"
          css={this.props.style}
        >
          <Top>
            {/* <Input label="Cardholder Name" value="" /> */}
            <Line />
          </Top>
        </Container>
      </Wrapper>
    );
  }
}

const InputCheckoutCard = forwardRef((props, ref) => {
  const { colorOverrides, colors } = useTheme();

  const { checkoutComLocalization } = useDictionary();
  return (
    <InputCheckoutCardComponent
      ref={ref}
      colors={
        colorOverrides?.cart
        ? {
          ...colors,
          text: colorOverrides.cart.text || colors.text,
          backgroundCart: colorOverrides.cart.backgroundCart
          || colors.backgroundCart,
        }
        : colors
      }
      localization={checkoutComLocalization}
      {...props}
    />
  );
});

export default InputCheckoutCard;

import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { useStore } from '../context/Store';

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const MotionA = forwardRef((
  {
    navigate,
    onClick,
    ...props
  },
  // eslint-disable-next-line no-unused-vars
  ref,
) => {
  const { target } = props;
  return (
    <motion.a
      {...props}
      onClick={(event) => {
        try {
          if (onClick) onClick(event);
        } catch (ex) {
          event.preventDefault();
          throw ex;
        }

        if (
            !event.defaultPrevented // onClick prevented default
          && event.button === 0 // ignore everything but left clicks
          && (!target || target === '_self') // let browser handle "target=_blank" etc.
          && !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault();
          navigate();
        }
      }}
    />
  );
});

const StoreDefinedLink = forwardRef(({
  to = '',
  embla,
  ...props
}, ref) => {
  const { data: store, getUrl } = useStore();
  if (!store) {
    return null;
  }

  if (to && to[0] === '/') {
    const url = getUrl(to);
    return (
      <Link
        ref={ref}
        {...props}
        component={MotionA}
        to={url}
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-target-blank, jsx-a11y/anchor-has-content
  return (
    <motion.a
      ref={ref}
      {...props}
      href={to}
      target="_blank"
    />
  );
});

export default StoreDefinedLink;

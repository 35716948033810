import React, { useContext } from 'react';
import { createNamedStyled } from '../../../stitches.config';

import Notification from '../Notification';
import CartContext from '../../../context/Cart';

import { Cart } from '../../../icons';
import { Label } from '../Text';

const styled = createNamedStyled('CartTrigger');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  width: 'fit-content',
  cursor: 'initial',
  pointerEvents: 'none',
  opacity: 0.3,
  transition: 'opacity $m',

  padding: '$xs',
  margin: '-$xs',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$s',

  '.notification': {
    transition: 'transform $m $ease',
  },

  '&:hover .notification': {
    transform: 'translateY(-5px)',
  },
});

function Button({ label, css, onClick, ...props }) {
  const { toggleShowCart, quantityTotal } = useContext(CartContext);
  const hasItems = quantityTotal > 0;

  return (
    <Wrapper
      {...props}
      className="hover-target"
      onClick={() => {
        toggleShowCart();
        if (onClick) { onClick(); }
      }}
      css={{
        ...hasItems && {
          cursor: 'pointer',
          pointerEvents: 'all',
          opacity: 1,
        },
        ...css,
      }}
    >
      <Cart />
      <Notification
        className="notification"
        value={quantityTotal}
        subtle
        css={{ insetInlineEnd: 'auto', insetInlineStart: 32 }}
      />
      {label ? <Label>{label}</Label> : null}
    </Wrapper>
  );
}

export default Button;

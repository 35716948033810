import React from 'react';

import { createNamedStyled, keyframes } from '../../stitches.config';

import { Title, Paragraph } from './Text';

const styled = createNamedStyled('NotFound');

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '100px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,

  opacity: 0,
  animation: `${fadeIn} 0.5s 0.5s ease forwards`,
});

export default function NotFound({
  title = '404',
  message = 'Page not found',
}) {
  return (
    <Wrapper>
      <Title bold size="l">
        {title}
      </Title>
      <Paragraph>
        {message}
      </Paragraph>
    </Wrapper>
  );
}

import React, { forwardRef, useMemo } from 'react';

import LinkPattern from '../../components/Patterns/Link';

const Link = forwardRef(({ children, effect, to, node, ...props }, ref) => {
  const child = React.Children.only(children);
  const finalCss = useMemo(
    () => ({
      ...props.css,
      ...child.props.css,
    }),
    [props.css, child?.props?.css],
  );

  return React.cloneElement(child, {
    ...child.props,
    ...props,
    ref,
    as: LinkPattern,
    css: finalCss,
    to: effect.link_value,
    className: node.className,
  });
})

const LinkWrapper = forwardRef(({ children, effect, ...props }, ref) => {
  const child = React.Children.only(children);

  const finalCss = useMemo(
    () => ({
      ...(props?.css || {}),
      ...(child?.props?.css || {}),
    }),
    [props.css, child?.props?.css],
  );

  if (effect) {
    return (
      <Link
        ref={ref}
        effect={effect}
        {...props}
        className={child?.props?.className}
      >
        {children}
      </Link>
    )
  }

  return React.cloneElement(children, {
    ref,
    ...child.props,
    ...props,
    css: finalCss,
  });
})

export default LinkWrapper;

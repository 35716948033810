import React, { Fragment, forwardRef, useRef, useMemo, useEffect } from 'react';
// import isObject from 'lodash/isObject';
// import { getLuminance } from 'polished';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { useDraggable } from 'react-use-draggable-scroll';
import { useController } from 'react-hook-form';

import { createNamedStyled } from '../../stitches.config';
import { useTheme } from '../../theme';

import { Label, Paragraph } from './Text';

const styled = createNamedStyled('ButtonGroup');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  padding: '$s',
  borderRadius: '$s',
  gap: '$m',

  marginInline: '-$xs',

  overflowX: 'scroll',
  WebkitOverflowScrolling: 'touch',

  border: '1px solid $borderLight',
  transition: 'border-color $s',
  '@media (hover: hover)': {
    '&:hover': { borderColor: '$border' },
  },

  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
});

const Option = styled.named('Option')('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  cursor: 'pointer',
  zIndex: 1,
});

const Content = styled.named('Content')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  zIndex: 1,

  width: '100%',

  '*': {
    whiteSpace: 'nowrap',
    transition: 'color $s',
  },

  opacity: 0.5,
  transition: 'opacity $s',
  '@media (hover: hover)': {
    '&:hover': { opacity: 1 },
  },

  backfaceVisibility: 'hidden',
  transform: 'translate3d(0, 0, 0)',
  perspective: 1000,
  fontSmoothing: 'antialiased',
});

const Selector = styled.named('Selector')(motion.div, {
  position: 'absolute',
  inset: '-$xs',
  background: '$buttonBackground',
  zIndex: -1,
  borderRadius: '$s',
});

const Divider = styled.named('Divider')('span', {
  margin: '0 $xs',
  borderRight: '1px solid $buttonForeground',
  transition: 'border-color $s',
  opacity: 0.4,
});

const ButtonGroup = forwardRef(
  // eslint-disable-next-line no-unused-vars
  (
    {
      value,
      onChange,
      options,
      field,
      condensed,
      noContentPlaceholder,
      ...props
    },
    inputRef,
  ) => {
    const { transitions: { spring } } = useTheme();

    const ref = useRef();
    const { events } = useDraggable(ref, { applyRubberBandEffect: true });
    const optionsWithRefs = useMemo(
      () => options.map(option => ({
        ...option,
        ref: React.createRef(),
      })),
      [options],
    );
    useEffect(
      () => {
        const optionIndex = optionsWithRefs.findIndex(option => (
          option.value === value
        ));
        if (optionIndex > -1) {
          const optionSelected = optionsWithRefs[optionIndex];
          const offset = optionsWithRefs?.[0]?.ref?.current?.offsetLeft;
          if (ref.current && optionSelected?.ref?.current) {
            const cur = ref.current.scrollLeft;
            ref.current.scrollBy({
              left: (
                  optionSelected.ref.current.offsetLeft
                - (Number.isFinite(offset) ? offset : 0)
                // + (optionIndex > 0 ? -50 : 0)
              ) - cur,
              top: 0,
              behavior: 'smooth',
            });
          }
        }
      },
      [ref, value, optionsWithRefs],
    );

    return (
      <AnimateSharedLayout>
        <div {...field} {...props}>
          <Wrapper ref={ref} {...events}>
            {
                optionsWithRefs?.length
              ? optionsWithRefs.map(option => (
                  <Option
                    key={option._id || option.value}
                    ref={option.ref}
                    onClick={() => {
                      if (value !== option.value) {
                        onChange(option.value);
                      }
                    }}
                    css={{ ...option.disabled && {
                      opacity: 0.4,
                      pointerEvents: 'none',
                    } }}
                  >
                    {value === option.value ? (
                      <Selector layoutId="selector" transition={spring} />
                    ) : null}
                    <Content
                      css={{ ...(value === option.value) && {
                        opacity: 1,
                        '*': { color: '$buttonForeground' },
                      } }}
                    >
                      <Label
                        css={condensed ? { fontSize: '75%' } : undefined}
                      >
                        {option.label}
                      </Label>
                      {option.description ? (
                        <Paragraph
                          css={{ fontSize: '75%', opacity: 0.75 }}
                        >
                          {Array.isArray(option.description) ? (
                            <>
                              {option.description.map((span, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Fragment key={`${span}-${index}`}>
                                  { (index > 0)
                                  ? <Divider /> : null }
                                  <span key={span}>{span}</span>
                                </Fragment>
                              ))}
                            </>
                          ) : option.description}
                        </Paragraph>
                      ) : null }
                    </Content>
                  </Option>
                ))
              : (
                  <Paragraph>
                    {noContentPlaceholder}
                  </Paragraph>
                )
            }
          </Wrapper>
        </div>
      </AnimateSharedLayout>
    );
  },
);

export const Controlled = (props) => {
  const { field } = useController(props);

  const handleOnChange = (value) => {
    field.onChange && field.onChange(value);
    props.onChange && props.onChange(value);
  };

  return (
    <ButtonGroup
      {...props}
      {...field}
      field={field}
      onChange={handleOnChange}
    />
  );
};

export default ButtonGroup;

import React, { useRef, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { Helmet } from 'react-helmet';
import color from 'color';

import { createNamedStyled } from '../../stitches.config';
import { useTheme } from '../../theme';

import { useStore } from '../../context/Store';

import { Title } from './Text';
import Link from '../Patterns/Link';

const styled = createNamedStyled('Logo');

const Image = styled.named('Image')('img', {
  pointerEvents: 'none',
  objectFit: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',

  width: 'auto',
  maxWidth: '120px',
  height: 'auto',
  maxHeight: '120px',

  '@phablet+': { maxWidth: '180px', marginInlineEnd: '$xs' },
});

const TextualLogo = styled.named('TextualLogo')('div', {
  width: 'fit-content',
  pointerEvents: 'none',
  userSelect: 'none',
  marginRight: '$xs',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  lineHeight: 1,

  '*': { lineHeight: 0.45 },
});

const Trademark = styled.named('Trademark')('span', {
  fontFamily: 'Arial',
  fontWeight: 600,
  verticalAlign: 'center',
  fontSize: '8px',
  marginInlineStart: '3px',
  transform: 'translateY(2px)',
  color: '$title',
});

const FaviconWrapper = styled.named('FaviconWrapper')('div', {
  overflow: 'hidden',
  position: 'absolute',
});

const Favicon = styled.named('Favicon')('div', {
  transform: 'translateX(-3000vw)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Logo = ({ ...props }) => {
  const {
    style: { logo },
    colors,
  } = useTheme();
  const { data: store } = useStore();

  const elementRef = useRef(null);
  const [favicon, setFavicon] = useState('');

  useEffect(() => {
    if (elementRef.current) {
      html2canvas(elementRef.current, {
        backgroundColor: null,
        scale: 2,
      })
        .then(canvas => {
          setFavicon(canvas.toDataURL('image/png'));
        });
    }
  }, []);

const FaviconElement = () => (
  <FaviconWrapper>
    <Favicon ref={elementRef}>
      <TextualLogo
        css={{
          '*': {
            color: color(
              colors.title.startsWith('$')
                ? colors[colors.title.slice(1)]
                : colors.title,
            ).luminosity() < 0.5 ? colors.title : colors.brandColor,
          },
        }}
      >
        <Title>{store.name.charAt(0)}</Title>
        <Trademark>®</Trademark>
      </TextualLogo>
    </Favicon>
  </FaviconWrapper>
);

  return (
    <Link to="/">
      {logo ? (
        <Image src={logo} alt="Logo" {...props} />
      ) : (
        <>
          <TextualLogo {...props}>
            <Helmet>
              <link rel="icon" type="image/png" href={favicon} />
            </Helmet>
            <Title>{store.name}</Title>
            <Trademark>®</Trademark>
          </TextualLogo>
          <FaviconElement />
        </>
      )}
    </Link>
  );
};

export default Logo;

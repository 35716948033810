import React from 'react';
import { styled } from '../../stitches.config';

import StatsComponent from '../../components/Legacy/Stats';

import Base from '../components/Base';

const Node = styled(Base, {});

const Stats = ({ node, css, renderNode, ...props }) => (
  <Node
    {...props}
    node={node}
    css={{
      cursor: 'pointer',
      ...css,
    }}
    id={node._id}
    className={node.className}
  >
    <StatsComponent items={node.stats_items} />
  </Node>
);

export default Stats;

/* eslint-disable max-len */
import * as React from 'react';

import { useTheme } from '../../theme';
import Wrapper from '../Wrapper';

function Tiktok({ type, ...props }) {
  const { custom: { iconsStyle } } = useTheme();

  return (
    <Wrapper
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`type-${iconsStyle}`}
      {...props}
    >
      {iconsStyle === 'duotone' || iconsStyle === 'duotoneOffset' ? (
        <path
          d="M21.278 6.29a4.996 4.996 0 01-4.996-4.995h-3.568v14.63a3.212 3.212 0 11-3.212-3.211V9.145a6.78 6.78 0 106.78 6.78V8.251a8.525 8.525 0 004.996 1.608V6.29z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="duotone"
          opacity={0.6}
        />
      ) : null}
      {iconsStyle === 'solid' ? (
        <path
          d="M21.278 6.29a4.996 4.996 0 01-4.996-4.995h-3.568v14.63a3.212 3.212 0 11-3.212-3.211V9.145a6.78 6.78 0 106.78 6.78V8.251a8.525 8.525 0 004.996 1.608V6.29z"
          fill="#000"
          className="solid"
        />
      ) : null}
      <path
        d="M21.278 6.29a4.996 4.996 0 01-4.996-4.995h-3.568v14.63a3.212 3.212 0 11-3.212-3.211V9.145a6.78 6.78 0 106.78 6.78V8.251a8.525 8.525 0 004.996 1.608V6.29z"
        stroke="#000"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={iconsStyle === 'solid' ? 1 : 2}
        className="base"
      />
    </Wrapper>
  );
}

export default Tiktok;

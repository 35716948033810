import React from 'react';
import { styled } from '../../stitches.config';

import ButtonComponent from '../../components/Legacy/Button';

import Base from '../components/Base';

const Node = styled(Base, {});

const Button = ({ node, css, renderNode, ...props }) => (
  <Node
    {...props}
    node={node}
    css={{
      cursor: 'pointer',
      ...css,
    }}
    id={node._id}
    className={node.className}
  >
    <ButtonComponent section={node} />
  </Node>
);

export default Button;

import React from 'react';
import { createNamedStyled } from '../../stitches.config';

import ButtonElement from '../Elements/Button';

const styled = createNamedStyled('LegacyButton');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  width: '100%',

  maxWidth: '$siteWidth',
  margin: '0 auto',

  padding: '$m $s',
  '@desktop+': {
    padding: '$l $m',
  },
});

const Button = ({ section, ...props }) => (
  <Wrapper {...props} css={{ justifyContent: section.button_align }}>
    <ButtonElement to={section.button_link} className="button">
      {section.button_label}
    </ButtonElement>
  </Wrapper>
);

export default Button;

import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';

import ContextProviderComponent from '../ContextProviderComponent';
import { useStore } from '../Store';
import { useLocation } from '../Location';

import TrackerSimpleAnalytics from './TrackerSimpleAnalytics';
import TrackerMeta from './TrackerMeta';
import TrackerSnapchat from './TrackerSnapchat';
import TrackerTiktok from './TrackerTiktok';
import TrackerGoogleAnalytics from './TrackerGoogleAnalytics';
import TrackerGoogleTagManager from './TrackerGoogleTagManager';

import Types from '../../modules/types';

const { ANALYTICS_EVENTS } = Types;

// export const EVENTS_TO_METHOD_NAMES_MAP = EVENTS.reduce(
//   (agr, event) => {
//     const eventKey = camelCase(event);
//     const eventMethodKey = `trigger${
//       eventKey[0].toUpperCase()
//     }${
//       eventKey.slice(1)
//     }`;
//     agr[event] = eventMethodKey;
//     return agr;
//   },
//   {},
// );

const Context = createContext();

export default Context;

export function Provider(props) {
  const location = useLocation();
  const store = useStore();
  const trackers = useMemo(
    () => [
      new TrackerSimpleAnalytics(),
      new TrackerMeta(),
      new TrackerSnapchat(),
      new TrackerTiktok(),
      new TrackerGoogleAnalytics(),
      new TrackerGoogleTagManager(),
    ],
    [],
  );
  return (
    <AnalyticsProvider
      // api={api}
      // location={location}
      // viewer={viewer}
      store={store}
      trackers={trackers}
      location={location}
      {...props}
    />
  );
}

export class AnalyticsProvider extends ContextProviderComponent {
  static NAME = 'ANALYTICS'

  static defaultProps = {
    ...ContextProviderComponent.defaultProps,
    ProviderComponent: Context.Provider,
  }

  static EVENTS = ANALYTICS_EVENTS

  constructor(props) {
    super(props);
    this.active = false;
    this.trackers = props.trackers || [];
    // this.constructor.EVENTS.forEach((event) => {
    //   const methodName = this.constructor.EVENTS_TO_METHOD_NAMES_MAP[event];
    //   if (methodName && !this[methodName]) {
    //     this[methodName] = (...args) => this.trigger(event, ...args);
    //   }
    //   this.exposeMethods(methodName);
    // });
    this.constructor.EVENTS.forEach((event) => {
      this.trigger[event] = (...args) => this.trigger(event, ...args);
    });
    this.exposeMethods(
      'trigger',
    );
    this.pageViewTimeout = null;
  }

  componentDidMount() {
    if (!this.active && this.props.store?.data) {
      this.setup(this.props);
    }
  }

  componentDidUpdate(props) {
    if (
      this.active
      && !this.props.store?.data
    ) {
      this.setup(null);
    } else if (
        !this.active
      && this.props.store?.data
      && this.props.store?.data?._id !== props.store?.data?._id
    ) {
      this.setup(this.props);
    }
    if (this.active) {
      const prevUrl = `${
        props.location.pathname
      }${
        props.location.search
      }`;
      const currUrl = `${
        this.props.location.pathname
      }${
        this.props.location.search
      }`;
      if (prevUrl !== currUrl) {
        clearTimeout(this.pageViewTimeout);
        this.pageViewTimeout = setTimeout(
          () => this.trigger.PAGE_VIEW({ url: currUrl }),
          500,
        );
      }
    }
  }

  setup(props) {
    clearTimeout(this.pageViewTimeout);
    this.trackers.forEach((tracker) => {
      if (tracker.setup) {
        try {
          tracker.setup(props);
        } catch (error) {
          //
        }
      }
    });
    this.active = !!props;
  }

  trigger(event, data) {
    if (this.active) {
      this.trackers.forEach((tracker) => {
        if (tracker.active) {
          try {
            tracker.trigger(event, data);
          } catch (error) {
            //
          }
        }
      });
    }
  }
}

export const { Consumer } = Context;

export function useAnalytics() {
  return useContext(Context);
}

export function useAnalyticsTrigger(event) {
  const analytics = useAnalytics();
  return useCallback(
    (data) => analytics.trigger(event, data),
    [analytics, event],
  );
}

export function useAnalyticsTriggers(...events) {
  const analytics = useAnalytics();
  const eventsString = events.join('@');
  const eventsArray = useMemo(
    () => eventsString.split('@'),
    [eventsString],
  );
  return useMemo(
    () => eventsArray.map(event => (
      (data) => analytics.trigger(event, data)
    )),
    [analytics, eventsArray],
  );
}

import React, { useContext, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme, useColorOverrides } from '../../../theme';

import CartContext from '../../../context/Cart';
import { useDictionary } from '../../../context/Language';

import { Title, Label } from '../Text';
import PriceWithCurrency from '../../../helpers/PriceWithCurrency';
import Number from '../../Patterns/Number';

import Items from './Items';
import Form from './Form';

const styled = createNamedStyled('Cart');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 1000,
  overflowY: 'scroll',

  display: 'flex',
  alignItems: 'center',
  justifyContent: '$shop$cartAlignment',

  padding: '$xs',
  '@desktop': { padding: '$s' },

  variants: { isOpen: { false: { pointerEvents: 'none' } } },

  // TODO: maybe not use for POS?
  paddingBottom: 0,
});

const Shade = styled.named('Shade')('div', {
  position: 'fixed',
  inset: 0,
  background: '$shade',

  transition: 'opacity calc($s / 1.5)',
  variants: { isOpen: { false: { opacity: 0 } } },
});

const Container = styled.named('Container')(motion.div, {
  position: 'relative',
  width: '100%',
  maxWidth: '600px',
  borderRadius: '$l',
  boxShadow: '$xl',
  background: '$backgroundCart',
  marginTop: 'auto',

  display: 'flex',
  flexDirection: 'column',
  gap: '$m',

  padding: '$s',
  '@desktop': { padding: 'calc($s * 1.5)' },

  minHeight: '100vh',

  // TODO: maybe not use for POS?
  paddingBottom: '$s',
  borderEndStartRadius: 0,
  borderEndEndRadius: 0,
});

const Close = styled.named('Close')('div', {
  position: 'absolute',
  top: '$s',
  padding: '$s',
  cursor: 'pointer',
  pointerEvents: 'all',
  zIndex: 1000,

  insetInlineEnd: '$s',

  '&:before, &:after': {
    content: '',
    position: 'absolute',
    top: 12,
    width: 24,
    height: 2,
    borderRadius: 2,
    background: '$icon',
    transition: 'scale $l $ease, background $s',

    insetInlineEnd: 0,
  },

  '&:before': { transform: 'rotate(45deg)' },
  '&:after': { transform: 'rotate(-45deg)' },

  '&:hover': {
    '&:before, &:after': {
      scale: 1.15,
      background: '$accent',
    },
  },
});

const Column = styled.named('Column')('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Row = styled.named('Row')('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 'calc($xs / 2)',
});

const Cart = ({ ...props }) => {
  const cart = useContext(CartContext);

  const { cartTitle, cartItem, cartItems } = useDictionary();

  const { transitions: { spring } } = useTheme();
  const colorOverrides = useColorOverrides('cart');

  // const cartIsOpen = true || cart.isOpen;
  const cartIsOpen = cart.isOpen;

  useEffect(() => {
    const getStripePaymentIntent = async () => {
      if (cartIsOpen) {
        await cart.getStripePaymentIntent();
      }
    }
    getStripePaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartIsOpen]);

  return (
    <Wrapper {...props} isOpen={cartIsOpen} className={colorOverrides}>
      <Shade isOpen={cartIsOpen} onClick={() => cart.toggleShowCart(false)} />
      <AnimatePresence>
        {(cartIsOpen && !!cart.quantityTotal) ? (
          <Container
            initial={{ opacity: 0, y: 250 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 250 }}
            transition={spring}
          >
            <Close onClick={() => cart.toggleShowCart(false)} />
            {/* HEADER */}
            <Column css={{ marginTop: '-$xs' }}>
              <Title bold>{cartTitle}</Title>
              <Label>
                <Row>
                  <Number
                    key="quantity"
                    number={`
                      ${cart.quantityTotal}
                      ${cart.quantityTotal === 1 ? cartItem : cartItems},
                    `}
                  />
                  <Number
                    key="price"
                    number={<PriceWithCurrency value={cart.priceCharged} />}
                  />
                </Row>
              </Label>
            </Column>
            {/* ITEMS */}
            <Items />
            {/* FORM */}
            <Form />
          </Container>
        ) : null}
      </AnimatePresence>
    </Wrapper>
  );
};

export default Cart;

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function Number({ number, quick, ...props }) {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: quick ? 0 : 0.25 } }}
        {...props}
      >
        {number}
      </motion.span>
    </AnimatePresence>
  );
}

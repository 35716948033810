import React from 'react';
import { styled } from '../../stitches.config';

import SliderComponent from '../../components/Legacy/Slider';

import Base from '../components/Base';

const Node = styled(Base, {});

const Slider = ({ node, css, renderNode, ...props }) => (
  <Node
    {...props}
    node={node}
    css={{
      cursor: 'pointer',
      ...css,
    }}
    id={node._id}
    className={node.className}
  >
    <SliderComponent
      size={node.slider_size}
      style={node.slider_style}
      autoplay={node.slider_autoplay}
      overflow={node.slider_overflow}
      slides={node.slider_items}
    />
  </Node>
);

export default Slider;

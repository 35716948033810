import React, { useEffect, useMemo, useCallback, forwardRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { useTheme } from '../../theme';

const Parallax = forwardRef(({ as, children, effect, ...props }, ref) => {
  const { transitions } = useTheme();
  const imgAnimation = useAnimation();

  const innerRef = React.useRef(null);

  const handleMouseMove = useCallback(
    (e) => {
      const { clientX, clientY } = e
      const moveX = clientX - window.innerWidth / 2
      const moveY = clientY - window.innerHeight / 2
      const offsetFactor = 100 / (effect.parallax_depth * 10)
      imgAnimation.start({
        x: moveX / offsetFactor,
        y: moveY / offsetFactor,
        transition: effect.parallax_easing === 'LINEAR'
          ? { duration: 0 } : transitions.spring,
      })
    },
    [
      effect.parallax_depth,
      effect.parallax_easing,
      imgAnimation,
      transitions.spring,
    ],
  );

  const handleScroll = useCallback(
    () => {
      imgAnimation.start({
        y: ((window.scrollY - innerRef.current.offsetTop)
        + window.innerHeight / 2) / (effect.parallax_depth * 10),
        transition: effect.parallax_easing === 'LINEAR'
          ? { duration: 0 } : transitions.spring,
      });
    },
    [
      effect.parallax_depth,
      effect.parallax_easing,
      imgAnimation,
      transitions.spring,
    ],
  );

  useEffect(() => {
    if (effect.parallax_trigger === 'MOUSE') {
      window.addEventListener('mousemove', handleMouseMove)
    } else if (effect.parallax_trigger === 'BOTH') {
      setTimeout(() => handleScroll(), 100);
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('mousemove', handleMouseMove)
    } else {
      // SCROLL
      setTimeout(() => handleScroll(), 100);
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (effect.parallax_trigger === 'MOUSE') {
        window.removeEventListener('mousemove', handleMouseMove)
      } else if (effect.parallax_trigger === 'BOTH') {
        window.removeEventListener('scroll', handleScroll)
        window.removeEventListener('mousemove', handleMouseMove)
      } else {
        // SCROLL
        window.removeEventListener('scroll', handleScroll)
      }
    }
    // x-eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    effect.parallax_trigger,
    handleMouseMove,
    handleScroll,
  ]);

  const finalAs = useMemo(
    () => (as ? motion(as) : motion.div),
    [as],
  );

  const child = React.Children.only(children);

  return React.cloneElement(child, {
    ref: React.useCallback(node => {
      innerRef.current = node;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    }, [innerRef, ref]),
    ...child.props,
    ...props,
    css: {
      ...child.props.css,
      ...props.css,
    },
    as: finalAs,
    initial: false,
    animate: imgAnimation,
    className: props.node.className,
  });
})

const ParallaxWrapper = forwardRef(
  ({ children, as, effect, ...props }, ref) => {
    if (effect) {
      return (
        <Parallax
          as={as}
          ref={ref}
          effect={effect}
          {...props}
        >
          {children}
        </Parallax>
      )
    }

    const child = React.Children.only(children);
    return React.cloneElement(children, {
      ref,
      as,
      ...child.props,
      ...props,
      css: {
        ...props.css,
        ...child.props.css,
      },
    });
  }
)

export default ParallaxWrapper;

import React from 'react';
import { useTheme } from '../theme';

const GlobalBackgroundColor = ({ page }) => {
  const { colors: { backgroundWebsite, backgroundPageOrder } } = useTheme();

  return (
    <style>
      {`body { background: ${backgroundWebsite}; }`}
      {page === 'order' && `body { background: ${backgroundPageOrder}; }` }
    </style>
  );
};

export default GlobalBackgroundColor;
